import React, { createContext, useContext, useEffect, useState } from "react";
import { Auth, Hub } from "aws-amplify";

type CognitoUserContextType = {
  cognitoUser: {
    attributes: {
      email: string;
      email_verified: boolean;
      sub: string;
      phone_number: string;
      phone_number_verified: boolean;
      given_name: string;
      family_name: string;
      name: string;
      preferred_username: string;
      updated_at: string;
      picture: string;
      zoneinfo: string;
    };
  } | null;
  updatingCognitoAttribute: boolean
  updateCognitoAttributes: (attributes: { [key: string]: string }) => Promise<void>;
  refreshCognitoUser: (attributes: any) => Promise<void>;
}

const CognitoUserContext = createContext<CognitoUserContextType>({
  cognitoUser: null,
  updatingCognitoAttribute: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateCognitoAttributes: async (attributes: { [key: string]: string }) => {
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refreshCognitoUser: async () => {
  }
});


const { Provider, Consumer: CognitoUserConsumer } = CognitoUserContext;

const CognitoUserProvider = ({ children }: {
  children: React.ReactNode;
}) => {
  const [cognitoUser, setCognitoUser] = useState(null);
  const [updatingCognitoAttribute, setUpdatingCognitoAttribute] = useState(false);
  const refreshCognitoUser = async () => {
    setCognitoUser(await Auth.currentAuthenticatedUser());
  };

  useEffect(() => {
    refreshCognitoUser();

    Hub.listen("auth", (data) => {
      refreshCognitoUser();
      // switch (data.payload.event) {
      //   case 'signIn':
      //     console.log('user signed in');
      //     break;
      //   case 'signUp':
      //     console.log('user signed up');
      //     break;
      //   case 'signOut':
      //     console.log('user signed out');
      //     break;
      //   case 'signIn_failure':
      //     console.log('user sign in failed');
      //     break;
      //   case 'configured':
      //     console.log('the Auth module is configured');
      // }
    });
  }, []);

  const updateCognitoAttributes = async (attributes: { [key: string]: string }) => {
    setUpdatingCognitoAttribute(true);
    if (cognitoUser) {
      await Auth.updateUserAttributes(cognitoUser, attributes);
      await refreshCognitoUser();
    }

    setUpdatingCognitoAttribute(false);
  };

  return (
    <Provider
      value={{
        cognitoUser,
        updateCognitoAttributes,
        updatingCognitoAttribute,
        refreshCognitoUser
      }}
    >
      {children}
    </Provider>
  );
};

export { CognitoUserContext, CognitoUserProvider, CognitoUserConsumer };
