import gql from 'graphql-tag';

export default gql`
    query ($vendorUuid: ID!, $cognitoSub: ID){
        getUserPublicProfile(vendorUuid: $vendorUuid, cognitoSub: $cognitoSub) {
            data {
                isPrivate
                cognitoSub
                profilePictureUrl
                headerPictureUrl
                birthdate
                firstName
                lastName
                bio
                careerPath
                location
                contactMethods {
                    platform
                    method
                }
            }
        }
    }
`;
