import { useQuery } from "@apollo/react-hooks";
import getProtectedCourseQuery from "../api/getProtectedCourseQuery.js";
import getUnauthorisedCourseQuery from "../api/getUnauthorisedCourseQuery.js";
import { useConfig } from "@genflow/core";
import useUserAccess from "./useUserAccess.ts";

const useContent = ({ type = 'course', productId }: { type: 'course'; productId: string }) => {
  const { vendorUuid } = useConfig();
    const { hasAccess } = useUserAccess({ contentId: productId });
    const contentQuery = (hasAccess ? { course: getProtectedCourseQuery } : { course: getUnauthorisedCourseQuery })[
        type
    ];
    const { data, loading } = useQuery(contentQuery, {
        variables: {
            vendorUuid,
            productId,
        },
        skip: !vendorUuid || !type || !productId || hasAccess === undefined,
    });

    if (loading) return { isLoading: true, data: null } as {data: any, isLoading: boolean};

    const contentData =
        data?.[
            {
                hasAccess: {
                    course: 'getProtectedCourse',
                },
                noAccess: {
                    course: 'getUnauthorisedCourse',
                },
            }[hasAccess ? 'hasAccess' : 'noAccess'][type]
        ]?.data;

    return { data: contentData, isLoading: false } as  {data: any, isLoading: boolean};
};

export default useContent;
