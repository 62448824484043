import React, { useEffect, useMemo } from "react";
import { LockClosedIcon } from "@heroicons/react/outline";
import CourseProductLink from "./CourseProductLink";
import { courseToIndex } from "./search/withSearch";
import { useConfig } from "@genflow/core";
import lockIcon from "../assets/img/icons/lock.svg";
import useGoalLocked from "hooks/useGoalLocked";
import useUserAccess from "../hooks/useUserAccess";

export default ({
    imageSrc,
    productID,
    comingSoon,
    brandSrc,
    extraStyling,
    invite,
    extraImgStyling,
    item,
    openCourse,
    openGoalLockedModal
}) => {
    const { contentLocking } = useConfig();

    const { hasAccess } = useUserAccess({ contentId: productID });
    const {lockedInfo} = useGoalLocked([productID], hasAccess)
    const courseLockedInfo = lockedInfo?.[0];



    // Hide hidden content that's not got access
    const hidden = useMemo(() => {
        try {
            const isHiddenProduct = item.meta_data && JSON.parse(item.meta_data)?.hidden;
            return isHiddenProduct && !hasAccess;
        } catch (error) {
            console.log('error:', error);
            return false;
        }

    }, [hasAccess, item]);

    // add course assets to search engine on access confirm
    useEffect(() => {
        if (hasAccess) courseToIndex(item, item?.id);
    }, [hasAccess, item]);

    // Check access to course or if content is coming soon or if contentLocking is disabled
    // If all pass, open course
    // const checkAccessOpenCourse = () => {
    //         openCourse();
    //     } else {
    //         console.log('Handle rejection')
    //     }
    // }

    if (hidden) return null;

    const showLockedModal = courseLockedInfo?.locked  && !comingSoon;

    return (
        <>
            <CourseProductLink
                key={productID}
                productID={productID}
                access={showLockedModal ? true : hasAccess}
                onClick={()=> courseLockedInfo?.locked ?
                    openGoalLockedModal({
                        image:imageSrc,
                        logo:brandSrc,
                        message:courseLockedInfo?.message,
                        cta: courseLockedInfo?.cta,
                        link: courseLockedInfo?.link,
                        externalLink: courseLockedInfo?.externalLink
                    }) : openCourse() }
            >
                <div
                    className={`rounded-xl relative  ${((invite || !hasAccess) && contentLocking) && 'opacity-50'} opacity-on-hover bounce-on-hover overflow-hidden h-full rounded-xl aspect-ratio-[2/3]`}
                >
                    <div className="cardheader h-full">
                        <div className="relative h-full">
                            <img
                                src={imageSrc}
                                alt="card"
                                className="object-cover h-full w-full"
                            />
                            <div className="body5 text-white absolute bottom-8 left-0 right-0 mx-auto w-full flex flex-col justify-center items-center">
                                <div className="flex justify-center  px-8" style={extraStyling}>
                                    {
                                        (brandSrc) && (
                                            <img
                                                src={brandSrc}
                                                alt="brand"
                                                // style={{ width: '315px' }}
                                                style={extraImgStyling}
                                            />
                                        )
                                    }
                                    {/* {
                                        (!brandSrc) && (
                                            <div className="mt-6 whitespace-normal h-20 text-center font-bold text-2xl">{name}</div>
                                        )
                                    } */}
                                </div>
                                {/* <div className="mt-6 whitespace-normal h-20 text-center px-2 w-full lg:w-3/4" dangerouslySetInnerHTML={{ __html: description }} /> */}

                            </div>
                            {invite && (
                                <div className="w-28 bg-black text-white body5 absolute top-4 right-8 flex flex-row items-center justify-center py-2 rounded-md">
                                    <LockClosedIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                                    <div>Invite Only</div>
                                </div>
                            )}
                        </div>

                        {
                            ((!hasAccess && !comingSoon) && contentLocking) && (
                                <div className="w-full h-full bg-black/60 text-white body5 absolute top-0 left-0 flex flex-row items-center justify-center rounded-md ">
                                    <img  src={lockIcon} className='w-8 h-8 md:w-12 md:h-12 object-cover' alt='locked'/>
                                </div>
                            )
                        }
                        {
                            ((hasAccess && !comingSoon && courseLockedInfo?.locked) && contentLocking) && (
                                <div className="w-full h-full bg-black/60 text-white body5 absolute top-0 left-0 flex flex-col items-center justify-center rounded-md ">
                                <img  src={lockIcon} className='w-8 h-8 md:w-12 md:h-12 object-cover' alt='locked'/>
                                <p className='text-[#CBD5E1] text-lg  md:text-2xl font-semibold mt-2'>UNLOCK NOW</p>
                            </div>
                            )
                        }
                        {
                            (!hasAccess && comingSoon) && (
                                <div className="w-full h-full bg-black/60 text-white body5 absolute top-0 left-0 flex flex-col items-center justify-center rounded-md ">
                                    <img  src={lockIcon} className='w-8 h-8 md:w-12 md:h-12 object-cover' alt='locked'/>
                                    <p className='text-[#CBD5E1] text-lg  md:text-2xl font-semibold mt-2'>COMING SOON</p>
                                </div>
                            )
                        }
                    </div>
                </div>
            </CourseProductLink>


        </>
    );
};

// {showModal ? (
//     <>
//         <div
//             className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none whitespace-normal text-white"
//         >
//             <div className="relative w-auto my-6 mx-auto max-w-3xl">
//                 {/* content */}
//                 <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-black outline-none focus:outline-none">
//                     {/* header */}
//                     <div className="flex items-start justify-between p-10 rounded-t">
//                         <h3 className="text-3xl font-semibold py-8">
//                             You don’t have access to this program!
//                         </h3>
//                         <button
//                             className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
//                             onClick={() => setShowModal(false)}
//                         >
//                             <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
//                                 ×
//                             </span>
//                         </button>
//                     </div>
//                     {/* body */}
//                     <div className="relative p-10 flex-auto space-y-4">
//                         <p>
//                             This is an application-only program for Agency Owners doing $10,000+ a month who want to scale to $50k, $70k, $100k+ and use their agencies as a launch pad to get into 8-figures business models.
//                         </p>
//                         <p>
//                             Interested in joining? Schedule a call below with our APEX Student Integration Manager, Max, to apply for a spot.
//                         </p>
//                         <p>
//                             {/* <a href="https://link.growyouragency.com/apex-apply"><b>Click here to apply now</b></a> */}
//                             <button onClick={applytoApex} style={{ width: '155px', padding: '0px' }} className="buttonLgLight h-fit mt-7 hidden sm:flex">Apply Now</button>
//                         </p>
//                     </div>
//                     {/* footer */}
//                     <div className="flex items-center justify-end p-10 rounded-b">
//                         <button
//                             className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
//                             type="button"
//                             onClick={() => setShowModal(false)}
//                         >
//                             Close
//                         </button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//         <div className="opacity-25 fixed inset-0 z-40 bg-black" />
//     </>
// ) : null}
