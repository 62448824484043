import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import upsertUserProfileData from '../../../api/upsertUserProfileData';
import { UserDataContext } from '../../../providers/UserDataProvider';

const continents = [
    {
        label: 'North America',
        value: 'North America',
    },
    {
        label: 'South America',
        value: 'South America',
    }, 
    {
        label: 'Central America',
        value: 'Central America',
    }, 
    {
        label: 'Africa',
        value: 'Africa',
    },
    {
        label: 'Europe',
        value: 'Europe',
    },
    {
        label: 'Middle East',
        value: 'Middle East',
    },
    {
        label: 'Asia',
        value: 'Asia',
    },
    {
        label: 'Antarctica',
        value: 'Antarctica',
    },
    {
        label: 'Australia',
        value: 'Australia',
    },    
];
const binaryOptions = [
    {
        label: 'Yes',
        value: 'yes',
    },
    {
        label: 'No',
        value: 'no',
    },
];


const OnboardingInfo3 = ({ next }) => {
    const [info, setInfo] = useState({continent: continents?.[0]?.value});
    const { userProfile, setUserProfile } = useContext(UserDataContext);

    const handleChange = (e) => {
        const { value } = e.target;
        setInfo({
            ...info,
            [e.target.id]: value,
        });
    };

    const handleNumberChange  = (e) => {
        // Remove non-numeric characters using a regular expression
        const numericValue = e.target.value.replace(/[^0-9]/g, '');             
        setInfo({
            ...info,
            [e.target.id]: numericValue,
        });
      };


    const onSubmit = (e) => {
        next(e, {
            ...userProfile,
            data: { 
                ...userProfile?.data, 
                ...info,
                onboardingStageId: 3,
            },
        });
    };

    return (
        <div className="w-full flex flex-col mt-2">
            <form onSubmit={onSubmit}>
                {
                    userProfile?.data?.currentlyEmployed === 'yes' ?
                 ( <>
                        <div className="mb-6 w-full">
                            <label htmlFor="monthlyIncome" className="block mb-2 text-sm font-medium text-white w-full ">
                                What is your current monthly income from your job? (In US dollars)
                                <input
                                type="text"
                                value={info?.monthlyIncome} id="monthlyIncome" onChange={handleNumberChange} className="rounded-md glassCard backdrop-blur-md mt-2  text-white text-sm focus:ring-blue-500 focus:outline-none block w-full p-2.5 " placeholder="amount" required />
                            </label>
                        </div>
                        <div className="mb-6 w-full">
                                <label htmlFor="careerReason" className="block mb-2 text-sm font-medium text-white w-full ">
                                    Why are you looking to leave your full time job to start your full time career?
                                    <textarea type="text" id="careerReason" value={userProfile?.data?.careerReason} onChange={handleChange} className="rounded-[20px] mt-2 glassCard backdrop-blur-md text-white text-sm focus:ring-blue-500 focus:outline-none block w-full p-2.5" placeholder="..." required />
                                </label>
                        </div>
                    </>
                ):(
                <div className="mb-6 w-full">
                    <label htmlFor="incomeTarget" className="block mb-2 text-sm font-medium text-white w-full ">
                        What is your immediate income target that enables you to start your online career full time? (In US dollars)
                        <input type="number" id="incomeTarget" value={userProfile?.data?.incomeTarget} onChange={handleChange} className="rounded-md glassCard backdrop-blur-md mt-2  text-white text-sm focus:ring-blue-500 focus:outline-none block w-full p-2.5 " placeholder="amount" required />
                    </label>
                </div>
                )
                }
        
                <div className="mb-4 w-full">
                    <div htmlFor="name" className="block mb-2 text-sm font-medium text-white w-full ">
                        Where are you located?
                        <div className="pr-2.5 rounded-md glassCard backdrop-blur-md overflow-hidden mt-2">
                            <select value={info?.continent} onChange={handleChange} id='continent' className="bg-transparent text-white text-sm  focus:outline-none focus:border-blue-500 block w-full !p-2.5">
                                {continents?.map((option, id) => (
                                    <option value={option?.value} key={id}>{option?.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <button
                    type="submit"
                    className="button flex w-full justify-center rounded-md  px-3 py-3 2xl:py-4 text-lg md:text-lg font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                 Next
                </button>
            </form>
        </div>
    );
};

export default OnboardingInfo3;
