import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import getUserHabitGroupsStatsQuery from "../../../../to-package/app-habit-tracker/api/getUserHabitGroupsStats.query";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useConfig } from "@genflow/core";

export default () => {
  const { vendorUuid } = useConfig();
  const { data, error, loading } = useQuery(getUserHabitGroupsStatsQuery, {
    variables: { vendorUuid },
    skip: !vendorUuid
  });

  const [challengeStats, setChallengeStats] = useState([{
      title: 'Monk Mode Completed',
      currentValue: 0,
      totalValue: 2
    },
    {
      title: '21 Total Days in Monk Mode',
      currentValue: 0,
      totalValue: 21
    },
    {
      title: '90 Total Days in Monk Mode',
      currentValue: 0,
      totalValue: 90
    }]);


  useEffect(() => {
    const habitGroupStats = data?.getUserHabitGroupsStats?.data as HabitGroupStats
      console.log('habitGroupStats', habitGroupStats);
    if(!loading && habitGroupStats?.byCompletionStatus?.length > 0){
      const completed = habitGroupStats.byCompletionStatus.find(({completionStatus}) => completionStatus === 'FINISHED');
      console.log('habitGroupStats completed', completed);
      const _21Completed = completed?.byDuration.find(({duration}) => duration === 21);
      const _30Completed = completed?.byDuration.find(({duration}) => duration === 30);
      setChallengeStats([
        {
          title: 'Monk Mode Completed',
          currentValue: completed?.total || 0,
          totalValue: 2
        },
        {
          title: '21 Day Monk Mode Completed',
          currentValue: _21Completed?.total || 0,
          totalValue: 2
        },
        {
          title: '30 Day Monk Mode Completed',
          currentValue: _30Completed?.total || 0,
          totalValue: 2
        }
      ])
    }
  }, [data, loading])


  return (
    <div className="glassCard backdrop-blur-md px-4 2xl:px-8 pb-4 pt-3 flex-1 h-full rounded-[30px]">
      <div  className='text-lg mb-3'>Monk Mode Challenges</div>
      <div className="grid grid-rows-3 sm:!grid-rows-1 sm:grid-cols-3 justify-stretch sm:justify-center items-center gap-4 w-full ">
        {
          challengeStats?.map((item)=> (
            <div className="h-full glassCard flex flex-row sm:flex-col justify-center items-center rounded-[20px] px-2 sm:px-0 py-2 sm:py-4 w-full sm:w-auto">
              <div className="flex flex-col w-1/4 sm:min-w-[6rem] aspect-square justify-center items-center border-3 border-[#ffffff14] rounded-full">
                <p className="font-extrabold">{item?.currentValue}</p>
                <div className="w-[32px] h-[2px] bg-white my-1"/>
                <p  className="font-extrabold">{item?.totalValue}</p>
              </div>
              <p className="text-left sm:text-center sm:mt-2 sm:leading-[110%] px-4 flex-1">{item?.title}</p>
            </div>
          ))
        }
      </div>
    </div>

  )
}
