import React, { Dispatch, FC, SetStateAction, useMemo } from "react";
import Modal from "./Modal";
import { XIcon } from "@heroicons/react/outline";
import {
  communityLogo,
  plusIcon,
  programs,
  socials
} from "constants/findAFriend/content";
import { UserProfile } from "../../@types/user-discovery";
import friendHeader from "../../assets/img/friendHeader.png";
import { startCase } from "lodash";
import moment from "moment";
import getConnectWithMeButtonData from "../../utils/getConnectWithMeButtonData.ts";
import getContactMethodUrl from "../../utils/getContactMethodUrl.ts";
import getFlag from "utils/getFlag.ts";

type FAFUserModalProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  userProfile: UserProfile;
};

const FAFUserModal: FC<FAFUserModalProps> = ({
                                               showModal,
                                               setShowModal,
                                               userProfile
                                             }) => {
  const connectWithMeButtonData = getConnectWithMeButtonData(userProfile);
  const {flag} = getFlag(userProfile);

  const socialLinks = useMemo(()=> userProfile?.contactMethods?.filter((item)=> item?.platform !== 'community' )  ,[userProfile?.contactMethods])
  const careerIcon : string | undefined = programs?.find(({value})=> value?.toLocaleLowerCase() === userProfile?.careerPath?.toLocaleLowerCase())?.icon

  return (
    <Modal
      open={!!showModal}
      setOpen={setShowModal}
      backgroundColor="rgba(12, 16, 24, 0.80)"
    >
      <div className="flex justify-center px-4 py-4 items-center overflow-y-auto relative">
        <div
            onClick={()=> setShowModal(!showModal)}
            className="h-[30px] w-[30px] md:h-[40px] md:w-[40px] rounded-[8px] md:rounded-[12px] bg-[#17191C] flex justify-center items-center absolute top-0  left-0 border-[2px] border-[#FFFFFF08] hover:cursor-pointer z-50"
          >
              <XIcon
                className="h-5 w-5 md:h-6 md:w-6 text-[#CBD5E1] hover:text-white transition-all"
                aria-hidden="true"
              />
        </div>
        <div
          className="mx-auto flex w-full md:w-[85vw] lg:w-[50vw] flex-col glassCard !bg-[#181c24] !border-[1px] rounded-[20px] relative px-5 py-5 backdrop-blur-md">
          <div className="">
            <div className="w-full rounded-xl relative">
              <div className="w-full h-24 md:h-52 relative ">
                <img
                  src={userProfile?.headerPictureUrl ?? friendHeader}
                  className="w-full h-full object-cover rounded-xl"
                />
              </div>

              <div className="flex flex-row items-end md:mt-[-100px] mt-[-40px]">
                <div
                  className="w-24 h-24 md:w-[200px] md:h-[200px] rounded-xl md:rounded-[30px] ml-2 lg:ml-10 mr-2.5 md:mr-6 md:p-1 relative md:bg-[#CBD5E1]/80">
                  <img
                    src={userProfile?.profilePictureUrl ?? friendHeader}
                    className="w-full h-full object-cover rounded-xl md:rounded-[30px]"
                  />
                </div>

                <div
                  className="flex flex-col md:flex-row md:items-end justify-between w-[70%] flex-1 md:h-12  md:mb-5">
                  <div className="flex flex-col items-start">
                    <p className="text-[#CBD5E1] text-base md:text-3xl">
                      {startCase(`${userProfile?.firstName ?? ""} ${userProfile?.lastName ?? ""}`)}
                    </p>
                    {userProfile?.location && <div className="flex flex-row items-center mt-1">
                      <div className="flex justify-center items-center w-6 h-6 bg-[#4f70a05e] rounded-full mr-2">
                        {!!flag && <img src={flag} className="w-4 h-4 rounded-full" />}
                      </div>
                      <p className="text-[#CBD5E1] text-xs">
                        {userProfile?.location}
                      </p>
                    </div>}
                  </div>

                  {
                    connectWithMeButtonData?.platform && <a
                      className="h-8 md:h-12 mt-2 md:mt-0 glassCard !border-[1px] rounded-xl hidden md:flex fex-row justify-center items-center px-4 md:px-8 hover:cursor-pointer"
                      href={connectWithMeButtonData.url} target="_blank">
                      <img
                        src={socials.find(({ platform }) => platform === connectWithMeButtonData?.platform)?.icon}
                        className="w-4 h-5 object-contain mr-2"
                      />
                      <p className="text-sm md:text-base text-[#CBD5E1]">
                        Connect with me
                      </p>
                    </a>
                  }
                </div>
              </div>
              {/* mobile */}
              {connectWithMeButtonData?.platform && <a
                className="h-8 md:h-12 mt-2 md:mt-0 glassCard !border-[1px] rounded-xl md:hidden flex fex-row justify-center items-center px-4 md:px-8 hover:cursor-pointer"
                href={connectWithMeButtonData?.url} target="_blank">
                <img
                  src={socials.find(({ platform }) => platform === connectWithMeButtonData?.platform)?.icon}
                  className="w-4 h-5 object-contain mr-2"
                />
                <p className="text-sm md:text-base text-[#CBD5E1]">
                  Connect with me
                </p>
              </a>}
            </div>
          </div>

          <div className="lg:grid grid-flow-col gap-5 w-full mt-5 ">
            {(!!userProfile?.birthdate || !!userProfile?.bio) && <div
              className="col-span-3 h-full flex flex-col glassCard backdrop-blur-md rounded-2xl !border-[1px] px-4 mb-5 lg:mb-0">
              <div className="my-4 w-full">
                <div className="block items-start mb-2 text-sm font-medium text-white">
                  {/* {
                    userProfile?.birthdate && <p className="text-left text-[#CBD5E1] text-base font-semibold">
                      {`Age: ${moment().diff(userProfile?.birthdate, "years")}`}
                    </p>
                  } */}
                  {
                    userProfile?.bio && (
                      <>
                        <p className="text-left text-[#CBD5E1] text-base my-2 font-semibold">
                          About me
                        </p>
                        <p className="text-left text-[#CBD5E1] text-sm ">
                          {userProfile?.bio}
                        </p>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
            }

            <div className="col-span-2 flex flex-col md:flex-row lg:flex-col lg:justify-end lg:items-end">
              {
                userProfile?.careerPath && <div
                  className="flex flex-col items-start justify-start glassCard !border-[1px] p-4 w-full rounded-2xl mb-5 md:mb-0 lg:mb-5 md:mr-2 lg:mr-0">
                  <p className="text-base font-semibold text-[#CBD5E1] mb-2">
                    Career Path
                  </p>

                  <div
                    id="program"
                    className={`hover:cursor-pointer py-1.5 px-3 flex flex-row items-center justify-center glassCard !border-[1px] rounded-[10px] mb-2`}
                  >
                    <img src={careerIcon || plusIcon} className="h-3 w-3 object-contain mr-1.5" />
                    <p className="text-xs text-white">{userProfile?.careerPath}</p>
                  </div>
                </div>
              }

              {socialLinks?.length > 0 &&
                <div className="flex flex-col items-start justify-start glassCard !border-[1px] p-4 w-full rounded-2xl">
                  <p className="text-base font-semibold text-[#CBD5E1] mb-2">
                    Socials
                  </p>

                  <div className="flex flex-row">
                    {socialLinks?.map((contactMethod, id) => (
                      <a key={contactMethod?.platform + contactMethod?.method} className="h-6 w-6 mr-4"
                         href={getContactMethodUrl(contactMethod)} target="_blank">
                        <img
                          src={socials.find(({ platform: socialPlatform }) => socialPlatform === contactMethod?.platform)?.icon}
                          className="h-full w-full object-contain" />
                      </a>
                    ))}
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FAFUserModal;
