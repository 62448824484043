const vendorUuid = 'e23223d0-9ee1-41b4-8cf3-d0ad2e13af59';
const beaconKey = '2a9b0427-6127-46e9-8b5b-45401d4bdc01'
// Legacy - remove?
const copyPasteAgencyCourseProductID = 2084;
const agencyIncubatorCourseProductID = 2083;
const consonantPackageProductID = 2034;
const agencyNavigatorResourceLibraryProductID = 6053;
const digitalLaunchpadResourceLibraryProductID = 7273;
const resourcesArticlesSectionID = 80;
const resourcesVideoLibrarySectionID = 81;
const resourcesQARecordingsID = 82;
const upcomingQACallsID = 575;
const qaRecordingID = 572;
const bannerId = 589;

const contentLocking = true;

const agencyNavigatorCourseProductID = 6018;

const resetPasswordRedirectUri = 'https:///forgot-password?code=';
const TRACKING_ID = 'G-DEP7M2J43N'; // RVLGPJXZS7

const stripeProductPlans = {
    agencyNavigator: 'educateSubscription',
    agencyNavigatorExistingStudents: 'educateGyaUpgrade',
};

const subscriptionServiceBaseURL = {
    staging: `https://1osfn38ejh.execute-api.eu-west-1.amazonaws.com/dev/subscriptions/${vendorUuid}`,
    production: `https://ix6hdu8hu0.execute-api.eu-west-1.amazonaws.com/prod/subscriptions/${vendorUuid}`,
};


export const staging = {
    aws: {
        url: 'https://rm56uphfwjfrnfe2msn3a6g6bi.appsync-api.eu-west-1.amazonaws.com/graphql',
        region: 'eu-west-1',
        type: 'AWS_IAM',
    },
    cognito: {
        identityPoolId: 'eu-west-1:4936b8f4-66cd-4175-84e5-7a983eb47180',
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_3sxDScGLH',
        userPoolWebClientId: '4jgkebm1mh2v2r8vq05f4q21b3',
        mandatorySignIn: 'false',
    },
    forgotPassword: 'https://jhhs6d8jmb.execute-api.eu-west-1.amazonaws.com/dev',
    stripe: {
        publishableKey: 'pk_test_51NH68LDM6ttbLpZZ2u1q0FfWBEOxgve1eVjATuQ6ipBW8TFRa4CP747SKjc4pDWVh21s4Yz7DdUTg48KLJd78QdP00kJrh4Rlb',
        baseSessionUrl: `${subscriptionServiceBaseURL.staging}/stripe/session`,
        subscriptionsServiceUrl: `${subscriptionServiceBaseURL.staging}/stripe/subscriptions`,
        entitlementUrl: `${subscriptionServiceBaseURL.staging}/entitlement`,
        entitlementDiscountCheckUrl: `${subscriptionServiceBaseURL.staging}/entitlement/discount/check`,
    },
    entitlementTmpURL: `${subscriptionServiceBaseURL.staging}/entitlement`,
    mediaContentUpload: 'https://8f7od6diif.execute-api.eu-west-1.amazonaws.com/staging/',
};

export const production = {
    aws: {
        url: 'https://uyuh4pjnwnd3fdltnovspiihwu.appsync-api.eu-west-1.amazonaws.com/graphql',
        region: 'eu-west-1',
        type: 'AWS_IAM',
    },
    cognito: {
        identityPoolId: 'eu-west-1:8429ef9e-4f57-45f6-8354-4ec9b80a5fb9',
        region: 'eu-west-1',
        userPoolId: 'eu-west-1_lVdmqTrRz',
        userPoolWebClientId: '52veqmv4vo352fqmsevpr7vjk3',
        mandatorySignIn: 'false',
    },
    forgotPassword: 'https://7efpy5nte8.execute-api.eu-west-1.amazonaws.com/prod',
    stripe: {
        publishableKey: 'pk_live_51NH68LDM6ttbLpZZ9D3XFVJQOePgGu0ItbGPuSSsI8qZPKE0c9HoFx4lJX8E7xBSa2XrTfD9weHF9fhWNaevReEm0002XTEgir',
        baseSessionUrl: `${subscriptionServiceBaseURL.production}/stripe/session`,
        subscriptionsServiceUrl: `${subscriptionServiceBaseURL.production}/stripe/subscriptions`,
        entitlementUrl: `${subscriptionServiceBaseURL.production}/entitlement`,
        entitlementDiscountCheckUrl: `${subscriptionServiceBaseURL.production}/entitlement/discount/check`,
    },
    entitlementTmpURL: `${subscriptionServiceBaseURL.production}/entitlement`,
    mediaContentUpload: 'https://mae26ewiy8.execute-api.eu-west-1.amazonaws.com/prod/',
};

export default {
    vendorUuid,
    resetPasswordRedirectUri,
    production,
    staging,
    copyPasteAgencyCourseProductID,
    agencyNavigatorCourseProductID,
    digitalLaunchpadResourceLibraryProductID,
    consonantPackageProductID,
    agencyIncubatorCourseProductID,
    agencyNavigatorResourceLibraryProductID,
    resourcesArticlesSectionID,
    resourcesVideoLibrarySectionID,
    resourcesQARecordingsID,
    upcomingQACallsID,
    stripeProductPlans,
    TRACKING_ID,
    qaRecordingID,
    contentLocking,
    bannerId,
    beaconKey
};
