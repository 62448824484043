import { LockClosedIcon, XIcon } from '@heroicons/react/outline';
import Modal from 'components/modals/Modal';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import useHabitTracker from 'to-package/app-habit-tracker/hooks/useHabitTracker';
import monkModeIcon from '../../../assets/img/icons/monkModeIcon.svg'
import monkModeIconInactive from '../../../assets/img/icons/monkModeIconInactive.svg'
import useHabitStreakHistory from 'to-package/app-habit-tracker/hooks/useHabitStreakHistory';
import { trackEvent } from 'utils/useAnalytics';

// interface MonkModeStartProps {
//     // TODO
// }

export const NON_NEGOTIABLES = [
    {
        title: '30 Minutes of Exercise',
        icon: 'https://d2ztsos3nab125.cloudfront.net/e23223d0-9ee1-41b4-8cf3-d0ad2e13af59/assets/images/81853183-7c95-4401-bd12-ec1c19339401.svg'
    },
    {
        title: '10 Minutes of Meditation',
        icon: 'https://d2ztsos3nab125.cloudfront.net/e23223d0-9ee1-41b4-8cf3-d0ad2e13af59/assets/images/3a7e74b6-2c7b-410a-b314-8bad55cd5066.svg'
    },
    {
        title: 'No Alcohol or Weed',
        icon: 'https://d2ztsos3nab125.cloudfront.net/e23223d0-9ee1-41b4-8cf3-d0ad2e13af59/assets/images/3665f872-9c2f-4304-8149-926a18cc0ee9.svg'
    },
];

export const NEGOTIABLE_ICON = 'https://d2ztsos3nab125.cloudfront.net/e23223d0-9ee1-41b4-8cf3-d0ad2e13af59/assets/images/4ff4cb8b-8457-4419-b27b-bda4ae64c1c4.svg';


const MONK_MODE_LENGTH_OPTIONS = [
    {
        length: 21,
        requirements: []
    }, {
        length: 30,
        requirements: [21]
    }, {
        length: 45,
        requirements: [21]
    }, {
        length: 60,
        requirements: [30, 45]
    }, {
        length: 75,
        requirements: [30, 45]
    }, {
        length: 90,
        requirements: [60, 75]
    }
]

    interface MonkModeStartCardProps {
    days: number;
    locked: boolean;
    requirements: number[];
    onClick: () => void;
}


const MonkModeStartCard = ({
    days,
    locked,
    requirements,
    onClick
}: MonkModeStartCardProps) => (
    <div className='p-4 sm:p-0 overflow-hidden glassCard flex justify-center items-center cursor-pointer rounded-[20px] relative' key={days} onClick={onClick} >
        {locked && (
            <div className='absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-black/20'>
                <LockClosedIcon className="h-10 w-10 text-[#CBD5E1]" aria-hidden="true" />
                <p className='text-[#CBD5E1] font-medium mt-2 w-1/2 text-center'>{`Complete the ${requirements?.length === 1 ? requirements[0] : requirements.join(' or ')} day Monk Mode to unlock`}</p>
            </div>
        )}
        <div className={`flex flex-col w-[70%] aspect-square rounded-full justify-center items-center border-[4px] border-[#ffffff14] glassCard text-[#cbd5ff33] hover:text-[#cbd5e166] transition-all ${locked && 'opacity-20'}`}>
            <p className='text-7xl sm:text-[120px]  font-bold leading-[100%] '>{days}</p>
            <p className='textlg sm:text-[24px]  font-bold leading-[100%] '>Monk Mode</p>
            <p className='textlg sm:text-[24px]  font-bold leading-[100%] '>Days</p>
        </div>
    </div>
)

interface MonkModeInputProps {
    value: string;
    disabled?: boolean;
    onChange?: (evt: any) => void;
    icon: string;
    iconInactive?: string;
}

const MonkModeInput = ({icon, iconInactive, value, disabled, onChange}: MonkModeInputProps) => (
    <div className='flex flex-row items-center glassCard backdrop-blur-md rounded-2xl sm:rounded-[20px] w-full py-2 sm:py-4 pl-2 sm:pl-5 mb-3'>
        <img src={!disabled && !value?.length ? iconInactive : icon} className='h-4 w-4 sm:h-6 sm:w-6 ' />
        <input className='w-full h-full pl-2 sm:pl-5 bg-transparent text-white !outline-none ' placeholder='Add a variable' value={value} disabled={disabled} onChange={onChange}/>
    </div>
)

const MonkModeStart = () => {
    const [open, setOpen] = React.useState(false);
    const [days, setDays] = React.useState(0);
    const history = useHistory();
    const onStart = (days: number) => {
        setDays(days);
        setOpen(true);
    }
    const [negotiables, setNegotiables] = React.useState([null, null, null]);
    const { completed } = useHabitStreakHistory();

    const updateNegotiable = (index: number, event: any) => {
        const newNegotiables = [...negotiables];
        newNegotiables[index] = event?.target?.value;
        setNegotiables(newNegotiables);
    }

    const getNegotiable = (index: number) => {
        return negotiables[index] || '';
    }

    const { createUpdateHabitGroup } = useHabitTracker();

    const onSubmit = async () => {
        const habits = [
            ...NON_NEGOTIABLES,
            ...negotiables.filter(n => !!n).map(title => ({ 
                title,
                icon: NEGOTIABLE_ICON,
             }))
        ];
        const habitGroup = {
            name: `${days} Day Monk Mode`,
            habits: habits?.filter(Boolean).map((habit, index) => ({
                habitName: habit?.title,
                frequency: 'daily',
                perDay: 1,
                status: 'active',
                habitDisplayOrder: index,
                imageUrl: habit?.icon,
            })),
            duration: days,
            durationUnits: 'day'
        }
        const response = await createUpdateHabitGroup({ habitGroupUuid: null, duration: days, metadata: habitGroup });
        trackEvent({
            action: 'MM_Started',
            category: 'MM_Name',
            label: `${days} Day Monk Mode`,
          });
        console.log('response', response);
        history.push('/monk-mode');
    }

    const [showWelcome, setShowWelcome] = useState(true)

    // monk mode start
    return (
        <div className='md:h-screen flex py-4 px-4 md:py-8 md:px-8 '>
            <div className='rounded-[30px] glassCard w-full h-full backdrop-blur-md'>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-8 h-full'>
                    {MONK_MODE_LENGTH_OPTIONS.map(({length, requirements}) => {
                        const locked = requirements?.length ? requirements.every((requirement) => !!requirement && !completed[requirement]) : false;
                        return (
                            <MonkModeStartCard requirements={requirements} days={length} locked={locked} onClick={() => !locked && onStart(length)} key={`startcard-${length}`} />
                        )
                    })}
                </div>
                <Modal
                    open={open}
                    setOpen={setOpen}
                    backgroundColor='rgba(12, 16, 24, 0.60)'
                >
                <div className="h-screen flex justify-center ] px-4 items-center">
                    <div className="mx-auto flex w-[95vw] sm:w-[70vw] flex-col glassCard backdrop-blur-md rounded-[30px] p-4 sm:p-10 text-[#CBD5E1] relative">
                        <div
                            onClick={() => setOpen(false)}
                            className="h-[40px] w-[40px] rounded-[12px] bg-[#17191C] flex justify-center items-center absolute top-[-16px] left-[-16px] border-[2px] border-[#FFFFFF08] hover:cursor-pointer"
                        >
                            <XIcon className="h-6 w-6 text-[#CBD5E1] hover:text-white transition-all" aria-hidden="true" />
                        </div>


                        <div className="flex flex-col sm:flex-row w-full gap-4 sm:gap-6 h-full ">
                            <div className="w-full rounded-[20px] overflow-hidden sm:mb-4 glassCard backdrop-blur-md p-3 sm:p-5">
                                <p className='mb-3 text-[#CBD5E1] text-[16px] font-medium text-left'>Non Negotiables</p>
                                {NON_NEGOTIABLES.map((item) => (
                                    <MonkModeInput value={item?.title} disabled key={item?.title} icon={item?.icon} />
                                ))}
                            </div>
                            <div className="w-full rounded-[20px] overflow-hidden mb-4 glassCard backdrop-blur-md p-3 sm:p-5">
                            <p className='mb-3 text-[#CBD5E1] text-[16px] font-medium text-left'>Custom Variables</p>
                                <MonkModeInput value={getNegotiable(0)} onChange={(evt: any) => updateNegotiable(0, evt)} icon={monkModeIcon} iconInactive={monkModeIconInactive} />
                                <MonkModeInput value={getNegotiable(1)} onChange={(evt: any) => updateNegotiable(1, evt)} icon={monkModeIcon} iconInactive={monkModeIconInactive} />
                                <MonkModeInput value={getNegotiable(2)} onChange={(evt: any) => updateNegotiable(2, evt)} icon={monkModeIcon} iconInactive={monkModeIconInactive} />
                            </div>

                        </div>
                        <button className='monkModeButton h-14  border-[2px] !rounded-[12px] text-[16px] !bg-[#1a1e26]' onClick={onSubmit}>
                            {`Start Your ${days} Day Monk Mode`}
                        </button>

                    </div>
                </div>
                </Modal>

                <Modal
                    open={showWelcome}
                    setOpen={setShowWelcome}
                    backgroundColor='rgba(12, 16, 24, 0.60)'
                >
                <div className="h-screen flex justify-center px-4 items-center">
                    <div className="mx-auto flex w-[40vw] flex-col glassCard rounded-[30px] relative px-14 py-6 backdrop-blur-md">
                        <div className='w-[77px] h-[77px] glassCard backdrop-blur-md self-center flex justify-center items-center rounded-[16px] mb-3'>
                            <img src={monkModeIcon} className='h-10 w-10' />
                        </div>
                        <div className="flex flex-col w-full mb-4">
                            <h1 className='text-[41px] text-[#CBD5E1] font-normal'> Welcome to Monk Mode</h1>
                            <p className='text-base text-[#CBD5E180] leading-[150%]'>This is a transformative mindset and approach to life, involving total immersion, enabling you to reach your fullest potential by shutting out distractions to achieve your biggest goals.</p>
                        </div>
                        <button className='monkModeButton h-14 border-[2px] !rounded-[12px] text-[18px] text-white' onClick={()=> setShowWelcome(false)}>
                            Get started
                        </button>
                    </div>
                </div>
                </Modal>
                </div>
        </div>
    )
}
export default MonkModeStart;
