import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge'


interface DashboardCardProps {
    to?: string;
    onClick?: (e: any)=>void;
    title: string | null;
    children: React.ReactNode;
    className?: string;
    titleClassName?: string;
    lgWidth: number;
    externalLink?: string | null
}

const DashboardCard = ({
    to,
    onClick,
    title,
    children,
    lgWidth,
    className,
    titleClassName,
    externalLink
}: DashboardCardProps) => {    
    return !!externalLink ?   
    <a
    href={externalLink}
    target="_blank"
    rel="noreferrer" onClick={onClick} className={twMerge(`flex-auto flex flex-col items-stretch w-full px-4 lg:px-6 2xl:px-8 pb-4 lg:pb-6 2xl:pb-8 pt-3 glassCard rounded-[16px] xl:rounded-[30px] mb-4 lg:mb-0 h-full max-h-full lg:max-h-96`, `lg:w-${lgWidth}`, className)}>
        {title && <span className={twMerge('text-base 2xl:text-lg pb-2', titleClassName)}>{title}</span>}
        {children}
    </a>
    :
    <Link to={to} onClick={onClick} className={twMerge(`flex-auto flex flex-col items-stretch w-full px-4 lg:px-6 2xl:px-8 pb-4 lg:pb-6 2xl:pb-8 pt-3 glassCard rounded-[16px] xl:rounded-[30px] mb-4 lg:mb-0 h-full max-h-full lg:max-h-96`, `lg:w-${lgWidth}`, className)}>
        {title && <span className={twMerge('text-base 2xl:text-lg pb-2', titleClassName)}>{title}</span>}
        {children}
    </Link>
}

export default DashboardCard;