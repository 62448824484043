import moment from "moment";
import parseMetadata from "../utils/parseMetadata";
import { useContext } from "react";
import { TimezoneContext } from "providers/TimezoneProvider";

const useSortCallsByStartTime = () => {
    const { localiseTimestamp } = useContext(TimezoneContext);

    const sortCalls = (calls) => {
        let response = calls?.map((item) => {
            if (moment.isMoment(item?.startTime)) return item;
            const metaData = parseMetadata(item);
            const {time: startTime} = localiseTimestamp(metaData?.startTime);
            return {
                ...item,
                startTime,
            };
        });
        
        response = response.sort((a, b) => {
            return a.startTime.diff(b?.startTime);
        });
        
        return response;
    }

    return sortCalls;
}

export default useSortCallsByStartTime;