import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import FAFListCard from "components/cards/FAFListCard";
import { SearchIcon, UserIcon } from "@heroicons/react/outline";
import Dropdown from "components/menu/Dropdown";
import filter from "../../../assets/img/icons/filter.svg";
import gridIcon from "../../../assets/img/icons/course-inactive.svg";
import FAFGridCard from "components/cards/FAFGridCard";
import { programs } from "constants/findAFriend/content";
import FAFUserModal from "components/modals/FAFUserModal";
import { useQuery } from "@apollo/react-hooks";
import searchUserPublicProfilesQuery from "../../../api/searchUserPublicProfiles.js";
import { useConfig } from "@genflow/core";
import { UserProfile, UserProfileSearchFilters } from "../../../@types/user-discovery";
import { orderBy, range } from "lodash";
import CustomLoader from "../../../components/CustomLoader";
import ReactPaginate from 'react-paginate';
import { UserDataContext } from "providers/UserDataProvider.js";
import useCountries from "../../../hooks/useCountries";

const FindAFriend = () => {
  const history = useHistory();
  const { vendorUuid } = useConfig();
  const [search, setSearch] = useState("");
  const [countrySearch, setCountrySearch] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const [filters, setFilters] = useState({} as UserProfileSearchFilters);
  const updateFilters = (changes: any) => setFilters({ ...filters, ...changes });

  const locationArr = filters?.location?.split(',')?.map((item:string)=> item?.trim())
  const locationCountry = locationArr?.[1] ?? locationArr?.[0]
  const locationCity = (locationArr?.length && locationArr?.length > 1) ? locationArr?.[0] : null;

  const {countries, cities} = useCountries(locationCountry)

  const [orderedCountryOptions] = useState(orderBy(countries?.map(( label ) => ({ label, value: label })), ["label"], ["asc"]));
  const orderedCityOptions = useMemo(()=> orderBy(cities?.map(( label ) => ({ label, value: label })), ["label"], ["asc"]), [cities]);
  const filteredCountryOptions = countrySearch ? orderedCountryOptions.filter(({ label }) => label.toLowerCase().startsWith(countrySearch.toLowerCase())) : orderedCountryOptions;
  const filteredCityOptions = citySearch ? orderedCityOptions.filter(({ label }) => label.toLowerCase().startsWith(citySearch.toLowerCase())) : orderedCityOptions;


  const [currentPage, setCurrentPage] = useState(0);
  const { data, error, loading } = useQuery(searchUserPublicProfilesQuery, {
    variables: {
      vendorUuid,
      search,
      filters,
      page: currentPage,
    }
  });
  const { page, pages } = data?.searchUserPublicProfiles ?? {};


  const [userProfiles, setUserProfiles] = useState([] as UserProfile[]);
  useEffect(() => {
    if (!loading && data) setUserProfiles(data?.searchUserPublicProfiles?.data);
  }, [data, loading]);

  const { userProfile } = useContext(UserDataContext);

  const fafOnboardComplete = userProfile?.data?.fafOnboardComplete;

  useEffect(() => {
    if (!!userProfile && !fafOnboardComplete) history.push("/find-a-friend/start");
  }, []);


  const [gridView, setGridView] = useState(false);
  const [showUserModal, setShowUserModal] = useState<any>(null);

  return (
    <div className="h-[92vh] lg:h-screen flex py-4 px-4 md:py-8 md:px-8">
      <div className="rounded-[30px] glassCard w-full h-full flex flex-col backdrop-blur-md p-4 md:p-8 overflow-hidden">
        <div className="w-full glassCard !border-[1px] rounded-[20px] px-4 py-3">
          <div className="flex flex-row items-center rounded-[10px] pl-4 mb-5 glassCard backdrop-blur-md !border-[1px]">
            <div className="flex items-center">
              <SearchIcon
                className="h-4 w-4 mr-2 inline grey500"
                aria-hidden="true"
              />
            </div>
            <input
              placeholder="Search by name, location, program, interests or something else"
              type="search"
              value={search}
              onChange={(e) => setSearch(e?.target?.value)}
              className="placeholder-[#42464E] outline-none rounded-full text-white text-sm focus:ring-blue-500 focus:outline-none block w-full py-3 pr-1.5 bg-transparent"
            />
          </div>

          {/* filters */}
          <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
            <div className="flex md:flex-row items-start self-start md:items-center mb-2 md:mb-0">
              <div className="hidden md:flex flex-row  md:items-center md:mr-4 ">
                <img src={filter} className="h-3 w-3 object-contain mr-1.5" />
                <p>Filters:</p>
              </div>
              <div
                className="flex items-center glassCard rounded-[10px] mr-4 px-4 self-start md:self-center pb-[0.4rem] pt-[0.2rem] ">
                <Dropdown
                  title={filters?.careerPath ?? "Career Path"}
                  options={programs}
                  onSelect={({ value: careerPath }) => updateFilters({ careerPath })}
                  smallDropDownWIdth="120px"
                  dropDownWidth={"200px"}
                  backgroundColor="#1A1E25"
                  showClearButton={!!filters?.careerPath}
                  onClickClear={() => updateFilters({ careerPath: undefined })}
                />
              </div>
              <div
                className="flex items-center glassCard rounded-[10px] mr-4 px-4 self-start md:self-center pb-[0.4rem] pt-[0.2rem] ">
                <Dropdown
                  title={locationCountry ?? "Country"}
                  options={filteredCountryOptions}
                  onSelect={({ value: location }) => updateFilters({ location })}
                  smallDropDownWIdth="120px"
                  dropDownWidth={"200px"}
                  backgroundColor="#1A1E25"
                  search={countrySearch}
                  setSearch={setCountrySearch}
                  showClearButton={!!filters?.location}
                  onClickClear={() => {
                    updateFilters({ location: undefined })
                    setCountrySearch('')
                  }}
                />
              </div>

             {orderedCityOptions?.length > 0 && <div
                className="flex items-center glassCard rounded-[10px] mr-4 px-4 self-start md:self-center pb-[0.4rem] pt-[0.2rem] ">
                <Dropdown
                  title={locationCity ?? "City"}
                  options={filteredCityOptions?.slice(0, 100)}
                  onSelect={({ value: city }) => updateFilters({ location: `${city}, ${locationCountry}` })}
                  smallDropDownWIdth="120px"
                  dropDownWidth={"200px"}
                  backgroundColor="#1A1E25"
                  search={citySearch}
                  setSearch={setCitySearch}
                  showClearButton={!!filters?.location}
                  onClickClear={() => {
                    updateFilters({ location: locationCountry })
                    setCitySearch('')
                  }}
                />
              </div>
              }
            </div>

            <div
              className="flex flex-row items-center glassCard rounded-xl  px-4 py-2 hover:cursor-pointer"
              onClick={() => setGridView(!gridView)}
            >
              <p className=" mr-1.5 text-[#CBD5E1] text-sm">{gridView ? "List View" : "Grid View"}</p>
              <img src={gridIcon} className="h-3 w-3 object-contain" />
            </div>
          </div>
        </div>

        <div className="grow w-full overflow-y-auto">
          <CustomLoader loading={loading}>
            <div
                className={`${
                  gridView
                    ? "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-4 mt-4"
                    : "h-[100%] pb-12"
                } `}
              >
                {
                  userProfiles?.map((userProfile) =>
                    gridView ?
                      <FAFGridCard
                        key={userProfile.cognitoSub}
                        userProfile={userProfile}
                        onClickUser={() => setShowUserModal(userProfile)}
                      />
                      :
                      <FAFListCard
                        key={userProfile.cognitoSub}
                        userProfile={userProfile}
                        onClickUser={() => setShowUserModal(userProfile)}
                      />
                  )
                }
                {userProfiles?.length === 0 && (
                  <div className="flex flex-col items-center justify-center w-full h-full">
                    <p className="text-white text-xl font-bold pt-16 pb-8">No results found.</p>
                    </div>
                )}

              </div>
            </CustomLoader> 
        </div>

        {!loading && userProfiles?.length > 0 &&
        <div className="flex items-center justify-between px-4 pt-3 sm:px-6">
            <div className="w-full flex flex-row justify-center mt-2 shrink-0 ">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next >"
                forcePage={currentPage}
                onPageChange={(e) => setCurrentPage(e.selected)}
                pageRangeDisplayed={5}
                pageCount={pages}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                containerClassName='flex gap-4'
                pageClassName='text-2xl'
                pageLinkClassName=''
                activeClassName='bg-white w-7 h-7 text-gray-900 text-center font-bold flex items-center justify-center rounded'
                activeLinkClassName=''
                previousClassName=''
                nextClassName=''
                disabledClassName=''
                disabledLinkClassName=''
              />
            </div>
          </div>
          }
      </div>


      <FAFUserModal
        showModal={!!showUserModal}
        setShowModal={setShowUserModal}
        userProfile={showUserModal}
      />
    </div>
  );
};

export default FindAFriend;
