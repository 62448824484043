import React, { useEffect, useMemo } from "react";
import ReactGA from "react-ga4";
import { useHistory, useParams } from "react-router-dom";
import { useConfig } from "@genflow/core";
import CustomLoader from "components/CustomLoader";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import useDownloader from "hooks/useDownloader";
import { nonDownloadable } from "constants/content";
import ResourceCard from "components/cards/ResourceCard";
import useContent from "../../../../hooks/useContent";


const downloadableAssets = (assets) =>
  assets.filter((item) => !nonDownloadable?.includes(item?.type));

const ResourceModules = () => {
  const history = useHistory();
  const { courseId, phaseId } = useParams();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  const {data: course, isLoading} = useContent({productId: courseId});

  const phase = useMemo(() => {
    if (!course?.sections?.length) {
      return [];
    }
    return course?.sections?.find((section) => +section?.id === +phaseId);
  }, [course, phaseId]);

  const filteredDownloads = useMemo(() => {
    if (!phase.lessons?.length) {
      return [];
    }
    return phase?.lessons?.reduce((result, lesson, index) => {
      if (lesson.assets) {
        result.push({
          name: lesson?.name,
          moduleNumber: index + 1,
          downloads: downloadableAssets(lesson.assets),
        });
      }
      return result?.filter((item) => item?.downloads?.length);
    }, []);
  }, [phase]);

  const metaData = course?.meta_data && JSON.parse(course?.meta_data);

  const { download } = useDownloader();

  const trackDownloads = (item) => {
    ReactGA.event('Files_Downloaded', {
      module_name: item?.name,
      phase_name: phase?.name,
      program_name: course?.name,
  });
  }

  return (
    <>
      <CustomLoader loading={isLoading}>
        <div className="min-h-[90vh] md:min-h-screen flex p-4 lg:p-8">
          <div className="glassCard !border-[1px] w-full rounded-[16px] md:rounded-[30px] px-4 md:px-10 py-8 relative">
            <div
              onClick={() => history.goBack()}
              className="h-[30px] w-[30px] md:h-[40px] md:w-[40px] rounded-[8px] md:rounded-[12px] bg-[#17191C] flex justify-center items-center absolute md:top-[-16px] top-[-10px] left-[-5px] md:left-[-16px] border-[2px] border-[#FFFFFF08] hover:cursor-pointer"
            >
              <ArrowLeftIcon
                className="h-5 w-5 md:h-6 md:w-6 text-[#CBD5E1] hover:text-white transition-all"
                aria-hidden="true"
              />
            </div>
            <div>
              <div className="w-full aspect-[2.77] md:aspect-[4.77] mb-5 rounded-[16px] md:rounded-[20px] flex justify-center items-center glassCard !border-[1px] hover:cursor-pointer overflow-hidden">
                {metaData?.bannerImage ? (
                  <img
                    src={metaData?.bannerImage}
                    className="h-full w-full object-cover"
                  />
                ) : (
                  // <img src={metaData?.productLogo} className="h-[50%] w-auto" />
                  <></>
                )}
              </div>
            </div>

            <div className="w-full grid grid-cols-2 md:grid-cols-3 gap-5">
              {filteredDownloads?.map((item, index) => (
                <ResourceCard
                    onClick={() =>  {
                      trackDownloads(item)
                      download(item?.downloads)
                    }}
                    title={item?.name}
                    subtitle={`Module ${item?.moduleNumber}`}
                />
              ))}
            </div>
          </div>
        </div>
      </CustomLoader>
    </>
  );
};

export default ResourceModules;
