import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';
// import styles from 'styled-components';
import { debounce, isEqual } from 'lodash';
import Pagination from './Pagination';
// import useResponsiveCarousel from '../../../hooks/useResponsiveCarousel';
import { useSnapCarousel } from 'react-snap-carousel';
import './styles.scss';
// const CarouselContainer = styles.div`
//     -ms-overflow-style: none;
//     scrollbar-width: none;

//     &::-webkit-scrollbar {
//         display: none;
//     }
// `;

export type CardsPerPageType = {
        lg: number,
        md: number,
        sm: number,
} | number;

interface CarouselProps {
    content: any[];
    render: (item: any, isSnapPoint: boolean, index: number) => React.ReactNode;
    cardsPerPage?: CardsPerPageType
}

const Carousel = ({
    content,
    render,
    cardsPerPage,
}: CarouselProps) => {
    // const containerRef = useRef(null);

    // const { pages } = useResponsiveCarousel(content, cardsPerPage);

    // const scrollToPage = (page: number) => {
    //     // Note: If we change number of cards shown, will need to pass into here
    //     console.log('containerRef.current.offsetWidth: ', containerRef.current.offsetWidth, page);
    //     const pageWidth = containerRef.current.offsetWidth;
    //     console.log('pageWidth * (page - 1 || 0): ', pageWidth * (page - 1 || 0));
    //     containerRef.current.scrollLeft = pageWidth * (page - 1 || 0);
    //     // Todo: May need to account for margins here if there's scroll bugs
    //     setScrolling(false);
    // };

    // const [currentPage, setCurrentPage] = useState(0);
    // const [scrolling, setScrolling] = useState(false);
    // const [atStart, setAtStart] = useState(true);
    // const [atEnd, setAtEnd] = useState(false);

    // const onContainerScroll = (e: any) => {
    //     onEndScroll();
    // };

    // const onEndScroll = useMemo(
    //     () => debounce(() => {
    //         const { scrollLeft, scrollWidth, offsetWidth } = containerRef.current;
    //         const newAtStart = scrollLeft === 0;
    //         const newAtEnd = scrollLeft === scrollWidth - offsetWidth;
    //         setScrolling(true);
    //         setCurrentPage(Math.round(scrollLeft / offsetWidth) + 1);
    //         setAtStart(newAtStart);
    //         setAtEnd(newAtEnd);
    //         setScrolling(false);
    //     }, 250),
    //     [],
    // );

    // useEffect(() => {
    //     if (!scrolling) {
    //         scrollToPage(currentPage);
    //     }
    // }, [currentPage]);

    const {
        scrollRef,
        pages,
        activePageIndex,
        prev,
        next,
        goTo,
        refresh,
        snapPointIndexes
    } = useSnapCarousel();

    // If content changes and is different to previous content, refresh carousel
    useEffect(() => {
        refresh();
    }, [content]);

    return (
        <div className='flex flex-col w-full'>
            <div
                ref={scrollRef}
                className="carousel-container overflow-x-auto flex flex-row overscroll-contain snap-x snap-mandatory scroll-smooth relative"
                // onScroll={(e) => onContainerScroll(e)}
            >
                {content?.map((item, index) => render(item, snapPointIndexes.has(index), index))}
            </div>
            <Pagination
                pages={pages}
                current={activePageIndex}
                onPrev={prev}
                onNext={next}
                onSelect={(page: number) => goTo(page)}
                atStart={activePageIndex === 0}
                atEnd={activePageIndex === pages.length - 1}
                cardsPerPage={3}
            />
        </div>
    );
};

const styles = {

}

export default Carousel;
