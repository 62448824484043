import { useEffect, useState } from 'react';

export default (defaultValue, key, disableSticky=false) => {
    const [value, setValue] = useState(() => {
        if(!disableSticky){
            const stickyValue = window.localStorage.getItem(key);
            return stickyValue !== null
                ? JSON.parse(stickyValue)
                : defaultValue;
        }

    });
    useEffect(() => {
        if(!disableSticky){
            if(value != null) {
                window.localStorage.setItem(key, JSON.stringify(value));
            }
        }
    }, [key, value]);

    return [value, setValue];
};
