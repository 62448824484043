import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { XIcon } from '@heroicons/react/outline';
import Modal from 'react-responsive-modal';
import upsertUserProfileData from '../../../../../api/upsertUserProfileData';
import { UserDataContext } from '../../../../../providers/UserDataProvider';
import { uploadImage } from '../../../../../utils/uploadImage';
import Loader from '../../../../../components/Loader';
import logo from '../../../../../assets/img/logo.svg';

export default function UserProfileModal({ open, setShowUserProfileModal }) {
    const [user, setUser] = useState({});
    const { userProfile, setUserProfile, userToken } = useContext(UserDataContext);
    const [loading, setLoading] = useState(false);
    const [upsertProfileData] = useMutation(upsertUserProfileData, {});

    const handleImageChange = (e) => {
        setLoading(true);
        uploadImage(e?.target?.files[0], userToken).then((res) => {
            if (res?.fileUrl) {
                setLoading(false);
                setUser({
                    ...user,
                    image: res?.fileUrl,
                });
            }
        }).catch((e) => {
            setLoading(false);
        });
    };

    const handleChangeText = (e) => {
        const { value } = e.target;
        setUser({
            ...user,
            [e.target.id]: value,
        });
    };


    useEffect(() => {
        userInfo();
    }, []);

    const userInfo = async () => {
        try {
            setUser({
                name: userProfile?.data?.name,
                email: userProfile?.data?.email,
                image: userProfile?.data?.image,
            });
        } catch (e) {
            console.log(e);
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (!user?.name.replace(/\s+/g, '')) {
            alert('Please enter username');
            return;
        }

        setUserProfile({
            ...userProfile,
            data: { ...userProfile?.data, name: user?.name, image: user?.image },
        });
        upsertProfileData({
            variables: {
                data: JSON.stringify({
                    ...userProfile?.data, name: user?.name, image: user?.image,
                }),
            },
        });
        setShowUserProfileModal(false);
    };


    return (
        <Modal
            open={open}
            center
            className="grey800Bg"
        >
            <div
                className="fixed top-0 left-0 inset-0 bg-black/75 h-full w-full z-50 flex justify-center overflow-x-hidden overflow-y-auto"
                id="my-modal"
            >
                <div className="w-11/12 md:w-3/12 flex justify-center">
                    <div
                        className="flex flex-col items-center self-center w-full relative"
                    >
                        <div className="flex items-center hover:cursor-pointer absolute top-0 right-0" onClick={() => setShowUserProfileModal(false)}>
                            <XIcon className="h-5 w-5 ml-2 hover:animate-pulse" />
                        </div>
                        <div className="mx-auto py-8 ">
                            <img
                                alt="genflow"
                                src={logo}
                            />
                        </div>
                        <h1 className="text-white text-2xl mb-3">GYA!</h1>

                        <div className="w-full mt-[20px]">
                            <div className="w-full flex flex-col">
                                <form onSubmit={onSubmit}>
                                    <div className="flex justify-center">
                                        <div className="mt-3 mb-5 self-center bg-[#393939] w-[130px] h-[130px] flex justify-center items-center rounded-full relative">
                                            <img src={user?.image} alt="" className={`w-[130px] h-[130px] rounded-full object-cover object-top opacity-${user?.image ? 1 : 0}`} />
                                            <label id="form-onboarding-image" htmlFor="onboarding-image" className="text-white flex items-center justify-center w-[130px] h-[130px] rounded-full absolute z-10 top-0 left-0">
                                                {(user?.image || loading) ? '' : '+ add image'}
                                                {loading && <Loader />}
                                                <input type="file" id="onboarding-image" alt="+ add photo" width="48" height="48" onChange={handleImageChange} className="h-0 w-0 opacity-0" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="mb-6 w-full">
                                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-white w-full ">
                                            Your name?
                                            <input type="text" id="name" onChange={handleChangeText} className="rounded-full mt-2 bg-[#393939] text-white text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" value={user?.name} required />
                                        </label>
                                    </div>
                                    <div className="mb-6 w-full">
                                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-white w-full ">
                                            Your email
                                            <input type="email" id="email" readOnly className="rounded-full mt-2 bg-[#393939] text-white text-sm focus:ring-blue-500 focus:outline-none block w-full p-2.5 " value={user?.email} required />
                                        </label>
                                    </div>

                                    <button type="submit" className="border-2 rounded-full text-white focus:outline-none font-medium w-full px-5 py-2.5 text-center flex flex-row items-center justify-center" disabled={loading}>
                                        Update
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Modal>
    );
}
