import React, { useEffect, useMemo, useState } from "react";
import { Container, Row } from "reactstrap";
import { useConfig } from "@genflow/core";
import { get, groupBy, parseInt, uniq } from "lodash";
import "./style.scss";
import { XIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import useCompleteLesson from "@genflow/web-courses/hooks/useCompleteLesson";
import CustomLoader from "../../../../components/CustomLoader";
import { trackEvent } from "../../../../utils/useAnalytics";
import brand from "../../../../components/Navbar/brand.svg";
import RecordingCard from "../../../../components/cards/RecordingCard.tsx";
import Dropdown from "../../../../components/menu/Dropdown.tsx";
import Modal from "../../../../components/modals/Modal";
import moment from "moment";
import upscaleVideo from "utils/upscaleVideo.js";
import useContent from "../../../../hooks/useContent";

const MonthsArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];


export default () => {
    const [activeSectionId, setActiveSectionId] = useState(null)

    const {
        agencyNavigatorResourceLibraryProductID,
        qaRecordingID,
        beaconKey
    } = useConfig();

    const {data, isLoading} = useContent({productId: agencyNavigatorResourceLibraryProductID});

    const { completeLesson, data: completedLessons } = useCompleteLesson(
        agencyNavigatorResourceLibraryProductID,
    );

    const qaRecordingsSection = get(data, 'sections', []).find(
        ({ id }) => parseInt(id) === qaRecordingID,
    );

    const qaSections = useMemo(() => {
        if (!qaRecordingsSection?.lessons?.length) {
          return [];
        }
        return qaRecordingsSection?.lessons?.map((item) => ({
          label: item?.name,
          value: item?.id,
          id: item?.id
        }));
      }, [qaRecordingsSection]);


    const activeQASection = useMemo(()=> {
        if(!!activeSectionId){
            return qaRecordingsSection?.lessons?.find((item)=> item?.id === activeSectionId)
        }
        return qaRecordingsSection?.lessons?.[0]
    } ,[qaRecordingsSection, activeSectionId])



    const activeSectionAssets = useMemo(()=> {
        if(!activeQASection){
            return []
        }
        const assets = activeQASection?.assets?.map((asset)=> {
            const metaData = asset?.meta_data && JSON.parse(asset?.meta_data)
            return{
            ...asset,
            meta_data: metaData ,
            year: moment(metaData?.startTime).format('YYYY'),
            month: moment(metaData?.startTime).format('MMMM')
            }
        })
        return assets;
    } ,[activeQASection])


    const [selectedSection, setSelectedSection] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, []);

    const videosByDate = (obj) => {
        return obj.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
    };

    const groupedByYear = groupBy(activeSectionAssets ,(item)=> item?.year)

    const yearsAscending = Object.keys(groupedByYear).sort(
        (a, b) => parseInt(b) - parseInt(a),
    );
    const sections = [];

    yearsAscending.forEach((year) => {
        const monthGroupObj = groupBy(groupedByYear[year], (item) => item?.month );

        const resultOrder = Object.keys(monthGroupObj).sort(
            (a, b) => MonthsArray?.indexOf(a) - MonthsArray.indexOf(b),
        );

        sections.push({
            name: year,
            months: resultOrder?.map(month => ({
                name: month,
                videos: monthGroupObj[month]?.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
            })),
        });
    });

    const categories = useMemo(() => {
        if (!sections?.length) {
          return [];
        }
        const allCategories = sections?.flatMap((section) =>
        section?.months?.flatMap((month) =>
            month?.videos?.flatMap((video) => video?.meta_data?.categories)
        ));
        return uniq(allCategories)?.map((item) => ({
          label: item,
          value: item,
        }));
    }, [sections]);

    const [categoryOptions, setCategoryOptions] = useState([]);

    const handleOptionClick = (option) => {
        const isSelected = !!categoryOptions.find((item)=> item?.value === option?.value);
        if (isSelected) {
        setCategoryOptions(
            categoryOptions.filter((selectedOption) => selectedOption?.value !== option?.value)
        );
        } else {
        setCategoryOptions([...categoryOptions, option]);
        }
    };

    const activeCategories = useMemo(
        () => categoryOptions?.map((item) => item?.value),
        [categoryOptions]
    );


    const videos = useMemo(()=> {
    const filteredVideos = sections
    .find(({ name: year }) => year === selectedSection?.year)
    ?.months?.find(({ name: month }) => month === selectedSection?.month)
    ?.videos || []

    if(activeCategories?.length){
        return filteredVideos?.filter((video) => video?.meta_data?.categories?.some((option) => activeCategories?.includes(option)) )
    }
    return filteredVideos
    },[sections , selectedSection])



    useEffect(() => {
        if (!isLoading && !selectedSection) {
            setSelectedSection({
                year: sections?.[0]?.name,
                month: sections?.[0]?.months?.reverse()?.[0]?.name,
            });
        }
    }, [isLoading, sections, selectedSection]);

    useEffect(() => {
        if (!isLoading) {
            setSelectedSection({
                year: sections?.[0]?.name,
                month: sections?.[0]?.months?.[0]?.name,
            });
        }
    }, [isLoading, activeQASection]);

    const onSelectVideo = (video) => {
        trackEvent({
            action: 'Q&A_View_Recording',
            category: 'Session_Name',
            label: video?.name,
        });
        setSelectedVideo(video);
    };

    const onSelectSection = (section) => {
        console.log({ section });
        setSelectedVideo(null);
        setSelectedSection(section);
    };

    useEffect(() => {
        if (selectedVideo) {
            if (get(selectedVideo, 'sproutId')) {
                console.log('your sprout id', get(selectedVideo, 'sproutId'));
                const player = new window.SV.Player({
                    videoId: get(selectedVideo, 'sproutId'),
                });
                player.play();
            }
        }
    }, [selectedVideo]);

    const completedSelectedVideo = useMemo(
        () => !!completedLessons.find(
            ({ id: cId }) => parseInt(cId) === parseInt(selectedVideo?.id),
        ),
        [selectedVideo, completedLessons],
    );

    const activeYear = useMemo(
        () => sections?.find(item => item?.name === selectedSection?.year),
        [sections, selectedSection],
    );

    useEffect(()=> {
        if(!!selectedVideo){
            window.Beacon('destroy')
            return;
        }
            window.Beacon('init', beaconKey)
    },[selectedVideo])

    return (
        <CustomLoader loading={isLoading}>
            <main className="h-[90vh] lg:h-screen flex py-4 px-4 md:py-8 md:px-8 ">
                <div className="flex glassCard w-full rounded-[20px] md:rounded-[30px] overflow-auto flex-col pb-4 ">
                    <div className='flex flex-col'>
                    <div className="w-full flex flex-col md:flex-row  justify-between md:items-center mt-6 px-6">
                        <div className='flex flex-col md:flex-row md:items-center'>
                            <p className="font-bold text-lg md:text-xl mr-3 mb-2 md:mb-0">{activeQASection?.name}</p>

                            <div className='flex flex-row items-center'>
                            <div className="flex items-center glassCard rounded-[10px] mr-4 px-4 self-start md:self-center pb-[0.4rem] pt-[0.2rem] mb-2 md:mb-0  ">
                                <Dropdown
                                    title={'Program'}
                                    options={qaSections}
                                    onSelect={(item) => setActiveSectionId(item?.id)}
                                    smallDropDownWIdth={'100px'}
                                />
                            </div>

                            <div className="flex items-center glassCard rounded-[10px] mr-4 px-4 self-start md:self-center pb-[0.4rem] pt-[0.2rem] ">
                                <Dropdown
                                    title={'All Categories'}
                                    options={categories}
                                    onSelect={(item) => handleOptionClick(item)}
                                    smallDropDownWIdth={'100px'}
                                    dropDownWidth={'200px'}
                                />
                            </div>
                            </div>
                        </div>

                        <div className="flex flex-row md:items-center">
                            <div className="flex flex-row items-center glassCard rounded-[10px] mr-4 px-6 py-[3px]">
                                <Dropdown
                                    title={selectedSection?.year}
                                    options={sections?.map(item => ({
                                        ...item,
                                        label: item?.name,
                                    }))}
                                    onSelect={item => onSelectSection({
                                        year: item?.name,
                                        month: item?.months?.[0]?.name,
                                    })
                                    }
                                    smallDropDownWIdth={'100px'}
                                />
                            </div>
                            <div className="flex flex-row items-center glassCard rounded-[10px] px-6 py-[3px]">
                                <Dropdown
                                    title={selectedSection?.month}
                                    options={activeYear?.months?.map(item => ({
                                        ...item,
                                        label: item?.name,
                                    }))}
                                    onSelect={item => onSelectSection({
                                        year: selectedSection?.year,
                                        month: item?.name,
                                    })
                                    }
                                    smallDropDownWIdth={'100px'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center mt-4 w-full overflow-auto px-6 mb-2">
                            {categoryOptions?.map((item) => (
                                <div
                                key={item}
                                onClick={() => handleOptionClick(item)}
                                className={`hover:cursor-pointer text-[#CBD5E1] backdrop-blur-md hover:text-white transition-all flex flex-none flex-row  glassCard !border-[1px] items-center px-4 py-2 grow-0 rounded-[10px] mr-2 relative overflow-hidden`}
                                >
                                <p className="mx-1 text-xs ">{item?.label}</p>
                                <XIcon className="h-3 w-3" />
                                </div>
                            ))}
                        </div>
                    </div>


                    <div className="mt-4 lg:mt-0 px-6 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 z-1">
                        {!selectedVideo
              && videos.map((video) => {
                  const completedVideo = !!completedLessons.find(
                      ({ id: cId }) => parseInt(cId) === parseInt(video.id),
                  );
                  const title = video?.title?.split(' - ')?.[1]
                  return (
                      <RecordingCard
                          key={video?.id}
                          image={video?.meta_data?.videoCover || video?.thumbnail || brand}
                          title={title}
                          onClick={() => onSelectVideo(video)}
                          completed={completedVideo}
                          onComplete={() => completeLesson(video?.id, !completedVideo)}
                          startDate={moment(video?.meta_data?.startTime).format('DD / MM / YY')}
                      />
                  );
              })}
                    </div>
                    <Container className="content-container">
                        <Row className="flex flex-col sm:flex-row" />
                    </Container>
                </div>

                <Modal open={!!selectedVideo} setOpen={() => setSelectedVideo(null)}>
                    <div
                        className="h-[100vh] md:h-full w-full rounded-[15px] my-auto overflow-hidden  flex justify-center items-center self-center "
                    >
                        <div className="p-3 bg-transparent">
                            <div className="mx-auto flex flex-col h-full md:h-[85vh] w-full md:w-[70vw] p-2 md:p-6  glassCard rounded-[20px] md:rounded-[30px] backdrop-blur-md">
                                <div className="flex flex-col md:flex-row justify-between md:items-center mb-5">
                                    <p className="text-white font-semibold text-xs md:text-base text-left my-2 md:my-0">
                                        {selectedVideo?.title || selectedSection?.name}
                                    </p>

                                    <div
                                        className={` ${!completedSelectedVideo && 'button !border-[1px]'} py-[8px] w-[190px] !rounded-[9px] flex justify-center items-center hover:cursor-pointer bg-gradient-to-r from-[#0384FE] to-[#03E5B4]`}
                                        onClick={() => completeLesson(
                                    selectedVideo?.id,
                                    !completedSelectedVideo,
                                        )}
                                    >

                                        <p className="text-white text-[10px] md:text-sm ">
                                            {completedSelectedVideo
                                                ? ' Completed'
                                                : ' Mark as complete'}
                                        </p>

                                        {
                                            completedSelectedVideo && <CheckCircleIcon className="text-white h-4 w-4 ml-[6px]" />
                                        }
                                    </div>
                                </div>
                                {!! selectedVideo?.url && (
                                    <iframe
                                        id="videoPlayer"
                                        title="video"
                                        className="aspect-video w-full rounded-[12px]"
                                        src={`${upscaleVideo(selectedVideo?.url)}?cc=eng&autoPlay=${true}`}
                                        style={{ background: '#101010' }}
                                        frameBorder="0"
                                        allowFullScreen
                                        width="100%"
                                        height="100%"
                                    />
                                )}

                            </div>
                            <div
                                onClick={() => setSelectedVideo(null)}
                                className="backdrop-blur-md h-[40px] w-[40px] rounded-[12px] bg-[#17191C] flex justify-center items-center absolute top-[30vh] md:top-0 left-0 border-[2px] border-[#FFFFFF08] hover:cursor-pointer"
                            >
                                <XIcon
                                    className="h-6 w-6 text-[#CBD5E1] hover:text-white transition-all"
                                    aria-hidden="true"
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            </main>
        </CustomLoader>
    );
};
