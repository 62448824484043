import React, { FC } from 'react';
import { CheckIcon } from '@heroicons/react/solid';
import toTitleCase from '../../utils/toTitleCase.ts';
import checkBox from '../../assets/img/icons/checkBox.svg';
import { trackEvent } from 'utils/useAnalytics.js';

type UpcomingCoachCardType ={
  image: string,
  title: string,
  day: string,
  startTime: string,
  endTime: string,
  coach: string,
  position: string,
  bio: string,
  icon: string,
  link: string,
  color: string,
  timezone: string,
}

const UpcomingCoachCard:FC<UpcomingCoachCardType> = ({
    image,
    title,
    day,
    startTime,
    endTime,
    coach,
    position,
    bio,
    icon,
    link,
    color,
    timezone,
}) => {
    const track = () => {
        trackEvent({
            action: 'Coaching_Reserved',
            category: 'Coaching_Name',
            label: title,
        });
    }
    
    return(
    <div className="w-[70vw] md:w-[50vw] lg:w-[60vw] max-h-[80vh] glassCard backdrop-blur-md rounded-[20px] lg:pr-2 z-1">
        <div className="flex flex-col lg:flex-row lg:h-[350px] 2xl:h-[500px]">
            <div className="bg-gradient-to-r from-[#0384FE] to-[#03E5B4] rounded-[20px] p-[2px] m-2 md:m-0 h-full md:h-[300px] lg:h-full lg:aspect-square lg:mr-5">
                <img
                    src={image}
                    alt="coach"
                    className="h-full w-full object-cover object-top rounded-[20px] bg-[#13171f]"
                />
            </div>
            <div className="flex flex-1 flex-col justify-between py-4 mx-2 mb-2 lg:mb-0 lg:ml-0 lg:mr-3 ">
                <div className="w-full flex flex-col justify-between flex-grow glassCard p-4 backdrop-blur-md rounded-[12px] overflow-y-auto">
                    <div>
                    <div className="flex flex-col lg:flex-row">
                        <div className={`rounded-[12px] bg-[${color}]/25 flex flex-row justify-center items-center px-4 lg:mr-2 h-[32px] mb-2 relative`}>
                            <div className="absolute left-0 top-0 h-full w-full glassCard rounded-[12px] " />
                            {!!icon && <img src={icon} alt="coach" className="h-4 w-4 " />}
                            <span className="text-xs ml-2 text-white text-left line-clamp-1">
                                {title}
                            </span>
                        </div>
                        <div className="glassCard rounded-[12px] flex flex-row items-center justify-center px-4  h-[32px]">
                            <span className="text-xs text-[#CBD5E1] text-left line-clamp-1">
                                {`${toTitleCase(day)} ${startTime} - ${endTime} ${timezone}`}
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-col justify-start items-start mt-2 max-h-[30vh] md:max-h-full">
                        <p className="text-[#CBD5E1] text-base lg:text-2xl">{coach}</p>
                        <p className="text-[#CBD5E180] text-sm text-left">{position}</p>

                        <div
                            dangerouslySetInnerHTML={{ __html: bio }}
                            className="text-[#CBD5E180] text-sm mt-3 text-left leading-[150%]"
                        />

                        {/* handles mobile fixed height scrolling */}
                        <div className="flex">
                            <a
                                href={link}
                                target="_blank"
                                rel="noreferrer"
                                className="md:hidden my-2 button glassCard backdrop-blur-md rounded-[12px] flex flex-row justify-center items-center py-3 px-8 outline-none w-full lg:w-auto"
                                onClick={track}
                            >
                                <p className="text-[#CBD5E1] text-base mr-2">Reserve your spot</p>
                                <img alt="check box" src={checkBox} className="h-4 w-4 text-gray-400" />
                            </a>
                        </div>
                    </div>
                    </div>

                    <div className="flex">
                    <a
                        href={link}
                        target="_blank"
                        rel="noreferrer"
                        className="hidden button glassCard backdrop-blur-md rounded-[12px] md:flex flex-row justify-center items-center py-3 px-8 outline-none w-full lg:w-auto"
                        onClick={track}
                    >
                        <p className="text-[#CBD5E1] text-base mr-2">Reserve your spot</p>
                        <img alt="check box" src={checkBox} className="h-4 w-4 text-gray-400" />
                    </a>
                </div>
                </div>
            </div>
        </div>
    </div>
)};

export default UpcomingCoachCard;
