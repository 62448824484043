import React, { FC } from 'react'
import { LockClosedIcon } from '@heroicons/react/outline';
import useCountDown from 'hooks/useCountDown';

type PhaseCardProps = {
    image: string, 
    phaseNumber: number, 
    name: string, 
    locked: boolean,
    startTime: string
}

const PhaseCard:FC<PhaseCardProps> = ({image, phaseNumber, name, locked, startTime}) => {
const countDown = useCountDown(startTime, true);
return (
    <div className={`w-[150px] h-[100px] md:w-[200px] md:h-[120px] rounded-[16px] shrink-0 key={index} overflow-hidden relative`}>
        <img
        src={image}
        alt="phase video"
        className="object-cover object-center w-full h-full"
        />
        <div className="w-full h-full absolute bottom-0 flex justify-end items-end bg-black/25 ">
        <p className="text-[5rem] md:text-[7rem] leading-[60px] md:leading-[90px] font-bold text-white/[0.15]">
            {phaseNumber}
        </p>
        </div>
        <div className="absolute bottom-[10px] w-full px-2 flex flex-col justify-start items-start">
        <p className="text-[#CBD5E1] font-medium mb-1 text-left text-[12px] md:text-[14px]">
            {name}
        </p>
        </div>
        {locked && (
        <div className='absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-black/80'>
            <LockClosedIcon className="h-5 w-5 text-[#CBD5E1]" aria-hidden="true" />
            {!!countDown && 
            <div className='pb-2 px-2'>
                <p className='text-[#CBD5E1] text-sm font-semibold mt-1'>COMING SOON</p>
                <div className='flex flex-row items-start'>
                    <div className='flex flex-col items-center'>
                    <p className='font-bold text-[12px] md:text-[14px] w-4 md:w-8 text-center leading-normal'>{countDown?.days}</p>
                    <p className='text-[9px] font-light mt-[-4px]'>DAYS</p>
                    </div>
                    <p className='font-bold text-[12px] md:text-[14px] mx-[1px]'>:</p>
                    <div className='flex flex-col items-center'>
                    <p className='font-bold text-[12px] md:text-[14px] w-4 md:w-8 text-center leading-normal'>{countDown?.hours}</p>
                    <p className='text-[9px] font-light mt-[-4px]'>HOURS</p>
                    </div>
                    <p className='font-bold text-[12px] md:text-[14px] mx-[1px]'>:</p>
                    <div className='flex flex-col items-center'>
                    <p className='font-bold text-[12px] md:text-[14px] w-4 md:w-8 text-center leading-normal'>{countDown?.minutes}</p>
                    <p className='text-[9px] font-light mt-[-4px]'>MINS</p>
                    </div>
                    <p className='font-bold text-[12px] md:text-[14px] mx-[1px]'>:</p>
                    <div className='flex flex-col items-center'>
                    <p className='font-bold text-[12px] md:text-[14px] w-4 md:w-8 text-center leading-normal'>{countDown?.seconds}</p>
                    <p className='text-[9px] font-light mt-[-4px]'>SECS</p>
                    </div>
                </div> 
            </div>
        }
        </div>
        )}
    </div>
  )
}

export default PhaseCard;
