import { UserProfileContactMethod } from "../@types/user-discovery";

export default (contactMethod: UserProfileContactMethod) => {
  if(!contactMethod?.platform) return '#';
  // if(contactMethod?.platform === 'email') return `mailto:${contactMethod.method}`
  if(contactMethod?.method?.startsWith('http')
    || ['customWebsite', 'community'].indexOf(contactMethod?.platform) > -1) return contactMethod.method;

  return {
    instagram: `https://www.instagram.com/${contactMethod?.method?.replace('@', '')}`,
    twitter: `https://www.twitter.com/${contactMethod?.method}`,
    youtube: `https://www.youtube.com/${contactMethod?.method?.indexOf('@') === -1 ? '@' : ''}${contactMethod?.method}`,
  }?.[contactMethod.platform as string]

}
