import { UserProfile } from "../@types/user-discovery";
import getContactMethodUrl from "./getContactMethodUrl.ts";

export default (userProfile: UserProfile) => {
  const activeUserContactMethods = userProfile?.contactMethods?.filter(({ method }) => method.length > -1);
  const communityConnectMethod = activeUserContactMethods?.find(({ platform }) => platform === "community");

  // Prefer community contact platform
  if (communityConnectMethod) return {
    platform: communityConnectMethod.platform,
    url: getContactMethodUrl(communityConnectMethod)
  };

  return {
    platform: activeUserContactMethods?.[0]?.platform,
    url: getContactMethodUrl(activeUserContactMethods?.[0] ?? null)
  };

}
