import { useEffect, useState, useContext } from 'react';
import useUserAccess from './useUserAccess';
import { CognitoUserContext } from '../providers/CognitoUserProvider';
import axios from "axios";

export default () => {
  const [discordCommunityUrl, setDiscordCommunityUrl] = useState(undefined);

  const { userAccess } = useUserAccess();

  const hasDigitalLaunchPad = !!userAccess?.find(({ contentId }: { contentId: string }) => contentId === "6196");
  const hasAgencyNavigator = !!userAccess?.find(({ contentId }: { contentId: string }) => contentId === "6018");
  const onlyDigitalLaunchPad = !hasAgencyNavigator && hasDigitalLaunchPad;

  const { cognitoUser } = useContext(CognitoUserContext);

  useEffect(() => {
    if (onlyDigitalLaunchPad && !!cognitoUser?.attributes?.email) {
      (async () => {
        const response = await axios.get(`https://discord-bvblwyj72q-uc.a.run.app/discord/login?email=${encodeURIComponent(cognitoUser?.attributes?.email)}`);
        const redirectUrl = response?.data?.redirect_url;
        if (redirectUrl) setDiscordCommunityUrl(redirectUrl);
      })();
    }
  }, [cognitoUser, onlyDigitalLaunchPad]);

  return {
    discordCommunityUrl,
    facebookCommunityUrl: 'https://www.facebook.com/groups/agencynav'
  }
}
