import {
    ApolloClient, ApolloLink, ApolloProvider, defaultDataIdFromObject, HttpLink, InMemoryCache
} from "@apollo/client";
import { createHttpLink } from 'apollo-link-http';
import AppSyncConfig from '../init/appsync-configure';
import { createAppSyncLink } from './appSyncLink';

const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation).map((data) => {
        return data;
    });
});

const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
        // addTypename: false
        dataIdFromObject(responseObject) {
            switch (responseObject.__typename) {
                case 'UserPublicProfile': return responseObject?.cognitoSub;
                default: return defaultDataIdFromObject(responseObject);
            }
            // return defaultDataIdFromObject(responseObject);
        }
    }),
    link: ApolloLink.from([
        // cleanTypeName,
        createAppSyncLink(),
        createHttpLink({
            uri: AppSyncConfig.url,
        }),
    ]),
});

export default apolloClient;
