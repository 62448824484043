import circleGradientCheck from '../../assets/img/icons/circleGradientCheck.svg';
import circleCheck from '../../assets/img/icons/circleCheck.svg';

import { useEffect, useState } from 'react';

interface HabitCardProps {
    tracked: boolean;
    name: string;
    icon: string;
    onClick: () => void;
}

const HabitCard = ({ tracked, name, icon, onClick }: HabitCardProps) => {
    const [complete, setComplete] = useState(false);

    useEffect(() => {
        setComplete(tracked)
    }, [tracked]);

    return (
        <div
          className={`flex-1 max-h-16 px-2 sm:px-4 mt-3 py-2 sm:py-3 glassCard flex items-center justify-between rounded-[20px] cursor-pointer`}
          onClick={() => {
            setComplete(true);
            onClick();
          }}
        >
          <div className="flex justify-center items-center flex-row">
          <img src={icon} className="w-4 h-4 2xl:h-5 2xl:w-5 mr-2 sm:mr-4 ml-1 sm:ml-2"/>
            <div className='text-[#CBD5E1] font-medium text-sm sm:text-base'>{name}</div>
          </div>
          <div className="flex justify-center items-center w-7 h-7">
            <img src={complete ? circleGradientCheck : circleCheck} className="h-full w-full"/>
          </div>
        </div>
      )
};

export default HabitCard;
