import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { UserDataContext } from '../../../providers/UserDataProvider';
import countries from '../../../constants/registration/countriesData.json';
import { sortBy } from 'lodash';


const OnboardingInfo4 = ({ next }) => {
    const [info, setInfo] = useState({
        country: countries?.find((country)=> country?.name === 'United Kingdom')?.name
    });
    const { userProfile, setUserProfile } = useContext(UserDataContext);

    console.log({userProfile})

    const handleChange = (e) => {
        const { value } = e.target;
        setInfo({
            ...info,
            [e.target.id]: value,
        });
    };

    const onSubmit = (e) => {
        next(e, {
            ...userProfile,
            data: { 
                ...userProfile?.data, 
                ...info,
                onboardingStageId: 5,
            },
        });
    };



    return (
        <div className="w-full flex flex-col mt-2">
            <form onSubmit={onSubmit}>
                <div className="mb-4 w-full">
                    <label htmlFor="address" className="block mb-2 text-sm font-medium text-white w-full ">
                        What's your address?
                        <input type="text" id="address" value={userProfile?.data?.address} onChange={handleChange} className="rounded-md glassCard backdrop-blur-md mt-2  text-white text-sm focus:ring-blue-500 focus:outline-none block w-full p-2.5 " placeholder="address" required />
                    </label>
                </div>
                <div className="mb-4 w-full">
                    <label htmlFor="city" className="block mb-2 text-sm font-medium text-white w-full ">
                        What city?
                        <input type="text" id="city" value={userProfile?.data?.city} onChange={handleChange} className="rounded-md glassCard backdrop-blur-md mt-2  text-white text-sm focus:ring-blue-500 focus:outline-none block w-full p-2.5 " placeholder="city" required />
                    </label>
                </div>
                <div className="mb-4 w-full">
                    <label htmlFor="state" className="block mb-2 text-sm font-medium text-white w-full ">
                        Which state/region?
                        <input type="text" id="state" value={userProfile?.data?.state} onChange={handleChange} className="rounded-md glassCard backdrop-blur-md mt-2  text-white text-sm focus:ring-blue-500 focus:outline-none block w-full p-2.5 " placeholder="state" required />
                    </label>
                </div>
                <div className="mb-4 w-full">
                    <div htmlFor="name" className="block mb-2 text-sm font-medium text-white w-full ">
                        Which country?
                        <div className=" pr-2.5 rounded-md glassCard backdrop-blur-md overflow-hidden mt-2">
                            <select value={info?.country} id="country" onChange={handleChange} className="bg-transparent text-white text-sm  focus:outline-none focus:border-blue-500 block w-full !p-2.5">
                                {sortBy(countries, 'name').map(option => (
                                    <option value={option?.name} key={option?.id}>{option?.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="mb-4 w-full">
                    <label htmlFor="zip" className="block mb-2 text-sm font-medium text-white w-full ">
                        What's your zip/postal code?
                        <input type="text" id="zip" value={userProfile?.data?.zip} onChange={handleChange} className="rounded-md glassCard backdrop-blur-md mt-2  text-white text-sm focus:ring-blue-500 focus:outline-none block w-full p-2.5 " placeholder="zip" required />
                    </label>
                </div>
                               
                <button
                    type="submit"
                    className="button flex w-full justify-center rounded-md  px-3 py-3 2xl:py-4 text-lg md:text-lg font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-4"
                >
                    Get started
                </button>
            </form>
        </div>
    );
};

export default OnboardingInfo4;
