import { XCircleIcon } from "@heroicons/react/outline";

const ComingSoon = () => {
    return (
        <div className="h-screen flex justify-center px-4 items-center">
        <div className="mx-auto flex w-[90vw] lg:w-[40vw] flex-col glassCard rounded-[30px] relative px-10 py-6 backdrop-blur-md text-center">
            <div className='w-[77px] h-[77px] glassCard backdrop-blur-md self-center flex justify-center items-center rounded-[16px] mb-3'>
                <XCircleIcon className='h-10 w-10 text-[#CBD5E1]' />
            </div>
            <div className="flex flex-col w-full h-full mb-1">
                <h1 className='text-[41px] text-[#CBD5E1] font-normal mb-4'>{'Coming soon'}</h1>
                <p className='text-[16px] text-[#CBD5E180] leading-[150%]'>{
                    'This feature is coming soon. Please check back later.' 
                }</p>
            </div>
        </div>
    </div>
    )
}

export default ComingSoon;