import { useConfig } from '@genflow/core';
import getUserHabitGroupsStatsQuery from "../../app-habit-tracker/api/getUserHabitGroupsStats.query";
import { useQuery } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';
import { chain, keyBy, mapValues } from 'lodash';

const useHabitStreakHistory = (): {
    completed: any;
} => {
    const { vendorUuid } = useConfig();
    const { data, error, loading } = useQuery(getUserHabitGroupsStatsQuery, {
      variables: { vendorUuid },
      skip: !vendorUuid
    });
    const [completed, setCompleted] = useState({});

    useEffect(() => {
        const habitGroupStats = data?.getUserHabitGroupsStats?.data as HabitGroupStats

        if(!loading && habitGroupStats?.byCompletionStatus?.length > 0){
            const completedData = habitGroupStats.byCompletionStatus.find(({completionStatus}) => completionStatus === 'FINISHED');
            try {
                const completed = mapValues(keyBy(completedData?.byDuration, 'duration'), 'total');
                setCompleted(completed);
            } catch (error) {
                console.log('error', error)
            }
            
        }
    }, [data, loading])

    return {
        completed,
    }
}

export default useHabitStreakHistory;