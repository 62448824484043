import React, { useContext, useEffect, useMemo, useState } from "react";
import { useConfig } from "@genflow/core";
import useGetCourses from "@genflow/web-courses/hooks/useGetCourses";
import moment from "moment";
import { UserDataContext } from "../../../providers/UserDataProvider";
import useUpcomingQA from "../../../hooks/useUpcomingQA";
import "../CoursesStyles.css";
import BannerCard from "../../../components/cards/BannerCard.tsx";
import MonkModeTrackerCard from "../../../components/cards/MonkModeTrackerCard.tsx";
import CoachCard from "../../../components/cards/CoachCard.tsx";
import ProgressCard from "../../../components/cards/ProgressCard.tsx";
import Loader from "../../../components/Loader";
import coming from "../../../assets/img/coming.png";
import Carousel from "components/carousels/Carousel.tsx";
import DashboardCard from "components/cards/DashboardCard.tsx";
import Modal from "../../../components/modals/Modal.tsx";
import { XIcon } from "@heroicons/react/outline";
import UpcomingCoachCard from "components/cards/UpcomingCoachCard.tsx";
import { findIndex, isEqual } from "lodash";
import { useHistory } from "react-router";
import useHabitStreakHistory from "to-package/app-habit-tracker/hooks/useHabitStreakHistory.ts";
import useUserAccess from "../../../hooks/useUserAccess";
import useContent from "../../../hooks/useContent";
import useWelcomeVideo from "../../../hooks/useWelcomeVideo.ts";

const agencyNavCourseId = "6018";
export default () => {
  const { vendorUuid, agencyNavigatorResourceLibraryProductID, bannerId } = useConfig();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);


  const { userAccess, isLoading} = useUserAccess();
  const accessibleContentIds = userAccess?.map(({contentId}) => contentId)?.filter(Boolean);
  const hasAccess = !!userAccess?.find(({key}) => key === 'analytics.read');

  const courses = useGetCourses(vendorUuid);
  const coursesWithEntitlements = courses?.data?.filter(({ id }) => accessibleContentIds?.includes(id) && id !== agencyNavigatorResourceLibraryProductID.toString())

  const {data: resourceLibrary, isLoading: resourceLibraryLoading} = useContent({productId: agencyNavigatorResourceLibraryProductID});

  const banner = useMemo(() => {
    const bannerSection = resourceLibrary?.sections?.length && resourceLibrary?.sections?.find((item) => +item?.id === +bannerId);
    const bannerItem = bannerSection?.lessons?.[0]?.assets?.[0];
    return {
      ...bannerItem,
      meta_data: bannerItem?.meta_data && JSON.parse(bannerItem?.meta_data)
    };
  }, [resourceLibrary]);


  const { userProfile, setUserProfile } = useContext(UserDataContext);

  const userProgress = userProfile?.data?.progress;
  const progressData = useMemo(() => {
    if (userProgress) {
      return Object?.keys(userProgress);
    }
    return [];
  }, [userProfile]);

  const filterProgress = () => {
    if (!courses.loading) {
      // no course in progress return first video in agency navigator
      if (!progressData?.length) {
        const course = coursesWithEntitlements?.[0];
        if(course) return [
          {
            ...course,
            lesson: course?.sections?.[0]?.id,
            video: course?.sections?.[0]?.lessons?.[0]?.id,
            lessonIndex: 1
          }
        ];
      }
      const filteredProgressCourses = coursesWithEntitlements?.filter((item) =>
        progressData?.includes(item?.id)
      );
      const progress = filteredProgressCourses?.map((item) => ({
        ...item,
        lesson: userProgress?.[item?.id]?.lesson,
        video: userProgress?.[item?.id]?.video,
        lessonIndex: findIndex(item?.sections, (lesson) => lesson?.id === userProgress?.[item?.id]?.lesson)
      }))?.reverse();
      return progress;
    }
    return [];
  };

  const coursesInprogress = filterProgress();
  const { todayQA } = useUpcomingQA();

  const monkModeTracker = {
    image: coming,
    coach: "Paul Daley",
    title: "Six figures sales rep",
    completedDays: 26,
    streaks: [
      {
        title: "deep work",
        completedDays: 27,
        percentageCompleted: 30,
        gradient: ["#FF4117", "#832F00"]
      },
      {
        title: "exercise",
        completedDays: 52,
        percentageCompleted: 60,
        gradient: ["#26D8FF", "#0945DF"]
      },
      {
        title: "meditation",
        completedDays: 10,
        percentageCompleted: 15,
        gradient: ["#5DFF34", "#197D00"]
      }
    ],
    days: [
      {
        title: "mon",
        day: 2,
        percentageCompleted: 30
      },
      {
        title: "tue",
        day: 3,
        percentageCompleted: 10
      },
      {
        title: "Wed",
        day: 4,
        percentageCompleted: 50
      },
      {
        title: "Thur",
        day: 5,
        percentageCompleted: 40
      },
      {
        title: "Fri",
        day: 6,
        percentageCompleted: 80
      },
      {
        title: "Sat",
        day: 7,
        percentageCompleted: 70
      },
      {
        title: "Sun",
        day: 8,
        percentageCompleted: 100
      }
    ]
  };

  const [coachModal, setCoachModal] = useState(null);
  const [welcomeModal, setWelcomeModal] = useState(null);

  useEffect(() => {
    if (!isEqual(!userProfile?.data?.welcomeComplete, welcomeModal) && !isLoading) {
      setWelcomeModal(!userProfile?.data?.welcomeComplete);
    }
  }, [userProfile?.data?.welcomeComplete, isLoading]);

  const history = useHistory();

  const completeWelcome = () => {
    history.push("/programs");
    setUserProfile({
      ...userProfile,
      data: { ...userProfile?.data, welcomeComplete: true }
    });
  };

  const { completed } = useHabitStreakHistory();
  useEffect(() => {
    // check if 21 day monkMode is completed & update user profile
    if (!!completed?.["21"] && !userProfile?.data?.mm21Completed) setUserProfile({
      ...userProfile,
      data: { ...userProfile?.data, mm21Completed: true }
    });
  }, [completed]);


  const { welcomeVideoUrl } = useWelcomeVideo();

  return (
    <>
      <div className="lg:h-screen flex flex-col py-4 px-4 md:py-8 md:px-8 gap-4 2xl:gap-8 overflow-hidden">
        <div className="flex-col flex lg:flex-row gap-4 2xl:gap-8 overflow-hidden flex-1 lg:max-h-96">
          <BannerCard
            image={banner?.url}
            startTime={banner?.meta_data?.startTime}
            link={banner?.meta_data?.url}
            loading={resourceLibraryLoading}
          />
          <MonkModeTrackerCard monkModeTracker={monkModeTracker} />
        </div>

        <div className="flex-col lg:flex-row flex b gap-4 2xl:gap-8 flex-1 overflow-hidden">
          <DashboardCard
            title="Coaching Today"
            lgWidth={32}
          >
            {courses?.loading && (
              <div className="flex w-full h-full justify-center items-center">
                <Loader />
              </div>
            )}
            <div className="mt-2 flex flex-col flex-wrap overflow-hidden gap-2 overflow-y-auto">
              {todayQA?.length
                ? todayQA?.map((item, index) => (
                  <CoachCard
                    key={index}
                    title={item?.title}
                    image={item?.img}
                    startTime={item?.startTime}
                    endTime={item?.endTime}
                    onClick={() => setCoachModal(item)}
                    timezone={item?.timezone}
                  />
                ))
                :
                !courses?.loading &&
                <div className="w-full flex justify-between items-center glassCard backdrop-blur-md rounded-[20px] p-4">
                  <p className="text-lg text-[CBD5E1] line-clamp-1"> No calls for today</p>
                </div>
              }
            </div>
          </DashboardCard>

          <DashboardCard
            title="Resume where you left off"
            lgWidth={60}
            className="px-0 lg:px-0 xl:px-0 2xl:px-0 !pb-2"
            titleClassName="px-4 lg:px-6 2xl:px-8"
          >
            <div className="w-full h-full flex flex-row overflow-hidden">
              {courses?.loading && (
                <div className="flex w-full h-full justify-center items-center">
                  <Loader />
                </div>
              )}
              {!courses?.loading && coursesWithEntitlements?.length && (
                <Carousel
                  content={coursesInprogress}
                  render={(item, isSnapPoint, index) => (
                    <ProgressCard
                      image={item?.meta_data && (JSON.parse(item?.meta_data)?.banner || JSON.parse(item?.meta_data)?.profile?.[0]?.thumbnail)}
                      logo=""
                      key={item.name}
                      name={item.name}
                      imageSrc={item.background_image}
                      // brandSrc={item?.meta_data && JSON.parse(item.meta_data)?.productLogo}
                      brandSrc={null}
                      description={item.description}
                      productID={item.id}
                      sections={item.sections}
                      invite={item?.meta_data && JSON.parse(item.meta_data)?.blocked}
                      access={hasAccess}
                      lesson={item?.lesson}
                      video={item?.video}
                      progress
                      isSnapPoint={isSnapPoint}
                      isFirst={index === 0}
                      isLast={index === coursesInprogress.length - 1}
                      lessonIndex={item?.lessonIndex >= 0 && item?.lessonIndex + 1}
                    />
                  )}
                />
              )}

            </div>
          </DashboardCard>
        </div>


        {/* coach modal */}
        <Modal
          open={!!coachModal}
          setOpen={setCoachModal}
          opacity={70}
        >
          <div className="py-3 px-3 flex justify-center items-center">
            <div
              className="p-4 lg:p-6 overflow-y-auto rounded-[20px] md:rounded-[30px] glassCard backdrop-blur-md relative">
              {!!coachModal &&
                <UpcomingCoachCard
                  image={coachModal?.img}
                  title={coachModal?.title}
                  day={coachModal?.day}
                  startTime={moment(coachModal?.startTime).format("LT")}
                  endTime={moment(coachModal?.endTime).format("LT")}
                  coach={coachModal?.name}
                  position={coachModal?.position}
                  icon={coachModal?.callIcon}
                  bio={coachModal?.description}
                  link={coachModal?.link}
                  color={coachModal?.color}
                  timezone={coachModal?.timezone}
                />
              }
            </div>
            <div
              onClick={() => setCoachModal(false)}
              className="h-[40px] w-[40px] rounded-[12px] bg-[#17191C] z-99 flex justify-center items-center absolute top-0 left-0 border-[2px] border-[#FFFFFF08] hover:cursor-pointer glassCard backdrop-blur-md"
            >
              <XIcon
                className="h-6 w-6 text-[#CBD5E1] hover:text-white transition-all"
                aria-hidden="true"
              />
            </div>
          </div>
        </Modal>

        {/* Welcome modal */}
        <Modal
          open={!!welcomeModal && !!userProfile?.data?.onboardingCompleted}
          setOpen={completeWelcome}
          opacity={70}
        >
          <div className="py-3 px-3 flex justify-center items-center">
            <div
              className="p-4 lg:p-6 overflow-y-auto rounded-[20px] md:rounded-[30px] glassCard backdrop-blur-md relative flex-col">
              <iframe
                src={welcomeVideoUrl}
                allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                className="h-full w-full rounded-lg flex-1 sm:min-w-[80vw] sm:aspect-video lg:min-w-[60vw] 2xl:min-w-[50vw]"></iframe>

              <button
                className={`w-full button h-14 border-[2px] !rounded-[12px] text-[18px] text-white flex justify-center items-center mt-4`}
                onClick={completeWelcome}
              >
                View Courses
              </button>
            </div>
            <div
              onClick={() => setWelcomeModal(false)}
              className="h-[40px] w-[40px] rounded-[12px] bg-[#17191C] z-99 flex justify-center items-center absolute top-0 left-0 border-[2px] border-[#FFFFFF08] hover:cursor-pointer glassCard backdrop-blur-md"
            >
              <XIcon
                className="h-6 w-6 text-[#CBD5E1] hover:text-white transition-all"
                aria-hidden="true"
              />
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
