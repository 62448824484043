import React, { useContext, useState } from 'react';
import Datepicker from "react-datepicker";
import { UserDataContext } from '../../../providers/UserDataProvider';
import './index.css';
import './datePicker.css';


const binaryOptions = [
    {
        label: 'Yes',
        value: 'yes',
    },
    {
        label: 'No',
        value: 'no',
    },
];

const OnboardingInfo2 = ({ next }) => {
    const { userProfile, setUserProfile } = useContext(UserDataContext);
    const [info, setInfo] = useState({
        currentlySchooling: binaryOptions?.[0]?.value,
        currentlyEmployed: binaryOptions?.[0]?.value,
        dob: new Date()
    });

    const handleChange = (e) => {
        const { value } = e.target;
        setInfo({
            ...info,
            [e.target.id]: value,
        });
    };

    const onSubmit = (e) => {
        next(e, {
            ...userProfile,
            data: { 
                ...userProfile?.data, 
                ...info,
                onboardingStageId: 2,
            },
        });
    };

    return (
        <div className="w-full flex flex-col">
            <form onSubmit={onSubmit}>
                <div className="mb-4 w-full">
                    <label htmlFor="dob" className="block mb-2 text-sm font-medium text-white w-full ">
                        When is your birthday?
                        <div className='customDatePickerWidth'>
                        <Datepicker
                            id="dob"
                            selected={info?.dob}
                            onChange={date=> setInfo({...info, dob: date})}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="rounded-md glassCard backdrop-blur-md mt-2  text-white text-sm focus:ring-blue-500 focus:outline-none block !w-full p-2.5 "
                        />
                        </div>
                    </label>
                </div>
                <div className="mb-4 w-full">
                    <label htmlFor="registrationReason" className="block mb-2 text-sm font-medium text-white w-full ">
                        Why did you join Educate?
                        <textarea type="text" id="registrationReason" onChange={handleChange} value={userProfile?.data?.registrationReason} className="rounded-md mt-2 glassCard backdrop-blur-md text-white text-sm focus:ring-blue-500 focus:outline-none block w-full p-2.5" placeholder="..." required />
                    </label>
                </div>
               
                <div className="mb-4 w-full">
                    <div htmlFor="name" className="block mb-2 text-sm font-medium text-white w-full ">
                        Are you currently in a traditional school or university?
                        <div className="pr-2.5 rounded-md glassCard backdrop-blur-md overflow-hidden mt-2">
                            <select value={info?.currentlySchooling} onChange={handleChange} id='currentlySchooling' className="bg-transparent text-white text-sm  focus:outline-none focus:border-blue-500 block w-full !p-2.5">
                                {binaryOptions?.map((option, id) => (
                                    <option value={option?.value} key={id}>{option?.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="mb-4 w-full">
                    <div htmlFor="name" className="block mb-2 text-sm font-medium text-white w-full ">
                        Do you have a full time job?
                        <div className="pr-2.5 rounded-md glassCard backdrop-blur-md overflow-hidden mt-2">
                            <select value={info?.currentlyEmployed} onChange={handleChange} id='currentlyEmployed' className="bg-transparent text-white text-sm  focus:outline-none focus:border-blue-500 block w-full !p-2.5">
                                {binaryOptions?.map((option, id) => (
                                    <option value={option?.value} key={id}>{option?.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                <button
                    type="submit"
                    className="button flex w-full justify-center rounded-md px-3 py-3 2xl:py-4 text-lg md:text-lg font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Next
                </button>
            </form>
        </div>
    );
};

export default OnboardingInfo2;
