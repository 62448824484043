import React, { useEffect, useState } from "react";
import index, { addDoc } from "./searchIndex";

export const courseToIndex = (course, courseId) => course
    ?.sections
    ?.map(section => section
        ?.lessons
        ?.map(({ name, assets, id, meta_data }) => addDoc(
            name,
            assets?.reduce((acc, { description }) => acc.concat(description?.replace(/(<([^>]+)>)/gi, '') || ''),
                ''),
            `/course/${courseId}/phase/${section.id}/lesson/${id}`,
            meta_data = meta_data && JSON.parse(meta_data)?.searchTerms?.join(', ') || '',
        )));

const withSearch = Component => (props) => {
    const [value, setValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [anchor, setAnchor] = useState(null);

    const {data: agencyNav, isLoading: loadingAgencyNav} = useContent({productId: '3351'});

    // console.log(incubator);
    useEffect(() => {
        if (!loadingAgencyNav) courseToIndex(agencyNav, '3351');
    }, [loadingAgencyNav]);

    const onChange = ({ target: { value }, currentTarget }) => {
        setValue(value);
        setSearchResults(index.search(value));
        setAnchor(currentTarget);
    };


    return (
        <Component
            value={value}
            searchResults={searchResults}
            onChange={onChange}
            resultAnchor={anchor}
            {...props}
        />
    );
};

export default withSearch;
