import React, { useContext, useEffect, useMemo, useState } from "react";
import { uniqBy } from "lodash";
import moment from "moment";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { UserDataContext } from "../../../providers/UserDataProvider";
import "./style.scss";
import CustomLoader from "../../../components/CustomLoader";
import { OnboardingContext } from "../../../providers/OnboardingProvider";
import useUpcomingQA from "../../../hooks/useUpcomingQA";
import Modal from "../../../components/modals/Modal";
import UpcomingCoachCard from "../../../components/cards/UpcomingCoachCard.tsx";
import { XIcon } from "@heroicons/react/outline";
import Dropdown from "components/menu/Dropdown.tsx";
import CoachingCard from "components/cards/CoachingCard.tsx";
import useSortCallsByStartTime from "hooks/useSortCallsByStartTime.js";


const All = 'All';

const UpcomingQAPage = () => {
  // onboarding if unopened before initial close
  const { userProfile } = useContext(UserDataContext);
  const sortCallsByStartTime = useSortCallsByStartTime();
  const { activePage, setActivePage, setActiveInfoType } =
    useContext(OnboardingContext);
  useEffect(() => {
    if (
      !userProfile?.data?.tourCompleted?.qa &&
      userProfile?.data?.onboardingCompleted
    ) {
      if (activePage < 1) {
        setActiveInfoType('q&a');
        setActivePage(0);
      }
    }
  }, [userProfile]);


  const { loading, hosts } = useUpcomingQA();

  const sections = useMemo(() => {
    if (!hosts?.length) {
      return [];
    }
    const hostSections = hosts?.map((item) => ({
      label: item?.title,
      value: item?.id,
    }));

    return [{ label: All, value: All }, ...hostSections];
  }, [hosts]);

  const [selectedSection, setSelectedSection] = useState(null);

  useEffect(() => {
    if (!!sections?.length && !selectedSection) {
      setSelectedSection(sections?.[0]?.value);
    }
  }, [sections, selectedSection]);

  const activeSection = useMemo(() => {
    if (!hosts?.length) {
      return [];
    }

    // if active section is All merge all host calls
    if (selectedSection === All) {
      const allCalls = hosts?.reduce((acc, curr) => {
        if (!!curr?.hosts && Array.isArray(curr.hosts)) {
          return acc.concat(curr.hosts);
        }
        return acc;
      }, []);

      return {
        hosts: sortCallsByStartTime(allCalls),
      };
    }

    return hosts?.find((item) => item?.id === selectedSection) || hosts?.[0];
  }, [selectedSection, hosts]);

  const categories = useMemo(() => {
    if (!activeSection?.hosts?.length) {
      return [];
    }
    const allCategories = activeSection?.hosts?.map((item) => ({
      categories: item?.categories?.[0],
      callIcon: item?.callIcon,
      color: item?.color,
    }));

    return uniqBy(allCategories, (item) => item?.categories)
      ?.map((item) => ({
        ...item,
        label: item?.categories,
        value: item?.categories,
      }))
      ?.filter((item) => item?.categories);
  }, [activeSection]);

  const [categoryOptions, setCategoryOptions] = useState([]);

  const handleOptionClick = (option) => {
    const isSelected = categoryOptions.includes(option);
    if (isSelected) {
      setCategoryOptions(
        categoryOptions.filter((selectedOption) => selectedOption !== option)
      );
    } else {
      setCategoryOptions([...categoryOptions, option]);
    }
  };

  const activeCategories = useMemo(
    () => categoryOptions?.map((item) => item?.value),
    [categoryOptions]
  );

  // Extend to start of week of first and end of week of last
  // Fill all days in between these two dates
  const calendarDates = useMemo(() => {
    if (!activeSection?.hosts?.length) {
      return [];
    }
    // Get first and last calendar call
    const firstCallDate = activeSection?.hosts?.[0]?.startTime;
    const lastCallDate =
      activeSection?.hosts?.[activeSection?.hosts?.length - 1]?.startTime;
    const groupStart = firstCallDate.clone().startOf('isoweek');
    const groupEnd = lastCallDate.clone().endOf('week');
    const groupDates = Array.from(
      { length: groupEnd.diff(groupStart, 'days') + 1 },
      (_, i) => {
        const date = groupStart.clone().add(i, 'days');
        return {
          date: date.format('YYYY-MM-DD'),
          dayName: date.format('dddd'),
          dayNumber: date.format('DD'),
          calls: activeSection?.hosts?.filter((item) =>
            item?.startDate?.includes(date.format('L'))
          ),
        };
      }
    );
    return groupDates?.filter(
      ({ dayName }) => !(dayName === 'Sunday' || dayName === 'Saturday')
    );
  }, [activeSection]);

  // group month by weeks
  const weeklyGroups = useMemo(() => {
    return calendarDates.reduce((acc, date, index) => {
      if (index % 5 === 0) {
        acc.push(calendarDates.slice(index, index + 5));
      }
      return acc;
    }, []);
  }, [calendarDates]);

  const [activeWeekIndex, setActiveWeekIndex] = useState(0);

  // current week calls filtered by active categories
  const week = useMemo(() => {
    if (!weeklyGroups?.length) {
      return [];
    }

    if (activeCategories?.length) {
      return weeklyGroups?.[activeWeekIndex]?.map((day) => ({
        ...day,
        calls: day?.calls?.filter((call) =>
          call?.categories?.some((option) => activeCategories?.includes(option))
        ),
      }));
    }

    return weeklyGroups?.[activeWeekIndex];
  }, [weeklyGroups, activeWeekIndex, activeCategories]);

  // Current month based upon active week
  const month = moment(week?.[0]?.date)?.format('MMMM');
  const year = moment(week?.[0]?.date)?.format('YYYY');

  const [openModal, setOpenModal] = useState(null);
  const currentDay = useMemo(() => moment().format('dddd'), []);
  const [activeDay, setActiveDay] = useState(currentDay);
  const dayData = useMemo(
    () => week?.find((day) => day?.dayName === activeDay),
    [week, activeDay]
  );

  const today = useMemo(() => moment().format('YYYY-MM-DD'), []);
  const currentWeekIndex = weeklyGroups.findIndex((week) =>
    week.some((day) => day?.date === today)
  );

  // scroll to current week
  useEffect(() => {
    if (currentWeekIndex >= 0) {
      setActiveWeekIndex(currentWeekIndex);
    }else{
      setActiveWeekIndex(0);
    }
  }, [currentWeekIndex, selectedSection, activeCategories ]);

  return (
    <div className="p-[1rem] md:p-[2rem] w-full h-full">
      <CustomLoader loading={loading}>
        <div className="flex flex-col glassCard p-4 md:p-6 rounded-[20px] md:rounded-[30px] backdrop-blur-md1 overflow-y-auto ">
          {/* header */}
          <header className="flex flex-none flex-col  mb-2 md:mb-4">
            <div className="flex flex-col lg:flex-row lg:items-center justify-between">
              <div className="flex flex-col md:flex-row lg:justify-center mb-2 lg:mb-0">
                <div className="flex flex-row md:justify-center mr-6 mb-2 md:mb-0">
                  <p className="text-xl md:text-4xl font-semibold">{month}</p>
                  <p className="text-xl md:text-4xl font-semibold ml-2 text-[#CBD5E14D] ">
                    {year}
                  </p>
                </div>

                {/* section drop down */}
                <div className="flex items-center glassCard rounded-[10px] mr-4 px-4 self-start md:self-center pb-[0.4rem] pt-[0.2rem] mb-2 md:mb-0 ">
                  <Dropdown
                    title={
                      sections?.find((item) => item?.value === selectedSection)
                        ?.label
                    }
                    options={sections}
                    onSelect={(item) => setSelectedSection(item?.value)}
                    smallDropDownWIdth="120px"
                    dropDownWidth={'200px'}
                  />
                </div>

                {/* category dropdown */}
                {!!categories?.length && (
                  <div className="flex items-center glassCard rounded-[10px] mr-4 px-4 self-start md:self-center pb-[0.4rem] pt-[0.2rem] ">
                    <Dropdown
                      title={'All Categories'}
                      options={categories}
                      onSelect={(item) => handleOptionClick(item)}
                      smallDropDownWIdth="120px"
                      dropDownWidth={'200px'}
                    />
                  </div>
                )}
              </div>

              <div className="flex flex-row items-center">
                <div
                  className="flex flex-row items-center glassCard rounded-[10px] mr-4 px-4 py-2 hover:cursor-pointer"
                  onClick={() =>
                    setActiveWeekIndex((prev) => (prev > 0 ? prev - 1 : prev))
                  }
                >
                  <ChevronLeftIcon className="h-3 w-3" />
                  <p className="ml-1 text-sm text-[#CBD5E1]">Prev Week</p>
                </div>
                <div
                  onClick={() =>
                    setActiveWeekIndex((prev) =>
                      prev < weeklyGroups?.length - 1 ? prev + 1 : prev
                    )
                  }
                  className="flex flex-row items-center glassCard rounded-[10px] mr-4 px-4 py-2 hover:cursor-pointer"
                >
                  <p className="mr-1 text-sm text-[#CBD5E1]">Next Week</p>
                  <ChevronRightIcon className="h-3 w-3" />
                </div>
              </div>
            </div>

            {/* category filters */}
            <div className="flex flex-row items-center mt-4 w-full overflow-auto">
              {categoryOptions?.map((item) => (
                <div
                  onClick={() => handleOptionClick(item)}
                  className={`hover:cursor-pointer text-[#CBD5E1] hover:text-white transition-all flex flex-none flex-row  glassCard !border-[1px] items-center px-4 py-2 grow-0 rounded-[10px] mr-2 relative overflow-hidden`}
                >
                  <div
                    className={`absolute z-[-1] h-full w-full bg-[${item?.color}]/25  rounded-[10px] left-0`}
                  />
                  {!!item?.callIcon && (
                    <img src={item?.callIcon} className="h-3 w-3" />
                  )}
                  <p className="mx-1 text-xs">{item?.label}</p>
                  <XIcon className="h-3 w-3" />
                </div>
              ))}
            </div>
          </header>

          {/* body */}
          <div className="isolate flex flex-auto flex-col h-[70vh] lg:h-[75vh]">
            {/* mobile */}
            <div className="flex flex-col lg:hidden ">
              <div className="flex flex-row justify-around mb-4 md:mb-6 ">
                {week?.map((item, id) => (
                  <div
                    key={id}
                    onClick={() => setActiveDay(item?.dayName)}
                    className="hover:cursor-pointer flex flex-col justify-center items-center"
                  >
                    <p className="text-center text-[#f8f7fa99] text-sm md:text-base">
                      {item?.dayName?.slice(0, 3)}
                    </p>
                    <div
                      className={`${
                        item?.dayName === activeDay && 'bg-[#CBD5E14D]'
                      } ${
                        item?.date === today && 'border-2 border-[#0384FE]'
                      } w-7 h-7 rounded-full flex justify-center items-center`}
                    >
                      <p
                        className={`text-xs md:text-sm ${
                          item?.calls?.length
                            ? 'text-white'
                            : 'text-[#CBD5E1]/50'
                        }                         
                        `}
                      >
                        {item?.dayNumber}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex flex-col w-[80vw] md:w-[50vw] h-full mx-auto ">
                {dayData?.calls?.map((call, id) => (
                  <CoachingCard
                    key={id}
                    title={call?.title}
                    image={call?.img}
                    startTime={call?.startTime}
                    endTime={call?.endTime}
                    onClick={() => setOpenModal({ calls: [call] })}
                    timezone={call?.timezone}
                  />
                ))}
              </div>
            </div>

            {/* large screen */}
            <div className="lg:flex flex-none flex-row justify-between sm:max-w-none w-full h-full hidden">
              {week
                ?.concat(Array(5 - week?.length).fill([]))
                ?.map((item, id) => (
                  <div
                    className="flex flex-1 w-full flex-col h-full mx-1"
                    key={id}
                  >
                    <div className="w-full mb-2 px-2">
                      <p className="text-right text-[#f8f7fa99] text-xl">
                        {item?.dayName}
                      </p>
                    </div>

                    <div
                      className={`w-full h-full ${
                        item?.date === today
                          ? 'glassCard  !border-[#0384FE] '
                          : 'glassCard'
                      } rounded-[20px] p-2 backdrop-blur-md relative overflow-auto`}
                    >
                      <div className="w-full flex justify-end mb-1">
                        <p
                          className={`text-lg mr-2 font-normal ${
                            item?.calls?.length
                              ? 'text-white'
                              : 'text-[#CBD5E1]/50'
                          } `}
                        >
                          {item?.dayNumber}
                        </p>
                      </div>
                      {item?.calls?.map((call, id) => {
                        const many = item?.calls?.length > 5;
                        return (
                          <CoachingCard
                            key={id}
                            title={call?.title}
                            image={call?.img}
                            startTime={call?.startTime}
                            endTime={call?.endTime}
                            onClick={() => setOpenModal({ calls: [call] })}
                            timezone={call?.timezone}
                          />
                        );
                      })}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <Modal
          open={!!openModal?.calls?.length}
          setOpen={setOpenModal}
          opacity={70}
        >
          <div className="py-3 px-3 flex justify-center items-center">
            <div className="p-4 lg:p-6 overflow-y-auto rounded-[20px] md:rounded-[30px] glassCard backdrop-blur-md relative">
              {openModal?.calls?.map((call, id) => (
                <UpcomingCoachCard
                  key={id}
                  image={call?.img}
                  title={call?.title}
                  day={moment(call?.startTime).format('dddd')}
                  startTime={moment(call?.startTime).format('LT')}
                  endTime={moment(call?.endTime).format('LT')}
                  coach={call?.name}
                  position={call?.position}
                  icon={call?.callIcon}
                  bio={call?.description}
                  link={call?.link}
                  color={call?.color}
                  timezone={call?.timezone}
                />
              ))}
            </div>
            <div
              onClick={() => setOpenModal(false)}
              className="h-[40px] w-[40px] rounded-[12px] bg-[#17191C] z-99 flex justify-center items-center absolute top-0 left-0 border-[2px] border-[#FFFFFF08] hover:cursor-pointer glassCard backdrop-blur-md"
            >
              <XIcon
                className="h-6 w-6 text-[#CBD5E1] hover:text-white transition-all"
                aria-hidden="true"
              />
            </div>
          </div>
        </Modal>
      </CustomLoader>
    </div>
  );
};

export default UpcomingQAPage;
