import React from "react";

interface SettingsCardProps {
    label: string;
    icon: string;
    secondaryCtaText?: string;
    secondaryCtaOnClick?: () => void;
    children: React.ReactNode;
}

const SettingsCard = ({
    label,
    icon,
    secondaryCtaText,
    secondaryCtaOnClick,
    children
}: SettingsCardProps) => (
    <div className="block items-start mb-2 text-sm font-medium text-white w-full">
        <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center">
                <img src={icon} className="h-5 w-5 object-contain mr-2" />
                <p className="text-left text-[#CBD5E1] text-lg">{label}</p>
            </div>
            {secondaryCtaText && secondaryCtaOnClick && (
                <div onClick={secondaryCtaOnClick}>
                    {secondaryCtaText}
                </div>    
            )}
        </div>
        <div className="mb-4 mt-2">
            {children}
        </div>
  </div>
)

export default SettingsCard;