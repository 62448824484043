import { Dispatch, FC, Fragment, SetStateAction } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, SearchIcon, XIcon } from '@heroicons/react/outline';

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
}

type DropdownType = {
  title: string,
   options: {
      label: string,
   }[],
   onSelect: (item: any) => void,
   dropDownWidth?: string,
   smallDropDownWIdth?: string,
   align?: 'left' | 'right',
   videoDropDown?: boolean
   width?: string,
   backgroundColor?: string,
   search?: string,
   setSearch?: Dispatch<SetStateAction<string>>
   showClearButton?: boolean;
   onClickClear?: () => void;
}

const Dropdown: FC<DropdownType> = ({ title, options, onSelect, videoDropDown, width,backgroundColor, search, setSearch, dropDownWidth = '224px', smallDropDownWIdth = '100px', align = 'right', showClearButton, onClickClear }) => {
  return (
    <Menu as="div" className={`relative text-left inline-block ${width ? `w-[${width}]` : ''}`}>
      <div>
        <Menu.Button className="inline-flex w-full justify-start items-center rounded-md  text-sm font-semibold text-[#CBD5E1] shadow-sm outline-none">
          <p className={`${videoDropDown ? 'text-sm lg:text-base font-semibold leading-6 text-white text-left' : ''} text-sm leading-[100%] font-normal`}>{title}</p>
          {
            showClearButton
            ?
              (
                <XIcon
                  className="ml-1 h-5 w-5 text-gray-400 cursor-pointer"
                  aria-hidden="true"
                  onClick={onClickClear}
                />
              )
              : (
                <ChevronDownIcon
                  className="ml-1 h-3 w-3 text-gray-400"
                  aria-hidden="true"
                />
              )
          }
        </Menu.Button>
      </div>

        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
        <Menu.Items className={`glassCard max-h-[70vh] overflow-y-auto ${backgroundColor ? `!bg-[${backgroundColor}]` : ''} backdrop-blur-md absolute ${align}-0 z-10 mt-4 -ml-4 w-[${smallDropDownWIdth}] md:w-[${dropDownWidth}] origin-top-${align} rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                <div className="">
               {!!setSearch &&
                    <div className="my-3 mx-2 flex flex-row items-center rounded-[10px] pl-4 glassCard !bg-[#16181C] backdrop-blur-md !border-[1px]">
                        <input
                          placeholder="Type here"
                          type="search"
                          value={search}
                          onChange={(e) => {
                            e.stopPropagation();
                            setSearch(e?.target?.value ?? '')
                          }}
                          className="placeholder-[#42464E] outline-none rounded-full text-white text-sm focus:ring-blue-500 focus:outline-none block w-full py-3 pr-1.5 bg-transparent"
                        />
                        <div className="flex items-center">
                          <SearchIcon
                            className="h-4 w-4 mr-2 inline grey500"
                            aria-hidden="true"
                          />
                        </div>
                     </div>
                }
                    {options?.map((item: any, id: number) => (
                        <Menu.Item key={id}>
                            {({ active }) => (
                                <div
                                    className={classNames(
                                        active ? 'bg-gray-800 text-white' : 'text-[#CBD5E1]',
                                        'block px-4 py-2 text-xs lg:text-sm hover:cursor-pointer transition-all',
                                        item?.disabled && 'opacity-50'
                                    )}
                                    onClick={() => !item?.disabled && onSelect(item)}
                                >
                                    {item?.label}
                                </div>
                            )}
                        </Menu.Item>
                    ))}
                </div>
            </Menu.Items>
        </Transition>
    </Menu>
  );
};

export default Dropdown;
