import React, { useContext, useEffect, useMemo, useState } from 'react';
import Modal from 'react-responsive-modal';
import OnboardingProgress from '../../../components/onboarding/OnboardingProgress';
import OnboardingUserInfo from './OnboardingUserInfo';
import OnboardingInfo2 from './OnboardingInfo2';
import OnboardingInfo3 from './OnboardingInfo3';
import OnboardingInfo4 from './OnboardingInfo4';
import OnboardingInfo5 from './OnboardingInfo5';
import OnboardingCalendar from './OnboardingCalendar';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import logo from '../../../assets/img/logo.svg';
import { UserDataContext } from 'providers/UserDataProvider';
import Axios from 'axios';
import getTrackedVendorUser from 'api/getTrackedVendorUser';
import { useQuery } from '@apollo/react-hooks';
import { useConfig } from "@genflow/core";
import useUserAccess from "../../../hooks/useUserAccess";


export const onboardingPages = ({onlyDigitalLaunchPad = false}, id) => {
   const pagesLength = onlyDigitalLaunchPad ? 5 : 6
   const completedArr = Array.from({ length: pagesLength }, (_, i) => (i / (pagesLength - 1)) * 100)

    return ([
    {
        id: 0,
        component: OnboardingUserInfo,
        completed: completedArr?.[0] || 0,
    },
    {
        id: 1,
        component: OnboardingInfo2,
        completed: completedArr?.[1] || 20,
    },
    {
        id: 2,
        component: OnboardingInfo3,
        completed: completedArr?.[2] || 40,
    },
    {
        id: 3,
        component: OnboardingInfo4,
        completed: completedArr?.[3] || 60,
    },
    {
        id: 4,
        component: OnboardingInfo5,
        completed: completedArr?.[4] || 80,
    },
    !onlyDigitalLaunchPad && {
        id: 5,
        component: OnboardingCalendar,
        completed: completedArr?.[5] || 100,
        props: {
            url: 'https://calendly.com/educate-group-onboarding/welcome',
            stageId: null,
            className: 'h-[1050px] 2xl:h-[1200px]'
        }
    },
    // !onlyDigitalLaunchPad && {
    //     id: 6,
    //     component: OnboardingCalendar,
    //     completed: 100,
    //     props: {
    //         url: 'https://calendly.com/educate-student-success/the-rescue-vip-x2?hide_gdpr_banner=1',
    //         stageId: null,
    //         className: 'h-[1000px] xl:h-[1150px] 2xl:h-[1060px]',
    //     }
    // },
].filter(Boolean));
};

export default function OnboardingQuestions({ open }) {
    const [activePage, setActivePage] = useState(0);
    let { userProfile, setUserProfile } = useContext(UserDataContext);
    const { userAccess } = useUserAccess();
    const hasDigitalLaunchPad = !!userAccess?.find(({contentId}) => contentId === '6196');
    const hasAgencyNavigator = !!userAccess?.find(({contentId}) => contentId === '6018');
    const onlyDigitalLaunchPad = !hasAgencyNavigator && hasDigitalLaunchPad;
    const OnboardingPages = onboardingPages({onlyDigitalLaunchPad});

    const isValidActivePage = activePage < OnboardingPages.length && activePage >= 0;
    const Page = useMemo(() => isValidActivePage && OnboardingPages?.[activePage].component, [activePage]);
    const completed = useMemo(() => isValidActivePage && OnboardingPages?.[activePage].completed, [activePage]);
    const props = useMemo(() => isValidActivePage && OnboardingPages?.[activePage].props || {}, [activePage]);

    const { vendorUuid } = useConfig();
    const { data: trackedVendorUser } = useQuery(getTrackedVendorUser, {
        variables: { vendorUuid},
        skip: !vendorUuid
    });
    const userId = trackedVendorUser?.getTrackedVendorUser?.data?.userId

    const next = (e, data, id) => {
        e.preventDefault();

        if (data) setUserProfile(data, true);
    };

    const completeOnboarding = async () => {
        const newData = {
            ...userProfile.data,
            onboardingCompleted: true,
        }

        delete newData.onboardingStageId;
        if (!window?.location?.hostname?.includes('localhost')) {
            await Axios.post('/.netlify/functions/onboarded',  {
                ...newData,
                userId
            });
        }
        setUserProfile({
            ...userProfile,
            data: newData
        }, true);
    }

    useEffect(() => {
        const onboardingStageId = userProfile?.data?.onboardingStageId;

        if (onboardingStageId) {
            const ssc = userProfile?.data?.ssc;
            if (
                (onlyDigitalLaunchPad && onboardingStageId >= OnboardingPages?.length)
                || (!onlyDigitalLaunchPad && onboardingStageId === 6 && ssc === "yes" )) { // complete onboarding after welcome call if ssc exists
                completeOnboarding();
            } else {
                setActivePage(onboardingStageId);
            }
        } else if (onboardingStageId === null) {
            completeOnboarding();
        }
    }, [userProfile, OnboardingPages, onlyDigitalLaunchPad]);

    const prev = () => {
        setActivePage(activePage - 1)
    }


    return (
        <Modal
            open={open}
            center
            className="grey800Bg"
        >
            <div
                className="fixed top-0 left-0 inset-0 bg-[#0C1018FA] w-full z-50 flex justify-center"
                id="my-modal"
            >
                <div className={`w-11/12 md:6/12 lg:w-4/12 glassCard self-center backdrop-blur-md my-4 pb-4 rounded-[20px] overflow-auto max-h-[95vh] relative `}>
                    <div
                        className="flex flex-col items-center self-center"
                    >
                        <div className={`px-12 flex flex-col items-center justify-center`}>
                        {
                            activePage > 0 &&
                            <div className="absolute left-2 top-8 hover:cursor-pointer" onClick={prev}>
                            <ArrowLeftIcon className="h-5 w-5 md:h-8 md:w-8 shrink-0 text-[#30333a]" aria-hidden="true" />
                            </div>
                        }
                        <img
                            alt="genflow"
                            src={logo}
                            className='py-8'
                        />
                        <p className="text-white text-base text-center mb-4">
                        {'First, let’s start off with a few simple questions so we can get to know a little more about you.'}
                       </p>
                        <OnboardingProgress completed={completed} />
                        </div>
                        <div className={`w-full mt-[20px] ${activePage > 4 ? 'px-2' : 'px-12'}`}>
                            {activePage < OnboardingPages.length && activePage >= 0 && (
                                <Page
                                    key={OnboardingPages?.[activePage]?.id}
                                    next={next}
                                    {...props}
                                />
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </Modal>
    );
}
