import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useConfig } from "@genflow/core";
import useGetCourses from "@genflow/web-courses/hooks/useGetCourses";

import { isArray, orderBy } from "lodash";

import moment from "moment";
import "../CoursesStyles.css";
import CourseProduct from "../../../components/CourseProduct";
import Phases from "./Phases";
import CustomLoader from "../../../components/CustomLoader";
import GoalLockedModal from "components/modals/GoalLockedModal";
import useGoalLocked from "hooks/useGoalLocked";
import useUserAccess from "../../../hooks/useUserAccess";

export default () => {
  const { vendorUuid, agencyNavigatorResourceLibraryProductID, digitalLaunchpadResourceLibraryProductID} = useConfig();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  const courses = useGetCourses(vendorUuid);

  const { userAccess, isLoading } = useUserAccess();
  const accessibleContentIds = userAccess?.map(({contentId}) => contentId)?.filter(Boolean);

  const {lockedInfo} = useGoalLocked(courses?.data?.map((course)=> course?.id))

  // create course order based on user entitlements, admin course order & goal locked courses logic
  const orderedCourses = useMemo(
    () =>
      orderBy(courses?.data?.filter(({ id }) => id < 7228 && [agencyNavigatorResourceLibraryProductID.toString(), digitalLaunchpadResourceLibraryProductID.toString()].indexOf(id) == -1)?.map((course) => {
        const goalLockedCourse = isArray(lockedInfo) && lockedInfo?.find((item)=> course?.id === item?.id)
        const locked = goalLockedCourse?.locked && !accessibleContentIds?.includes(goalLockedCourse?.id?.toString())
        const order = course?.meta_data && JSON.parse(course?.meta_data)?.listingOrder || 0

        return ({
          ...course,
          accessOrder: (accessibleContentIds?.includes(course?.id?.toString()) && !locked) ? order : Infinity
        })}
      ), 'accessOrder', 'asc'),
    [courses]
  );

  const [open, setOpen] = useState(false);
  const now = useMemo(() => moment().add(0, 'days'), []);

  const [openGoalLockedModal, setOpenGoalLockedModal] = useState(null);
  return (
    <>
      <CustomLoader loading={isLoading}>
        <div className="md:min-h-screen flex p-4 lg:p-8">
          <div className="glassCard w-full rounded-[20px] md:rounded-[30px]">
            <div className="p-4 lg:p-8 gap-6 grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
              {orderedCourses
                // ?.filter((item) => !JSON.parse(item.meta_data)?.hidden)
                .map((item, i) => (
                    <CourseProduct
                      key={i}
                      name={item.name}
                      imageSrc={item.background_image}
                      // brandSrc={JSON.parse(item.meta_data)?.productLogo}
                      brandSrc={null}
                      description={item.description}
                      productID={item.id}
                      invite={JSON.parse(item.meta_data)?.blocked}
                      extraImgStyling={{ width: '315px' }}
                      item={item}
                      openCourse={() => setOpen(item.id)}
                      comingSoon={JSON.parse(item.meta_data).releaseDate && now.isBefore(moment(JSON.parse(item.meta_data).releaseDate))}
                      openGoalLockedModal={(item)=> setOpenGoalLockedModal(item)}
                    />
                ))}
            </div>
          </div>
          <Phases open={open} setOpen={setOpen} />
          <GoalLockedModal openGoalLockedModal={openGoalLockedModal} close={()=> setOpenGoalLockedModal(null)}   />
        </div>
      </CustomLoader>
    </>
  );
};
