import React, { Dispatch, FC, Fragment, SetStateAction } from 'react';
import { Dialog, Transition } from '@headlessui/react';

type Modal = {
    open: boolean,
    setOpen?: any,
    children: any,
    opacity?: number,
    backgroundColor?: string
}

const Modal:FC<Modal> = ({
    open, setOpen, children, opacity = 70, backgroundColor
}) => (
    <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-9999" onClose={setOpen}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div
                    className={`fixed inset-0 ${backgroundColor ? `bg-[${backgroundColor}]` : `bg-[rgba(0,0,0,${opacity / 100})]` } transition-opacity backdrop-blur-sm`}
                />
            </Transition.Child>

            <div className="fixed inset-0 z-50 overflow-y-auto">
                <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg shadow-xl transition-all z-99999 ">
                            {children}
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition.Root>
);

export default Modal;
