import CountdownTimer from "./components/CountdownTimer"

export default () => {
    return (
        <div className="flex flex-col gap-4 h-screen items-center justify-center text-white">
            <CountdownTimer initialTime={10} redirectUrl="https://app.digital-launchpad.com" />
            <div className="flex-col flex items-center">
                <p>Digital Launchpad has moved to a new platform.</p>
            </div>
            <a
                className="flex items-center justify-center bg-white text-black px-3 py-1 font-semibold rounded-full"
                href="https://app.digital-launchpad.com"
                rel="noreferrer"
            >
                Go to new platform
            </a>
        </div>
    )
}