import React from 'react';
import ReactCodeInput from 'react-code-input';
import './style.scss';

const CodeInput = props => (
    <div style={{
        paddingTop: '1.5rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }}
    >
        <div className="text-center mb-2 text-muted block text-sm 2xl:text-base font-medium leading-6 text-[#CBD5E1]">
            <p>Please enter the verification code sent to your email address (check spam):</p>
        </div>
        <ReactCodeInput
            type="number"
            fields={6}
            {...props}
        />
    </div>
);

CodeInput.propTypes = {

};

export default CodeInput;
