import useCountries from "hooks/useCountries";
import { UserProfile } from "../@types/user-discovery";

export default (userProfile: UserProfile) => {
    const locationArr = userProfile?.location?.split(',')?.map((item:string)=> item?.trim())
    const countryName = locationArr?.[1] ?? locationArr?.[0]
    const {flag} = useCountries(countryName);
  
    return {
     flag
    };
}
  