import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import { useConfig } from "@genflow/core";
import useGetCourses from "@genflow/web-courses/hooks/useGetCourses";
import { lowerCase } from "lodash";
import { UserDataContext } from "providers/UserDataProvider";
import CustomLoader from "components/CustomLoader";
import { nonDownloadable } from "constants/content";
import moment from "moment";
import { SearchIcon } from "@heroicons/react/outline";
import useDownloader from "hooks/useDownloader";
import ResourceCard from "components/cards/ResourceCard";
import useUserAccess from "../../../../hooks/useUserAccess";

const downloadableAsset = (asset) => !nonDownloadable?.includes(asset?.type)

const Resources = () => {
  const {
    vendorUuid,
    agencyNavigatorResourceLibraryProductID,
    digitalLaunchpadResourceLibraryProductID,
  } = useConfig();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  const { userProfile } = useContext(UserDataContext)
  const courses = useGetCourses(vendorUuid);
  const { download } = useDownloader();

  const { userAccess, isLoading } = useUserAccess();
  const accessibleContentIds = userAccess?.map(({contentId}) => contentId)?.filter(Boolean)

  const now = useMemo(()=> moment(),[]);
  const checkRelease = (metaData) => {
    const releaseDate = metaData && JSON.parse(metaData)?.releaseDate;
    return  !!releaseDate ? now.isBefore(moment(releaseDate)) : false
  }

  // filter courses with user entitlements
  const filteredCourses = useMemo(()=>{
    if(!courses?.data) return [];
    const coursesWithEntitlements = courses?.data?.filter(({ id }) => accessibleContentIds?.includes(id) && [agencyNavigatorResourceLibraryProductID.toString(), digitalLaunchpadResourceLibraryProductID.toString()].indexOf(id) == -1)

    // only return courses with released phases having at least one downloadable asset
    return coursesWithEntitlements?.filter((course)=> course?.sections?.some(
      (section)=> !checkRelease(section?.meta_data) &&
      section?.lessons?.some((lesson)=> lesson?.assets?.some(
      (asset)=> !nonDownloadable?.includes(asset?.type)))));
  },[courses])

  // creates searchable downloadable assets
  const assets = useMemo(()=> {
    if(!filteredCourses?.length)return [];

    return filteredCourses?.reduce((a, course)=> {
      course?.sections?.forEach(
        (section) => section?.lessons?.forEach(
          (lesson)=> {
            if(lesson?.assets){
              lesson?.assets?.forEach((asset)=> {
                if(!!downloadableAsset(asset)){
                  a.push({
                    name: asset?.title,
                    program: course?.name,
                    phase: section?.name,
                    module: lesson?.name,
                    downloads: asset,
                  })
                }
              })
            }
          }))
          return a;
    },[])
  },[filteredCourses])


  const [search, setSearch] = useState("")
  const activeSearch = search?.length > 2

  const searchResult = useMemo(()=> {
    if(!activeSearch)return null
    const text = lowerCase(search)

    // search assets based on the following criteria
    return assets?.filter((asset)=> lowerCase(asset?.name)?.includes(text) ||  lowerCase(asset?.program)?.includes(text) ||  lowerCase(asset?.phase)?.includes(text) ||  lowerCase(asset?.module)?.includes(text))
  },[search, assets])


  const trackDownloads = (item) => ReactGA.event('Files_Downloaded', { module_name: item?.module, phase_name: item?.phase, program_name: item?.program, });



  return (
    <>
      <CustomLoader loading={courses?.loading}>
        <div className="min-h-[90vh] md:min-h-screen flex p-4 lg:p-8">
          <div className="glassCard w-full rounded-[20px] md:rounded-[30px] px-4 md:px-10 pb-3.5 pt-6">
            <div className="flex flex-row items-center rounded-2xl pl-4 mb-5 glassCard backdrop-blur-md">
                <div className="flex items-center">
                    <SearchIcon className="h-4 w-4 mr-2 inline grey500" aria-hidden="true" />
                </div>
                <input
                    placeholder="Search by program, module or document name"
                    type="search"
                    value={search}
                    onChange={(e)=> setSearch(e?.target?.value)}
                    className="placeholder-[#42464E] outline-none rounded-full text-white text-sm focus:ring-blue-500 focus:outline-none block w-full py-3 pr-1.5 bg-transparent"
                />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4'>
              {
                !activeSearch && filteredCourses?.map((item)=> {
                  const metaData = item.meta_data && JSON.parse(item.meta_data)
                  return(
                  <Link
                    key={item?.id}
                    to={{
                      pathname: `/resources/phases/${item?.id}`,
                    }}
                    className='w-full  aspect-[2.58] rounded-[16px] md:rounded-[20px] flex justify-center items-center glassCard !border-[1px] hover:cursor-pointer overflow-hidden'
                    >
                    {
                      metaData?.bannerImage ?
                      <img src={metaData?.bannerImage}
                      className='w-full h-full object-cover'
                      />
                      :
                      // <img src={metaData?.productLogo} className='h-[30%] w-auto'/>
                      <></>
                    }
                  </Link>
                )})
              }
            </div>

            {
                !!activeSearch && !!searchResult?.length && (
                  <div className="w-full grid grid-cols-2 md:grid-cols-3 gap-5">
                  {searchResult?.map((item, index) => (
                    <ResourceCard
                        onClick={() =>  {
                          trackDownloads(item)
                          download(item?.downloads)
                        }}
                        title={item?.name}
                        subtitle={item?.program}
                    />
                  ))}
                </div>
                )
              }

              {
                !!activeSearch && !searchResult?.length && (
                  <div className='w-full flex flex-col items-center'>
                    <p className='font-bold text-xl text-[#CBD5E1]'>Your search did not match any documents</p>
                    <p className='text-[#CBD5E1]/30 font-bold text-lg'>Make sure spelling is correct</p>
                  </div>
                )
              }

          </div>
        </div>
      </CustomLoader>
    </>
  );
};

export default Resources;
