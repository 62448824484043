import gql from 'graphql-tag';

export default gql`
    mutation($vendorUuid: ID!, $metadata: UserHabitGroupInput!){
        createUpdateHabitGroup(vendorUuid: $vendorUuid, metadata: $metadata){
            error
            data {
                name
                habitGroupUuid
                duration
                durationUnits
                status
                habits {
                    habitUuid
                    habitName
                    createdTimestamp
                    updatedTimestamp
                    frequency
                    perDay
                    streak
                    colour
                    status
                    reminderTimes
                    imageUrl
                }
            }
        }
    }
`;
