
import { saveAs } from 'file-saver';
import { isArray } from 'lodash';

type Item = {
    url: string,
    title: string,
    type: 'Image' | 'File'
}

const useDownloader = () => {
    const getFile=(item: Item)=>{
        const splitUrl = item?.url?.split('.')
        const fileType = splitUrl?.[splitUrl?.length - 1]; 

        if(item?.type !== 'Image'){
          window.open(item?.url, '_blank', 'noreferrer'); // open url in a new tab for non image files eg pdf
        }else{
          fetch(item?.url)
              .then(response => response.blob())
              .then(blob => {
                saveAs(blob, `${item?.title}.${fileType}`);
              })
              .catch(error => {
                console.error('Error fetching or saving the item:', error);
              })
        }
    }

    function download(items: Item[]) {
        // Create an array to store promises for each item
        const itemArr = isArray(items) ? items : [items]; // handles multi or single item downloads
        const promises: Promise<void>[] = [];

        itemArr?.forEach(item => {
          promises.push(
            Promise.resolve(getFile(item))
          );
        });

        // Wait for all promises to resolve
        Promise.all(promises).then(() => {
          console.log('All downloads saved successfully');
        });
      }

      return {
        download
      }
}

export default useDownloader;