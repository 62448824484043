import React, { CSSProperties, useContext, useMemo, useState } from 'react'
import * as moment from 'moment-timezone';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Select from 'react-select';
import { TimezoneContext } from 'providers/TimezoneProvider';
import { ChevronDownIcon } from '@heroicons/react/solid';


const TimeZoneCard = () => {
    const { timezone, setTimezone } = useContext(TimezoneContext);

    const timezones = moment.tz.names()
    const timezonesData = useMemo(()=> {
        if(!timezones?.length) return [];

        return timezones?.map((item: string)=> ({
            label: item,
            value: item
        }))
    },[timezones])

  return (
    <div className='flex flex-col flex-1 w-full gap-y-1'>
        <Select
            menuPortalTarget={document.body} 
            menuPosition={'fixed'}
            value={timezonesData?.find((item: any)=> item?.value === timezone)}
            onChange={(timezones: any) => {
                setTimezone(timezones?.value);
            }}
            className="rounded-md glassCard !border-[1px] backdrop-blur-md mt-2  text-white text-sm focus:ring-blue-500 focus:outline-none block flex-1 p-2.5 py-1.5"
            options={timezonesData}
            styles={{
                control: (provided: CSSProperties, state: {isFocused: boolean}) => ({
                    ...provided,
                    backgroundColor: 'transparent',
                    border: state.isFocused ? 0 : 0,
                    boxShadow: "none",
                    color: 'white',
                    fontSize: 14,
                    minHeight: '30px',
                    height: '30px',
                }),
                input: (baseStyles: CSSProperties) => ({
                    ...baseStyles,
                    color: 'white'
                }),
                menuPortal: (base: CSSProperties) => ({ ...base, zIndex: 9999, color:'white'  }),
                menu: (provided: any) => ({
                    ...provided,
                    backgroundColor: '#0C1018',
                    border: "none"
                }),
                option: (styles: CSSProperties, { isDisabled, isFocused, isSelected } : {isDisabled: boolean,isFocused: boolean, isSelected: boolean }) => {
                    return {
                    ...styles,
                    backgroundColor: isDisabled
                        ? undefined
                        : isSelected
                        ? '#283C63'
                        : isFocused
                        ? '#3C4963'
                        : undefined,
                    border:  'none',
                    fontSize: 14,
                    };
                },
                valueContainer: (provided: CSSProperties) => ({
                    ...provided,
                    height: '30px',
                    }),
                    indicatorsContainer: (provided: CSSProperties) => ({
                    ...provided,
                    height: '30px',
                    }),
            }}
        />
    </div>
  )
}

export default TimeZoneCard;
