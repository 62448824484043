import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import ReactGA from "react-ga4";
import { useIsLoggedIn } from "@genflow/web-auth";
import "./init";
import "./assets/css/tools.css";
import enableLogs from "utils/enable-logs";
import { IS_DEV } from "env";
import UpcomingQAPage from "./views/pages/UpcomingQA/index";
import ResourcesQARecordings from "./views/pages/resources/QARecordings";
import Profile from "./views/pages/Profile";
import PiracyPolicy from "./views/pages/PiracyPolicy";
import Providers from "./providers";
import Layout from "./components/layout";
import Phases from "./views/pages/courses/Phases";
import Courses from "./views/pages/courses/Courses";
import Register from "./views/pages/auth/Register";
import Login from "./views/pages/auth/Login";
import CourseOverview from "./views/pages/CourseOverview";
import CourseView from "./views/pages/CourseView";
import Admin from "./views/pages/Admin";
import config from "./config";
import Dashboard from "./views/pages/Dashboard/Dashboard";
import Sidebar from "./components/layout/Sidebar";
import MonkModeStart from "views/pages/monk-mode/start";
import MonkMode from "views/pages/monk-mode";
import ComingSoon from "views/pages/ComingSoon";
import Resources from "views/pages/resources/Resources/Resources";
import ResourcesPhases from "views/pages/resources/Resources/ResourcesPhases";
import ResourcesModules from "views/pages/resources/Resources/ResourcesModules";
import Beacon from "components/Beacon";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import FindAFriendStart from "views/pages/FindAFriend/start";
import FindAFriend from "views/pages/FindAFriend";
import Settings from "views/pages/Settings";
import useUserAccess from "./hooks/useUserAccess";
import DlRedirect from "views/pages/DlRedirect";

Bugsnag.start({
    apiKey: '4209ea2b653470f9c870f6c17882ac6e',
    plugins: [new BugsnagPluginReact()],
});

const { TRACKING_ID } = config;
ReactGA.initialize(TRACKING_ID);

const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React);


const PrivateRoute = ({ children, ...rest }) => {
    const { userAccess, isLoading } = useUserAccess();
    const hasAccess = !!userAccess?.find(({key}) => key === 'analytics.read');
    const isLoggedIn = useIsLoggedIn();

    const hasDigitalLaunchPad = !!userAccess?.find(({ contentId }) => contentId === "6196");
    const hasAgencyNavigator = !!userAccess?.find(({ contentId }) => contentId === "6018");
    const onlyDigitalLaunchPad = !hasAgencyNavigator && hasDigitalLaunchPad;

    const isOnPage = path => path.indexOf(rest?.path) > -1;

    if (
        isLoggedIn === null
        || (!hasAccess && !isLoading && !isOnPage('/privacy-policy'))
    ) {
        return null;
    }

    if (!!isLoggedIn && onlyDigitalLaunchPad) return (
        <Redirect
            to={{
                pathname: '/we-have-moved',
            }}
        />
    );

    return (
        <Route
            {...rest}
            render={({ location }) => (
                isLoggedIn ? (
                children
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: location },
                    }}
                />
            ))
            }
        />
    );
};

// Disable logs in production
if (!IS_DEV) {
    enableLogs(false);
}

ReactDOM.render(
    <ErrorBoundary>
        <Providers>
            <BrowserRouter>
                <QueryParamProvider adapter={ReactRouter5Adapter}>
                <Layout>
                    <Switch>
                        <Route path="/login" exact render={props => <Login {...props} />} />
                        <Route path="/upgrade" exact render={props => <Register {...props} />} />
                        <Route path="/we-have-moved" exact render={props => <DlRedirect {...props} />} />
                        {/* <Route path="/register" exact render={props => <Register {...props} />} /> */}
                        <Sidebar>
                            <PrivateRoute path="/dashboard" exact>
                                <Dashboard />
                            </PrivateRoute>
                            <PrivateRoute path="/programs" exact>
                                <Courses />
                            </PrivateRoute>
                            <PrivateRoute path="/programs/:remountKey" exact>
                                <Courses />
                            </PrivateRoute>

                            <PrivateRoute path="/piracy-policy" exact>
                                <PiracyPolicy />
                            </PrivateRoute>

                            <PrivateRoute path="/resources/qa" exact>
                                <ResourcesQARecordings />
                            </PrivateRoute>

                            <PrivateRoute path="/profile" exact>
                                <Profile />
                            </PrivateRoute>

                            <PrivateRoute path="/admin" exact>
                                <Admin />
                            </PrivateRoute>

                            <PrivateRoute path="/upcoming-q-a" exact>
                                <UpcomingQAPage />
                            </PrivateRoute>

                            <Route
                                path="/course/:productID?/phase/:phaseId?/:lesson?/:lessonID?"
                                exact
                                render={props => <CourseView {...props} />}
                            />
                            <Route
                                path="/course/:id?"
                                exact
                                render={props => <Phases {...props} />}
                            />
                            <Route path="/course-overview" exact render={props => <CourseOverview {...props} />} />

                            {/*<PrivateRoute path="/monk-mode" exact>*/}
                            {/*    <ComingSoon />*/}
                            {/*</PrivateRoute>*/}

                            <PrivateRoute path="/community" exact>
                                <ComingSoon />
                            </PrivateRoute>

                             <PrivateRoute path="/monk-mode/start" exact>
                                <MonkModeStart />
                            </PrivateRoute>

                            <PrivateRoute path="/monk-mode" exact>
                                <MonkMode />
                            </PrivateRoute>

                            <PrivateRoute path="/resources" exact>
                                <Resources />
                            </PrivateRoute>
                            <PrivateRoute path="/resources/phases/:courseId?" exact>
                                <ResourcesPhases />
                            </PrivateRoute>
                            <PrivateRoute path="/resources/phases/:courseId?/modules/:phaseId?" exact>
                                <ResourcesModules />
                            </PrivateRoute>
                            <Redirect to="/dashboard" />
                            <PrivateRoute path="/find-a-friend/start" exact>
                                <FindAFriendStart/>
                            </PrivateRoute>
                            <PrivateRoute path="/find-a-friend" exact>
                                <FindAFriend />
                            </PrivateRoute>
                            <PrivateRoute path="/settings" exact>
                                <Settings />
                            </PrivateRoute>
                            <Beacon />
                        </Sidebar>
                    </Switch>
                </Layout>
                </QueryParamProvider>
            </BrowserRouter>
        </Providers>
    </ErrorBoundary>,
    document.getElementById('root'),
);
