import moment from 'moment';
import { useEffect, useState } from 'react';

const leadingZero = (value: number) => {
    if (value < 0) return '00';
    return (value < 10 ? `0${value}` : value);
};

export default (date: string, start = false) => {
    const [startCountdown, setStartCountdown] = useState(false);
    const [countDownTime, setCountDownTime] = useState({
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
        elapsed: true
    });

    useEffect(() => {
        if (moment().isBefore(moment(date)) && !startCountdown) {
            setStartCountdown(true);
        }
    }, [date]);

    useEffect(() => {
        if (startCountdown || start) {
            const interval = setInterval(() => {
                const totalTime = Date.parse(date) - Date.parse(new Date().toString());
                if (totalTime > 0) {
                    setCountDownTime({
                        days: leadingZero(Math.floor(totalTime / (1000 * 60 * 60 * 24)))?.toString(),
                        hours: leadingZero(Math.floor((totalTime / (1000 * 60 * 60)) % 24))?.toString(),
                        minutes: leadingZero(Math.floor((totalTime / 1000 / 60) % 60))?.toString(),
                        seconds: leadingZero(Math.floor((totalTime / 1000) % 60))?.toString(),
                        elapsed: false
                    });
                } else {
                    setCountDownTime({
                        days: '00',
                        hours: '00',
                        minutes: '00',
                        seconds: '00',
                        elapsed: true
                    });
                    setStartCountdown(false);
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [startCountdown, start]);

    return countDownTime;
};
