import React, { useMemo } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { CardsPerPageType } from './Carousel';

interface PaginationProps {
    // total: number;
    current: number;
    onPrev: () => void;
    onNext: () => void;
    onSelect: (page: number) => void;
    atStart: boolean;
    atEnd: boolean;
    cardsPerPage: CardsPerPageType;
    pages: number[][];
}

interface PaginationNumberProps {
    page: number;
    current: number;
    onSelect: (page: number) => void;
}

const PaginationNumber = ({ onSelect, current, page }: PaginationNumberProps) => (
    <div
        onClick={() => onSelect(page)}
        className={`inline-flex cursor-pointer items-center border-t-2 border-transparent px-4 pt-2 text-sm font-medium ${(current) === page ? 'text-white' : 'text-[#64748B] hover:text-white'}"}`}
    >
        {page + 1}
    </div>
);

const Pagination = ({
    pages, current, onPrev, onNext, onSelect, atStart, atEnd, cardsPerPage
}: PaginationProps) => {
    // const pages = useMemo(
    //     () => Array.apply(null, Array(total)).map((x: unknown, i: number) => i + 1),
    //     [total],
    // );
    // console.log('total: ', total, 'current: ', current, 'pages: ', pages, 'atStart: ', atStart, 'atEnd: ', atEnd);

    // if (total <= 1) return null;
    // console.log('atStart: ', total, atStart, atEnd);
    const flattenedPages = pages?.length ? pages?.map((p,index:number) => index) : [];

    const isMiddlePage = (current < flattenedPages?.length - 1 && current > 1);

    return (
        <nav className="flex items-center justify-center px-4 sm:px-0">
            <div className="-mt-px flex">
                <div
                    onClick={() => onPrev()}
                    className={`inline-flex cursor-pointer items-center border-t-2 border-transparent pr-1 pt-2 text-sm font-medium text-[#64748B] hover:text-white ${atStart && 'opacity-50'}`}
                >
                    <ChevronLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
            </div>
            <div className="-mt-px flex">
                {
                    flattenedPages?.length > 6 ? (
                        <>
                            <PaginationNumber
                                page={isMiddlePage ? (current - 1) : 1}
                                current={current}
                                onSelect={onSelect}
                            />
                            <PaginationNumber
                                page={isMiddlePage ? current : 2}
                                current={current}
                                onSelect={onSelect}
                            />
                            <PaginationNumber
                                page={isMiddlePage ? current + 1 : 3}
                                current={current}
                                onSelect={onSelect}
                            />
                            <p
                                className="inline-flex border-t-2 border-transparent px-4 pt-2 text-sm font-medium text-[#64748B] hover:text-white"
                            >
                                ...
                            </p>
                            <PaginationNumber
                                page={flattenedPages?.length - 1}
                                current={current}
                                onSelect={onSelect}
                            />
                            <PaginationNumber
                                page={flattenedPages?.length}
                                current={current}
                                onSelect={onSelect}
                            />
                        </>
                    )
                        : flattenedPages.map(
                            (page: number, id) => <PaginationNumber page={page} current={current} onSelect={onSelect} key={id} />,
                        )
                }
            </div>
            <div className="-mt-px flex">
                <div
                    onClick={() => onNext()}
                    className={`inline-flex cursor-pointer items-center border-t-2 border-transparent pl-1 pt-2 text-sm font-medium text-[#64748B] hover:text-white ${atEnd && 'opacity-50'}`}
                >
                    <ChevronRightIcon className="ml-3 h-5 w-5" aria-hidden="true" />
                </div>
            </div>

        </nav>
    );
};

export default Pagination;
