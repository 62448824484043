import { useQuery } from '@apollo/react-hooks';
import getUserAccessQuery from "../api/getUserAccessQuery.js";
import { useConfig } from "@genflow/core";

const useUserAccess = (params?: { contentId?: string; skip?: boolean }) => {
    const { vendorUuid } = useConfig();
    const { data, loading } = useQuery(getUserAccessQuery, { variables: { vendorUuid }, skip: !vendorUuid || params?.skip });
    if (loading) return { isLoading: true };

    const userAccess = data?.getUserAccess?.data;

    if (params?.contentId && userAccess) {
        const hasAccess = !!userAccess?.find(
            ({ key, contentId }: { key: string; contentId: string }) =>
                key === 'content.read' && contentId === params?.contentId?.toString(),
        );

        return { hasAccess };
    }

    return {
        userAccess,
    };
};

export default useUserAccess;
