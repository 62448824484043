import React from 'react';
import { onboardingPages } from 'views/pages/onboarding/OnboardingQuestions';
import useUserAccess from "../../hooks/useUserAccess";

const OnboardingProgress = ({ completed }) => {
    const { userAccess } = useUserAccess();
    const hasDigitalLaunchPad = !!userAccess?.find(({contentId}) => contentId === '6196');
    const hasAgencyNavigator = !!userAccess?.find(({contentId}) => contentId === '6018');
    const OnboardingPages = onboardingPages({onlyDigitalLaunchPad: !hasAgencyNavigator && hasDigitalLaunchPad})

    const animate = {
        transition: 'width 1s ease-in-out',
    };

    const progressBar = {
        width: `${completed}%`,
    };

    return (
        <div className="h-[60px] flex justify-center items-center relative w-full" >
            <div className="h-[4px]  bg-[#30333a]" style={{ width: '100%' }}>
                <div className="h-full bg-gradient-to-t from-[#0384fe] to-[#03e5b4]" style={{ ...animate, ...progressBar }} />
            </div>
            <div className="absolute flex row justify-between w-full m-auto left-0 right-0 gap-3 xl:gap-6">
                {
                    OnboardingPages?.map(({ id, completed: value }) => (
                        <div key={id} className={`max-w-[60px] aspect-square flex-1 rounded-full flex justify-center items-center ${completed >= value ? 'bg-gradient-to-t from-[#0384fe] to-[#03e5b4]' : 'bg-[#30333a]'}`} style={animate}>
                            <span className="text-white">{id + 1}</span>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};


export default OnboardingProgress;
