const toTitleCase = (str: string) => {
    try {
        if (typeof str === 'string') {
            return str.replace(
                /\w\S*/g,
                txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
            );
        }
    } catch (error) {
        console.log(`Title case error - ${error}`);
    }
    return str;
};
export default toTitleCase;
