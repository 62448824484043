import React, { useContext, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { UserDataContext } from '../../../providers/UserDataProvider';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.js';
import { TimezoneContext } from 'providers/TimezoneProvider';
import Languages from '../../../constants/Languages.json'
import { sortBy } from 'lodash';

const continents = [
    {
        label: 'North America',
        value: 'North America',
    },
    {
        label: 'South America',
        value: 'South America',
    }, 
    {
        label: 'Central America',
        value: 'Central America',
    }, 
    {
        label: 'Africa',
        value: 'Africa',
    },
    {
        label: 'Europe',
        value: 'Europe',
    },
    {
        label: 'Middle East',
        value: 'Middle East',
    },
    {
        label: 'Asia',
        value: 'Asia',
    },
    {
        label: 'Antarctica',
        value: 'Antarctica',
    },
    {
        label: 'Australia',
        value: 'Australia',
    },    
];
const binaryOptions = [
    {
        label: 'Yes',
        value: 'yes',
    },
    {
        label: 'No',
        value: 'no',
    },
];
const programs = [
    {
        label: 'Agency Navigator',
        value: 'Agency Navigator',
    },
    {
        label: 'Pen to Profit',
        value: 'Pen to Profit',
    },
    {
        label: 'Six Figure Sales Rep',
        value: 'Six Figure Sales Rep',
    },
];


const OnboardingInfo4 = ({ next }) => {
    const { setTimezone, timezone } = useContext(TimezoneContext);

    const [info, setInfo] = useState({
        initialProgram: programs?.[0]?.value,
        ssc: binaryOptions?.[1]?.value,
        firstLanguage: 'English'
    });
    const { userProfile } = useContext(UserDataContext);

    const timezones = moment.tz.names()
    const timezonesData = useMemo(()=> {
        if(!timezones?.length){
            return []
        }
        return timezones?.map((item)=> ({
            label: item,
            value: item
        }))
    },[timezones])

    const handleChange = (e) => {
        const { value } = e.target;
        setInfo({
            ...info,
            [e.target.id]: value,
        });
    };

    const onSubmit = (e) => {
        next(e, {
            ...userProfile,
            data: { 
                ...userProfile?.data, 
                ...info,
                timezones: timezone,
                onboardingStageId: 4,
            },
        });
    };

    useEffect(()=> {
        if(userProfile?.data?.timezones) setTimezone(moment.tz.guess())
    },[])

    return (
        <div className="w-full flex flex-col mt-2">
            <form onSubmit={onSubmit}>
                <div className="mb-4 w-full">
                    <label htmlFor="firstLanguage" className="block mb-2 text-sm font-medium text-white w-full ">
                        What is your first language?
                        <div className="pr-2.5 rounded-md glassCard backdrop-blur-md overflow-hidden mt-2">
                            <select value={userProfile?.data?.firstLanguage || info?.firstLanguage} onChange={handleChange} id="firstLanguage" className="bg-transparent text-white text-sm  focus:outline-none focus:border-blue-500 block w-full !p-2.5">
                                {sortBy(Languages, 'name')?.map((option, id) => (
                                    <option value={option?.name} key={id}>{option?.name}</option>
                                ))}
                            </select>
                        </div>
                    </label>
                </div>
                               
                <div className="mb-4 w-full">
                    <div htmlFor="name" className="block mb-2 text-sm font-medium text-white w-full ">
                        What timezone do you operate in?
                        <div className="rounded-md glassCard backdrop-blur-md overflow-hidden mt-2">
                        <Select
                            value={timezonesData?.find((item)=> item?.value === timezone)}
                            onChange={timezones => setTimezone(timezones?.value)}
                            className="border-none body3 w-full "
                            options={timezonesData}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: 'transparent',
                                    border: state.isFocused ? 0 : 0,
                                    boxShadow: "none",
                                    color: 'white'
                                  }),
                                input: (baseStyles) => ({
                                    ...baseStyles,
                                    color: 'white'
                                  }),
                                menuPortal: base => ({ ...base, zIndex: 9999, color:'white'  }),
                                menu: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#0C1018',
                                    border: "none"
                                }),
                                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                    return {
                                    ...styles,
                                    backgroundColor: isDisabled
                                        ? undefined
                                        : isSelected
                                        ? '#283C63'
                                        : isFocused
                                        ? '#3C4963'
                                        : undefined,
                                    };
                                },
                            }}
                            menuPortalTarget={document.body}
                        />
                        </div>
                    </div>
                </div>
                {/* <div className="mb-4 w-full">
                    <div htmlFor="name" className="block mb-2 text-sm font-medium text-white w-full ">
                        Which program will you be starting with?
                        <div className="pr-2.5 rounded-md glassCard backdrop-blur-md overflow-hidden mt-2">
                            <select value={info?.initialProgram} onChange={handleChange} id='initialProgram' className="bg-transparent text-white text-sm  focus:outline-none focus:border-blue-500 block w-full !p-2.5">
                                {programs?.map((option, id) => (
                                    <option value={option?.value} key={id}>{option?.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div> */}
                {/* <div className="mb-4 w-full">
                    <div htmlFor="name" className="block mb-2 text-sm font-medium text-white w-full ">
                    Have you booked a call with your Student Success Coach yet?
                        <div className="pr-2.5 rounded-md glassCard backdrop-blur-md overflow-hidden mt-2">
                            <select value={info?.ssc} onChange={handleChange} id='ssc' className="bg-transparent text-white text-sm  focus:outline-none focus:border-blue-500 block w-full !p-2.5">
                                {binaryOptions?.map((option, id) => (
                                    <option value={option?.value} key={id}>{option?.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div> */}
                <button
                    type="submit"
                    className="button flex w-full justify-center rounded-md  px-3 py-3 2xl:py-4 text-lg md:text-lg font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Next
                </button>
            </form>
        </div>
    );
};

export default OnboardingInfo4;
