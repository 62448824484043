import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import upsertUserProfileData from '../../../api/upsertUserProfileData';
import { UserDataContext } from '../../../providers/UserDataProvider';
import countries from '../../../constants/registration/countriesData.json';
import { sortBy } from 'lodash';

interface OnboardingCalendarProps {
    next: (e: any, data: object) => void;
    profileUpdate: object;
    url: string;
    stageId: number
    className?: string;
}

const OnboardingInfo6 = ({ next, profileUpdate, url, stageId, className, }: OnboardingCalendarProps) => {
    const [info, setInfo] = useState({
        country: countries?.find((country)=> country?.name === 'United Kingdom')?.name
    });
    const { userProfile, setUserProfile } = useContext(UserDataContext);

    console.log({userProfile})

    const [upsertProfileData] = useMutation(upsertUserProfileData, {});

    const onSubmit = (e: any) => {
        next(e, {
            ...userProfile,
            data: { 
                ...userProfile?.data, 
                ...info,
                onboardingStageId: stageId,
            },
        });
    };

    useEffect(() => {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute(
          'src',
          'https://assets.calendly.com/assets/external/widget.js'
        );
        head?.appendChild(script);
    }, []);

    return (
        <div className="w-full flex flex-col mt-2">
            <form onSubmit={onSubmit}>
                {url && (
                    <>
                        <div className={`calendly-inline-widget rounded-[20px] overflow-hidden min-w-[320px] ${className}`} data-url={url}></div>
                        <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
                    </>
                )}
                <button
                    type="submit"
                    className="button flex w-full justify-center rounded-md  px-3 py-3 2xl:py-4 text-lg md:text-lg font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-4"
                >
                    I've booked my call
                </button>

            </form>
        </div>
    );
};

export default OnboardingInfo6;
