import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Row, // UncontrolledCollapse,
} from 'reactstrap';
import { useConfig } from '@genflow/core';
import { Typography, Grid, Switch, withStyles } from '@material-ui/core';
import { Link, useLocation, useParams } from 'react-router-dom';
import { findIndex, get, sortBy } from 'lodash';
import upsertUserProfileData from 'api/upsertUserProfileData';
import { useMutation } from '@apollo/react-hooks';
import './style.scss';
import '../../assets/css/sproutvideo.css';
import {
  ChevronLeftIcon,
  ChevronDownIcon,
  CheckIcon,
  ExternalLinkIcon,
  CheckCircleIcon as EmptyCheck,
  DownloadIcon,
} from '@heroicons/react/outline';
import { Disclosure } from '@headlessui/react';
import { trackEvent } from '../../utils/useAnalytics';
import Mp3Player from './components/mp3Player';
import CollapsibleSection from './components/CollapsibleSection';
import CustomLoader from '../CustomLoader';
import useStickyState from '../../utils/useStickyState';
import SubSections from './components/SubSections';
import { UserDataContext } from '../../providers/UserDataProvider';
import PhaseMenu from '../menu/PhaseMenu.tsx';
import { CheckCircleIcon } from '@heroicons/react/solid';
import completeIcon from '../../assets/img/icons/completed.svg';
import play from '../../assets/img/play.svg';
import Carousel from 'components/carousels/Carousel.tsx';
import Dropdown from 'components/menu/Dropdown.tsx';
import upscaleVideo from 'utils/upscaleVideo.js';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 44,
    height: 24,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: 'white',
    '&$checked': {
      transform: 'translateX(20px)',
      color: 'white',
      '& + $track': {
        opacity: 1,
        backgroundColor: 'black',
        borderColor: 'white',
      },
    },
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 20,
    opacity: 1,
    backgroundColor: 'black',
  },
  checked: {},
}))(Switch);

const whichVideoPlay = (videos) => {
  let video = null;

  videos.map((v) => {
    if (!v.complete && !video) {
      video = v;
    }
  });

  return video || videos[0];
};

export default ({
  name = 'Video Page',
  sections = [],
  loading = false,
  showSectionIndex = true,
  enableCompleteVideo = true,
  onToggleComplete,
  nextButtonLabel = 'Next Video',
}) => {
  const {
    beaconKey
  } = useConfig();
  const { productID, phaseId: pId, lessonID } = useParams();

  const [phaseId, setPhaseId] = useState(pId);

  // current phase in course section
  const item =
    useMemo(
      () => sections?.find(({ id }) => id === phaseId),
      [phaseId, sections, phaseId]
    ) || sections?.[0];

  const brandSrc = '';
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [transcriptEnabled, setTranscriptEnabled] = useState(false);
  const [transcriptAssets, setTranscriptAssets] = useState({
    audio: null,
    txt: null,
  });

  const [transcriptTxt, setTranscriptTxt] = useState('');
  const [visibleSection, setVisibleSection] = useState(true);

  const [mp3Player, setMp3Player] = useState(false);
  const [autoPlay, setAutoPlay] = useStickyState(true, 'autoPlay');

  const [phases, setPhases] = useState(true);
  const [downloads, setDownload] = useState(false);
  const [pandp, setPandp] = useState(false);

  const [phaseBg, setPhaseBg] = useState('#434343');
  const [downloadBg, setDownloadBg] = useState('#1C1C1C');
  const [pandpBg, setPandpBg] = useState('#1C1C1C');
  const [audioBg, setAudioBg] = useState('#1C1C1C');
  const { userProfile, setUserProfile } = useContext(UserDataContext);
  const [upsertProfileData] = useMutation(upsertUserProfileData, {});

  // const [playerLoaded, setPlayerLoaded] = useState(false);
  const flatVideosList = sections.reduce((acc, section) => {
    if (section.subSections) {
      section.subSections.map((sectionItem) => {
        acc = [...acc, ...sectionItem.videos];
      });
    } else {
      acc = [...acc, ...section.videos];
    }
    return acc;
  }, []);

  const selectedPhaseIndex = sections?.findIndex(
    ({id})=> id === phaseId)

  const selectedVideoIndex = flatVideosList.findIndex(
    ({ id: videoId }) => videoId === selectedVideo?.id
  );

  const selectedVideoSectionIndex = item?.videos?.findIndex(
    ({ id: videoId }) => videoId === selectedVideo?.id
  );

  useEffect(() => {
    // save current phase & video on product key to profile for tracking progress
    if (selectedVideo?.id) {
      const data = {
        ...userProfile?.data,
        progress: {
          ...userProfile?.data?.progress,
          [productID]: { lesson: item?.id, video: selectedVideo?.id },
        },
      };
      setUserProfile({
        ...userProfile,
        data,
      });
      upsertProfileData({
        variables: {
          data: JSON.stringify(data),
        },
      });
    }
  }, [selectedVideo, item, productID]);

  const hasNextVideo = flatVideosList.length - 1 > selectedVideoIndex;

  // last video in current phase
  const lastPhaseVideo = useMemo(()=> item?.videos?.length - 1 <= findIndex(item?.videos, (video)=> video?.id === selectedVideo?.id),[item?.videos, selectedVideo]) 

  const whichVideoPlayFromPhase = (videos) => {
    let video = null;

    videos.map((v) => {
      if (item?.videos?.[0] && item?.videos?.[0]?.id === v.id) {
        video = v;
      } else if (
        item?.subSections &&
        item?.subSections?.[2]?.videos?.[3]?.id === v.id
      ) {
        video = v;
      }
    });

    return video || videos[0];
  };

  useEffect(() => {
    if (!loading && !selectedVideo && !!item) {
      if (lessonID) {
        setSelectedVideo(
          flatVideosList.find((section) => section.id === lessonID)
        );
        return;
      }
      setSelectedVideo(whichVideoPlayFromPhase(flatVideosList));
    } else if (!loading && !selectedVideo) {
      setSelectedVideo(whichVideoPlay(flatVideosList));
    }
  }, [loading, sections, item, phaseId]);

  useEffect(() => {
    const transcript = { audio: null, text: null };

    if (selectedVideo) {
      selectedVideo.transcript.map((transcriptItem) => {
        if (transcriptItem.type === 'Audio') {
          transcript.audio = transcriptItem;
        }

        if (transcriptItem.type === 'Transcript') {
          extractTranscriptText(transcriptItem.url);
          transcript.txt = transcriptItem;
        }
      });

      setTranscriptAssets(transcript);
    }
  }, [selectedVideo]);

  const goNextVideo = () => {
    if (hasNextVideo) {
      // if last phase video & course has more videos go to next phase & next video
      if(lastPhaseVideo){
        setPhaseId(sections?.[selectedPhaseIndex + 1]?.id)
        setSelectedVideo(flatVideosList[selectedVideoIndex + 1]);
        return
      }

      setSelectedVideo(flatVideosList[selectedVideoIndex + 1]);
    }
  };

  useEffect(() => {
    if (selectedVideo) {
      setSelectedVideo(
        flatVideosList.find((section) => section.id === selectedVideo.id)
      );
    }
  }, [flatVideosList]);

  const NavBar = () => (
    <Link
      to={{
        pathname: `/course/${productID}`,
        state: { productID, brandSrc, phaseId },
      }}
    >
      <div className="w-10/12 mx-auto relative justify-center">
        <div className="flex flex-row items-center text-white opacity-50 absolute top-0 lg:top-1">
          <ChevronLeftIcon className="h-4 w-4 mt-1" />
          <div className="body3 ml-1 mt-1">Back</div>
        </div>
        <div>
          <h3 className="text-white text-center mt-6 hidden lg:block">
            {name}
          </h3>
          <h5 className="text-white text-center mt-6 block lg:hidden">
            {name}
          </h5>
        </div>{' '}
      </div>
    </Link>
  );

  const urlVideo = upscaleVideo(get(selectedVideo, 'url', ''));

  const metadata = get(selectedVideo, 'metadata', '');
  const plugAndPlay = metadata === '' || metadata === [] ? null : metadata;

  const filterValidPlugs = (p) => {
    if (!p?.plugAndPlaySlug) {
      return null;
    }

    const plugs = p?.plugAndPlaySlug?.filter((pItem) => !!pItem.url);
    if (!plugs?.length) return null;
    return plugs;
  };

  const extractTranscriptText = async (url) => {
    try {
      fetch(url)
        .then((r) => r.text())
        .then((text) => {
          setTranscriptTxt(text);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  // track when a new video starts
  useEffect(() => {
    if (selectedVideo) {
      trackEvent({
        action: 'Module_Started',
        category: 'Module_Name',
        label: selectedVideo?.name,
      });
    }
  }, [selectedVideo]);

  const downloadableAssets = useMemo(
    () => sortBy(selectedVideo?.downloads?.filter(({ type }) => type !== 'Secure Video'), 'order'),
    [selectedVideo?.downloads]
  );

  // change selected video to first video in phase
  const updatePhaseVideo = (phaseId) => {
    const phaseItemVideo = sections?.find((item)=> +item?.id === +phaseId)?.videos?.[0];
    if(!!phaseItemVideo){
      setSelectedVideo(phaseItemVideo)
    }    
  }

  useEffect(()=> {
    window.Beacon('destroy')
    return()=> {
      window.Beacon('init', beaconKey)
    }
  },[])

  return (
    <div>
      {/* <NavBar /> */}
      <main>
        <section>
          <CustomLoader loading={loading}>
            <div className='flex-col lg:flex-row flex p-4 gap-4 lg:p-6 lg:gap-6 overflow-hidden justify-start items-stretch lg:items-start'>
              <div className="w-full flex flex-1 flex-col gap-4 lg:gap-6 overflow-hidden">
                <div className="w-full mx-auto">
                  <div className="flex flex-col glassCard rounded-[20px] md:rounded-[30px] p-2 md:p-6">
                    <div
                      className="rounded-[15px] overflow-hidden relative w-full h-full aspect-video"
                      // style={{ background: '#101010' }}
                    >
                      {urlVideo && (
                        <iframe
                          title="video"
                          src={`${urlVideo}?cc=eng&autoPlay=${autoPlay}`}
                          style={{
                            background: '#101010',
                          }}
                          frameBorder="0"
                          allowFullScreen
                          width="100%"
                          height="100%"
                        />
                      )}
                    </div>
                    <div className='flex flex-col'>
                      <div className="mt-3 flex flex-row justify-between items-center">
                        <div>
                          <p className="text-base text-[#ffffff50] md:mb-1">
                            {`Module: ${item?.name}`}
                          </p>
                          <p className="text-lg leading-[20px]">
                            {selectedVideo?.name}
                          </p>
                        </div>

                        <div
                          onClick={() => {
                            const newComplete = !selectedVideo?.complete;
                            onToggleComplete(selectedVideo);
                            if (newComplete) {
                              goNextVideo();
                              trackEvent({
                                action: 'Module_Complete',
                                category: 'Session_Name',
                                label: name,
                              });
                            }
                          }}
                          className={`${
                            !selectedVideo?.complete && 'button !border-[1px]'
                          } hover:cursor-pointer !rounded-[8px] py-2 px-4 flex justify-center items-center bg-gradient-to-r from-[#0384FE] to-[#03E5B4]`}
                        >
                          {selectedVideo?.complete && (
                            <CheckCircleIcon className="text-white mr-2 h-4 w-4" />
                          )}
                          <p className="text-base text-[#CBD5E1] font-semibold">
                            {selectedVideo?.complete
                              ? 'Completed'
                              : lastPhaseVideo ? 'Complete module' :'Mark as complete'}
                          </p>

                        </div>
                      </div>

                        <div
                        dangerouslySetInnerHTML={{ __html: selectedVideo?.description}}
                        className="text-base leading-[120%] mt-4 text-white/50"
                        />
                    </div>
                  </div>
                </div>
                {/* Downloads */}
                <Row className="flex flex-col md:flex-row justify-between h-max md:h-[28%] flex-1">
                  {!!downloadableAssets?.length && (
                    <div className="w-full py-4 text-white block glassCard rounded-[16px] md:mt-0">
                      <p className="mb-4 text-[20px] font-medium text-[#CBD5E1] px-4">
                        Downloads + Plug & Plays
                      </p>
                      <div className="w-full mt-4 overflow-hidden relative">
                        <Carousel
                          content={downloadableAssets}
                          render={(item, isSnapPoint, index) => (
                            <a
                            type="button"
                            rel="tooltip"
                            data-original-title=""
                            title=""
                            href={item.url}
                            target="_blank"
                            download
                            key={item?.url}
                            className={`${isSnapPoint && 'snap-start'} pl-4 ${index === downloadableAssets.length - 1 && 'pr-4'}`}
                          >
                            <div 
                              className={`glassCard w-[175px] h-[90px] relative rounded-[12px] p-2 mb-2 shrink-0 `}
                              onClick={() =>
                                trackEvent({
                                  action: 'Download',
                                  category: 'Module_Name',
                                  label: selectedVideo?.name,
                                })
                              }
                            >
                              <p className="text-base text-[#CBD5E1]">
                                {item.title}
                              </p>
                              <p className="text-sm text-[#64748B]">
                                {get(item, 'type', '')}
                              </p>

                                <div
                                  className="absolute z-99 right-2 bottom-2"

                                >
                                  <DownloadIcon className="h-4 w-4 mt-1 text-[#64748B]" />
                                </div>
                            </div>
                          </a>
                          )}
                        />
                        </div>
                      </div>
                    )}
                </Row>
              </div>
              {/* Sidebar */}
              <div className='w-full lg:w-1/4 transition-all flex'>
              {!!item && (
                <div className="glassCard rounded-[16px] flex flex-col py-4 flex-1">
                  <div className="grow-0 flex flex-row lg:glassCard self-start pb-4 rounded-[12px] mx-4">
                  {/* phase dropdown sections */}
                  <Dropdown
                    title={item?.name}
                    options={sections?.map((item)=> ({
                      label: item?.name,
                      value: item?.id,
                      disabled: item?.locked,
                    }))}
                    onSelect={(item) => {
                      setPhaseId(item?.value)
                      updatePhaseVideo(item?.value)
                    }}
                    smallDropDownWIdth='200px'
                    dropDownWidth={'200px'}
                    videoDropDown
                    width='200px'
                  />
                  </div>
                  {sections.map((section) => {
                    if (section.name === item?.name) {
                      return (
                        <div className="w-full overflow-hidden">
                          <div className="overflow-auto flex flex-col items-center pr-4 gap-4">
                            {sortBy(section.videos, 'order')
                            ?.filter(({ metadata }) => !metadata?.isNonEducate)
                            ?.map((item, index) => (
                              <div
                                key={index}
                                className="pl-4 mb-2 md:mb-0 h-full cursor-pointer flex-row flex items-center w-full"
                                onClick={() => {
                                  setSelectedVideo(item);
                                }}
                              >
                                <div className="aspect-video w-1/2 max-w-[200px] rounded-[16px] overflow-hidden relative cursor-pointe">
                                  <img
                                    src={item?.thumbnail}
                                    alt="phase video"
                                    className="object-cover object-center w-full h-full"
                                  />
                                  <div className="w-full h-full absolute bottom-0 flex justify-end items-end bg-black/25 ">
                                    <p className="text-[5rem] leading-[50px] font-bold text-white/[0.15]">
                                      {index < 10
                                        ? `${index + 1}`
                                        : index + 1}
                                    </p>
                                  </div>
                                  <div className="absolute z-99999 w-full h-full top-0 flex justify-center items-center">
                                    <div
                                      className="border-[1px] border-white rounded-full w-6 h-6  flex justify-center items-center glassCardBorderWhite "
                                    >
                                      <img
                                        src={play}
                                        alt="play video"
                                        className="h-3 w-3 ml-0.5"
                                      />
                                    </div>
                                  </div>
                                  {item?.complete && (
                                    <div className="absolute top-2 right-2">
                                      <img
                                        src={completeIcon}
                                        alt="video complete h-6 w-6"
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="flex-1 px-2 flex flex-col justify-start items-start">
                                    <p className="text-[#CBD5E1] font-medium mb-1 lg:text-sm 2xl:text-base">
                                      {item?.name}
                                    </p>
                                  </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              )}

              </div>
            </div>

          </CustomLoader>
        </section>
      </main>
    </div>
  );
};
