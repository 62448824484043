import gql from 'graphql-tag';

export default gql`
    query ($vendorUuid: ID!, $productId: ID!) {
        getUnauthorisedCourse(vendorUuid: $vendorUuid, productId: $productId) {
            data {
                name
                description
                meta_data
                sections {
                    id
                    name
                    description
                    order
                    meta_data
                    lessons {
                        id
                        name
                        order
                        meta_data
                        assets {
                            id
                            sprout_id
                            title
                            thumbnail
                            order
                            length_seconds
                            asset_type {
                                id
                                name
                            }
                        }
                    }
                }
            }
            error
        }
    }
`;




