import React from 'react';
import { CoreProvider } from '@genflow/core';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { ThemeProvider } from '@material-ui/core/styles';

import config from '../config';
import apolloClient from './apolloClient';
import theme from './theme';
import { UserDataProvider } from './UserDataProvider';
import { OnboardingProvider } from './OnboardingProvider';
import { HabitsProvider } from 'to-package/app-habit-tracker/providers/HabitsProvider';
import { TimezoneProvider } from './TimezoneProvider';
import { CognitoUserProvider } from "./CognitoUserProvider";

const Providers = ({ children }) => (
    <ThemeProvider theme={theme}>
        <CoreProvider config={config}>
                <ApolloProvider client={apolloClient}>
                    <ApolloHooksProvider client={apolloClient}>
                      <CognitoUserProvider>
                        <UserDataProvider>
                            <TimezoneProvider>
                                <HabitsProvider>
                                    <OnboardingProvider>
                                        { children }
                                    </OnboardingProvider>
                                </HabitsProvider>
                            </TimezoneProvider>
                        </UserDataProvider>
                      </CognitoUserProvider>
                    </ApolloHooksProvider>
                </ApolloProvider>
        </CoreProvider>
    </ThemeProvider>
);

export default Providers;
