import React, { FC, useMemo, } from "react";
import { UserProfile, UserProfileContactMethod } from "../../@types/user-discovery";
import friendHeader from "../../assets/img/friendHeader.png";
import { programs, socials } from "../../constants/findAFriend/content.ts";
import countries from "../../constants/registration/countriesData.json";
import getContactMethodUrl from "../../utils/getContactMethodUrl.ts";
import getConnectWithMeButtonData from "../../utils/getConnectWithMeButtonData.ts";
import getFlag from "utils/getFlag.ts";

type FAFListCardProp = {
  userProfile: UserProfile;
  onClickUser: () => void;
};

const FAFListCard: FC<FAFListCardProp> = ({
                                            userProfile,
                                            onClickUser,
                                          
                                          }) => {

  const connectWithMeButtonData = getConnectWithMeButtonData(userProfile);

  const {flag} = getFlag(userProfile);
  const socialLinks = useMemo(()=> userProfile?.contactMethods?.filter((item)=> item?.platform !== 'community' )  ,[userProfile?.contactMethods])
  const careerIcon : string | undefined = programs?.find(({value})=> value?.toLocaleLowerCase() === userProfile?.careerPath?.toLocaleLowerCase())?.icon

  return (
    <div
      className="flex flex-row items-center justify-between w-full border-t-[1px] border-white/5 my-5 pt-5 hover:cursor-pointer"
      onClick={onClickUser}
    >
      <div className="flex flex-row items-center w-80">
        <div className="h-14 w-14 md:h-16 md:w-16 aspect-square rounded-full overflow-hidden p-[1px] bg-white/5  mr-3">
          <img
            src={userProfile.profilePictureUrl ?? friendHeader}
            className="h-full w-full object-cover rounded-full"
          />
        </div>

        <div className="flex flex-col justify-between ">
          <p
            className="text-base font-bold mb-1 lg:mb-2">{`${userProfile?.firstName ?? ""} ${userProfile?.lastName ?? ""}`}</p>
          <p className="lg:hidden text-xs font-bold mb-1">{userProfile?.location}</p>
          {userProfile?.careerPath && <div className="bg-[#4f70a05e] px-3 py-2 rounded-full self-start flex flex-row items-center justify-center">
          {!!careerIcon && <img src={careerIcon} className="h-3 w-3 object-contain mr-1.5" />}
            <p className="text-xs">{userProfile?.careerPath}</p>
          </div>}

          {!!connectWithMeButtonData?.platform &&
          <a className="flex md:hidden flex-row items-center glassCard !border-[1px] rounded-xl px-3 py-2 mt-2"
             href={connectWithMeButtonData?.url} target="_blank">
            <img
              src={socials.find(({ platform }) => platform === connectWithMeButtonData?.platform)?.icon}
              className="w-4 h-4 object-contain mr-2"
            />
            <p className="text-sm text-[#CBD5E1]">Connect with me</p>
          </a>}
        </div>
      </div>

      {
        userProfile?.location && <div className="hidden lg:flex flex-row items-center w-80">
          <div
            className="flex items-center justify-center h-8 w-8 md:h-12 md:w-12 rounded-full overflow-hidden md:p-[1px] bg-[#4f70a05e]  mr-2">
           {!!flag && <img
              src={flag}
              className="h-5 w-5 md:h-7 md:w-7 object-cover rounded-full"
            />
            }
          </div>

          <div className="flex flex-col justify-between ">
            <p className="text-sm md:text-base font-bold">{userProfile?.location}</p>

          </div>
        </div>
      }

      <div className="hidden md:flex justify-end flex-1">
        <div className="hidden lg:flex flex-row items-center mr-10">
          {socialLinks?.length > 1 && socialLinks?.map((contactMethod) => (
            <a key={contactMethod?.platform + contactMethod?.method} href={getContactMethodUrl(contactMethod)}
               target="_blank">
              <img
                src={socials.find(({ platform: socialPlatform }) => socialPlatform === contactMethod?.platform)?.icon}
                className="w-6 h-6 object-contain mr-2" />
            </a>
          ))}
        </div>

        {connectWithMeButtonData?.platform &&
          <a className="hidden md:flex flex-row items-center glassCard !border-[1px] rounded-xl px-3 py-2"
             href={connectWithMeButtonData?.url} target="_blank">
            <img
              src={socials.find(({ platform }) => platform === connectWithMeButtonData?.platform)?.icon}
              className="w-4 h-4 object-contain mr-2"
            />
            <p className="text-sm text-[#CBD5E1]">Connect with me</p>
          </a>}
      </div>
    </div>
  );
};

export default FAFListCard;
