import React, { FC } from 'react'
import Modal from './Modal'
import lockIcon from '../../assets/img/icons/lockIcon.svg'
import { Link } from 'react-router-dom'


type GoalLockedModalType = {
    openGoalLockedModal: {
        image: string
        message: string,
        cta: string,
        logo: string,
        link: string,
        externalLink?: boolean
    },
    close: ()=> void
}

const GoalLockedModal:FC<GoalLockedModalType> = ({openGoalLockedModal, close}) => (
    <Modal
    open={!!openGoalLockedModal}
    setOpen={close}
    opacity={70}
  >
    <div className="py-3 px-3 flex justify-center items-center lg:w-screen">
      <div className="flex flex-col md:flex-row p-5 md:p-8 rounded-[20px] md:rounded-[30px] glassCard !bg-[#17191F] backdrop-blur-md relative w-full md:w-[90%] lg:w-1/2">
        <div className='w-full md:w-52 h-auto relative'>
            {!!openGoalLockedModal?.image && <img
                src={openGoalLockedModal?.image}
                alt="card"
                className="object-cover h-full w-full rounded-2xl"
            />}
            {!!openGoalLockedModal?.logo && (
                    <div className='absolute bottom-8 left-0 right-0 mx-auto w-full'>
                    <img
                        src={openGoalLockedModal?.logo}
                        alt="brand"
                    />
                    </div>
                )
            }
        </div>

        <div className='w-full md:w-2/3 flex flex-col justify-end items-start md:pl-8'>
            <div className='flex flex-row md:flex-col items-center md:items-start'>
                <div className='glassCard p-2 md:p-4 md:rounded-2xl rounded-lg mt-2'>
                    <img src={lockIcon} className='w-6 h-6 md:w-10 md:h-10 object-cover' alt='locked'/>
                </div>
                <p className='text-[#CBD5E1] text-2xl md:text-4xl font-normal mt-2 ml-2 md:ml-0'>Program Locked</p>
            </div>
            <p className='text-[#CBD5E1]/50 text-base text-left mt-2'>{openGoalLockedModal?.message}</p>
            {!!openGoalLockedModal?.cta && !openGoalLockedModal?.link &&
            <div className='w-full border-[2px] border-[#0384FE] rounded-xl py-2 mt-4 hover:cursor-pointer' onClick={close}>
                <p className='text-[#CBD5E1] font-semibold text-base md:text-lg'>{openGoalLockedModal?.cta}</p>
            </div>
            }
            
            {!!openGoalLockedModal?.cta && !!openGoalLockedModal?.link &&
            (
                openGoalLockedModal?.externalLink ?
                <a
                href={openGoalLockedModal?.link}
                target="_blank"
                rel="noreferrer"
                className='w-full border-[2px] border-[#0384FE] rounded-xl py-2 mt-4 hover:cursor-pointer' 
                onClick={close}>
                <p className='text-[#CBD5E1] font-semibold text-base md:text-lg'>{openGoalLockedModal?.cta}</p>
                 </a>
            :
                <Link to={openGoalLockedModal?.link} className='w-full border-[2px] border-[#0384FE] rounded-xl py-2 mt-4 hover:cursor-pointer' onClick={close}>
                <p className='text-[#CBD5E1] font-semibold text-base md:text-lg'>{openGoalLockedModal?.cta}</p>
                </Link>
            )
            }
        </div>
      </div>
    </div>
  </Modal>
  );

export default GoalLockedModal
