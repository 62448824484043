import React, { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link, useHistory, useLocation } from "react-router-dom";

import { ChartBarIcon, XIcon } from "@heroicons/react/outline";
import ProfileCard from "../cards/ProfileCard";
import { UserDataContext } from "../../providers/UserDataProvider";
import dashboardInActive from "../../assets/img/icons/dashboard-inactive.svg";
import dashboardActive from "../../assets/img/icons/dashboard-active.svg";
import coachingActive from "../../assets/img/icons/coaching-active.svg";
import coachingInactive from "../../assets/img/icons/coaching-inactive.svg";
import recordingActive from "../../assets/img/icons/recording-active.svg";
import recordingInactive from "../../assets/img/icons/recording-inactive.svg";
import monkModeActive from "../../assets/img/icons/monk-mode-active.svg";
import monkModeInactive from "../../assets/img/icons/monk-mode-inactive.svg";
import communityActive from "../../assets/img/icons/community-active.svg";
import communityInactive from "../../assets/img/icons/community-inactive.svg";
import courseActive from "../../assets/img/icons/course-active.svg";
import courseInactive from "../../assets/img/icons/course-inactive.svg";
import settingsActive from "../../assets/img/icons/settings-active.svg";
import settingsInactive from "../../assets/img/icons/settings-inactive.svg";
import logoutInactive from "../../assets/img/icons/logout-inactive.png";
import logoutActive from "../../assets/img/icons/logout-active.png";
import fileInactive from "../../assets/img/icons/file-inactive.svg";
import fileActive from "../../assets/img/icons/file-active.svg";
import externalLink from "../../assets/img/icons/externalLink.svg";
import logo from "../../assets/img/logo.svg";
import PurchaseModal from "./PurchaseModal";
import useStickyLogout from "hooks/useStickyLogout";
import TimeZoneCard from "components/cards/TimeZoneCard";
import { useIsLoggedIn } from "@genflow/web-auth";
import { Redirect } from "react-router";
import friendInactive from "../../assets/img/icons/friend-inactive.svg";
import friendActive from "../../assets/img/icons/friend-active.svg";
import useUserAccess from "../../hooks/useUserAccess";
import env from "../../env";
import { useConfig } from "@genflow/core";
import axios from "axios";
import { Auth } from "aws-amplify";
import useCommunity from "../../hooks/useCommunity";

const Sidebar = ({ children }) => {
  const { vendorUuid } = useConfig();
  const history = useHistory();
  const { setUserProfile } = useContext(UserDataContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { discordCommunityUrl, facebookCommunityUrl } = useCommunity();

  const location = useLocation();
  const activePath = location?.pathname;

  const isLoggedIn = useIsLoggedIn(true);
  const { userAccess, isLoading } = useUserAccess();

  const hasAccess = !!userAccess?.find(({ key }) => key === "analytics.read");
  const hasDigitalLaunchPad = !!userAccess?.find(({ contentId }) => contentId === "6196");
  const hasAgencyNavigator = !!userAccess?.find(({ contentId }) => contentId === "6018");
  const onlyDigitalLaunchPad = !hasAgencyNavigator && hasDigitalLaunchPad;

  useEffect(() => {
    if (sidebarOpen) setSidebarOpen(false);
  }, [location]);


  const navigation = [
    {
      name: "Dashboard",
      link: "/dashboard",
      icon: dashboardInActive,
      activeIcon: dashboardActive,
      current: activePath?.includes("dashboard")
    },
    {
      name: "Programs",
      link: "/programs",
      icon: courseInactive,
      activeIcon: courseActive,
      current: activePath?.includes("programs")
    },
    hasAgencyNavigator && {
      name: "Educators",
      link: "/upcoming-q-a",
      icon: coachingInactive,
      activeIcon: coachingActive,
      current: activePath?.includes("upcoming-q-a")
    },
    hasAgencyNavigator && {
      name: "Recordings",
      link: "/resources/qa",
      icon: recordingInactive,
      activeIcon: recordingActive,
      current: activePath?.includes("resources/qa")
    },
    {
      name: "Monk Mode",
      link: "/monk-mode",
      icon: monkModeInactive,
      activeIcon: monkModeActive,
      current: activePath?.includes("monk-mode")
    },
    {
      name: "Resources",
      link: "/resources",
      icon: fileInactive,
      activeIcon: fileActive,
      current: activePath?.includes("resources") && !activePath?.includes("qa")
    },
    hasAgencyNavigator && {
      name: "Find a Friend",
      link: "/find-a-friend",
      icon: friendInactive,
      activeIcon: friendActive,
      current: activePath?.includes("find-a-friend")
    },
    // {
    //     name: 'Community',
    //     link: '/community',
    //     icon: communityInactive,
    //     activeIcon: communityActive,
    //     current: activePath?.includes('community'),
    // },
    (onlyDigitalLaunchPad && discordCommunityUrl || hasAgencyNavigator) && {
      name: "Community",
      link: hasAgencyNavigator ? facebookCommunityUrl : hasDigitalLaunchPad ? discordCommunityUrl : facebookCommunityUrl,
      icon: communityInactive,
      secondaryIcon: externalLink,
      activeIcon: communityActive,
      current: false,
      external: true
    },
    // Settings
    hasAgencyNavigator && {
      name: "Settings",
      link: "/settings",
      icon: settingsInactive,
      activeIcon: settingsActive,
      current: activePath?.includes("settings")
    }
  ]?.filter(Boolean);

  const onClickManageBilling = async () => {
    const portalAPI = {
      staging: `https://1osfn38ejh.execute-api.eu-west-1.amazonaws.com/dev/subscriptions/${vendorUuid}/stripe/customer-portal`,
      production: `https://ix6hdu8hu0.execute-api.eu-west-1.amazonaws.com/prod/subscriptions/${vendorUuid}/stripe/customer-portal`
    }[env];
    console.log("portalAPI", portalAPI);
    const cognitoUserSession = await Auth.currentSession();
    const response = await axios.get(portalAPI, {
      timeout: 4000,
      headers: { "Authorization": `Bearer ${cognitoUserSession?.getIdToken()?.getJwtToken()}` }
    });
    const userPortalUrl = response?.data?.url;
    window.open(userPortalUrl, "_blank");
    console.log("response", response);
  };
  const onClickLogout = useStickyLogout();

  const [options, setOptions] = useState([
    {
      id: 0,
      name: "Manage Billing",
      href: "#",
      initial: "H",
      current: false,
      icon: settingsInactive,
      activeIcon: settingsActive
    },
    {
      id: 1,
      name: "Log out",
      href: "#",
      initial: "H",
      current: false,
      icon: logoutInactive,
      activeIcon: logoutActive,
      onPress: onClickLogout
    }
  ]);

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        const portalAPI = {
          staging: `https://1osfn38ejh.execute-api.eu-west-1.amazonaws.com/dev/subscriptions/${vendorUuid}/stripe/customer-portal`,
          production: `https://ix6hdu8hu0.execute-api.eu-west-1.amazonaws.com/prod/subscriptions/${vendorUuid}/stripe/customer-portal`
        }[env];
        const cognitoUserSession = await Auth.currentSession();
        const response = await axios.get(portalAPI, {
          timeout: 4000,
          headers: { "Authorization": `Bearer ${cognitoUserSession?.getIdToken()?.getJwtToken()}` }
        });
        const userPortalUrl = response?.data?.url;
        setOptions(options?.map((option) => option.id === 0 ? {
          ...option,
          onPress: () => window.open(userPortalUrl, "_blank")
        } : option));
      })();
    }
  }, [isLoggedIn]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  if (!isLoggedIn) <Redirect to="/login" />;
  if (isLoggedIn && hasAccess !== null && !hasAccess && !isLoading) return <PurchaseModal />;

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div
                  className="flex grow flex-col gap-y-7 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10 pt-10">
                  <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img
                      className="h-5 md:h-8 2xl:h-14 mx-auto  w-auto"
                      src={logo}
                      alt="logo"
                    />
                    <ProfileCard />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col justify-between gap-y-7">
                      <li>
                        <ul className="-mx-2 space-y-1">
                          {navigation.map(item => (
                            <li key={item.name}>
                              {item?.external ? (
                                <a
                                  href={item.link}
                                  target="_blank"
                                  rel="noreferrer"
                                  className={classNames(
                                    item.current
                                      ? "bg-gray-800 text-white"
                                      : "text-gray-400 hover:text-white hover:bg-gray-800",
                                    "group flex gap-x-3 rounded-md p-2 text-base leading-6 font-semibold"
                                  )}
                                >
                                  <img
                                    src={
                                      item?.current
                                        ? item?.activeIcon
                                        : item?.icon
                                    }
                                    alt="menu icon"
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  />
                                  <div className="flex flex-row items-center">
                                    {item.name}
                                    {item?.secondaryIcon && <img
                                      src={item?.secondaryIcon}
                                      alt="menu icon"
                                      className="h-4 w-3 shrink-0 ml-1"
                                      aria-hidden="true"
                                    />}
                                  </div>
                                </a>
                              ) : (
                                <Link
                                  to={item.link}
                                  className={classNames(
                                    item.current
                                      ? "bg-gray-800 text-white"
                                      : "text-gray-400 hover:text-white hover:bg-gray-800",
                                    "group flex gap-x-3 rounded-md p-2 text-base leading-6 font-semibold"
                                  )}
                                >
                                  <img
                                    src={
                                      item?.current
                                        ? item?.activeIcon
                                        : item?.icon
                                    }
                                    alt="menu icon"
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </Link>
                              )}
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <div className="border-t-[2px] border-white/10 mb-2" />
                        <TimeZoneCard />
                        <ul className=" mt-2 space-y-1">

                          {options?.map(option => (
                            <li key={option.name}>
                              <div
                                onClick={() => option?.onPress()}
                                className={classNames(
                                  option.current
                                    ? "bg-gray-800 text-white"
                                    : "text-gray-400 hover:text-white hover:bg-gray-800 hover:cursor-pointer",
                                  "group flex gap-x-3  rounded-[20px] mb-6 mt-4 text-base leading-6 font-semibold"
                                )}
                              >
                                <img
                                  src={option?.icon}
                                  alt="menu icon"
                                  className="h-4 w-4 shrink-0"
                                  aria-hidden="true"
                                />
                                <span className="truncate text-xs">{option.name}</span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-1 lg:flex lg:w-60 2xl:72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-7 overflow-y-auto sidebar px-6 pt-10">
          <div className="mx-auto sm:w-full sm:max-w-sm ">
            <img
              className=" h-5 md:h-6 2xl:h-12  w-auto mx-auto"
              src={logo}
              alt="logo"
            />

            <ProfileCard />
          </div>

          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col justify-between gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1 ">
                  {navigation.map(item => (
                    <li key={item.name}>
                      {item?.external ? (
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noreferrer"
                          className={classNames(
                            item.current
                              ? "bg-gray-800 text-white"
                              : "text-gray-400 hover:text-white hover:bg-gray-800",
                            "group flex gap-x-6 rounded-[20px] p-4 text-sm 2xl:text-base font-medium leading-6"
                          )}
                        >
                          <img
                            src={item?.current ? item?.activeIcon : item?.icon}
                            alt="menu icon"
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          <div className="flex flex-row items-center">
                            {item.name}
                            {item?.secondaryIcon && <img
                              src={item?.secondaryIcon}
                              alt="menu icon"
                              className="h-4 w-3 shrink-0 ml-1"
                              aria-hidden="true"
                            />}
                          </div>
                        </a>
                      ) : (
                        <Link
                          to={item.link}
                          className={classNames(
                            item.current
                              ? "bg-gray-800 text-white"
                              : "text-gray-400 hover:text-white hover:bg-gray-800",
                            "group flex gap-x-6 rounded-[20px] p-4 text-sm 2xl:text-base font-medium leading-6"
                          )}
                        >
                          <img
                            src={item?.current ? item?.activeIcon : item?.icon}
                            alt="menu icon"
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <div className="border-t-[2px] border-white/10 mb-2" />
                <ul className="mt-2 space-y-1">
                  {options?.filter(({ onPress }) => onPress)?.map(option => (
                    <li key={option.name}>
                      <div
                        onClick={() => option?.onPress()}
                        className={classNames(
                          option.current
                            ? "bg-gray-800 text-white"
                            : "text-gray-400 hover:text-white hover:bg-gray-800 hover:cursor-pointer",
                          "group flex gap-x-2  rounded-[20px] mb-6 mt-4 text-base leading-6 font-semibold"
                        )}
                      >
                        <img
                          src={option?.icon}
                          alt="menu icon"
                          className="h-4 w-4 shrink-0"
                          aria-hidden="true"
                        />
                        <span className="truncate text-xs">{option.name}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-400 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <ChartBarIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      {/* main section */}
      <main className="lg:pl-60 text-white 2xl:container mx-auto">{children}</main>
    </div>
  );
};

export default Sidebar;
