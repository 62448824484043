import { capitalize } from '@material-ui/core';
import React from 'react';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import toTitleCase from '../../utils/toTitleCase.ts';
import DashboardCard from './DashboardCard.tsx';
import { LockClosedIcon } from '@heroicons/react/outline';
import useHabitTracker from 'to-package/app-habit-tracker/hooks/useHabitTracker.js';
import { get } from 'lodash';
import useHabitStreak from 'to-package/app-habit-tracker/hooks/useHabitStreak.ts';
import StreakCard from 'components/monkMode/StreakCard.tsx';
import { Link } from 'react-router-dom';
import { NEGOTIABLE_ICON, NON_NEGOTIABLES } from 'views/pages/monk-mode/start.tsx';

// interface MonkModeTrackerCardProps {
//   monkModeTracker: {
//     completedDays: number;
//     currentStreak: number;
//     longestStreak: number;
//     totalDays: number;
//   };
// }

const DEMO_HABITS = {
  habits: [
    ...NON_NEGOTIABLES.map(({ icon }: any) => ({
      imageUrl: icon,
      complete: true,
    })),
    {
      imageUrl: NEGOTIABLE_ICON,
      complete: false,
    }
  ]
};

const MonkModeTrackerCard = ({ monkModeTracker }: any) => {
  const { active, today, streak, duration, failed, finished } = useHabitStreak();

  return (
    <DashboardCard
      to="/monk-mode"
      title={active ? 'Monk Mode' : null}
      className={`${!active && '!p-0'} overflow-hidden`}
      lgWidth={32}
    >
    <div className="flex flex-row flex-1 relative overflow-hidden">
      <div className={`flex flex-col w-full relative ${!active && 'px-4 lg:px-6 2xl:px-8 pb-4 lg:pb-6 2xl:pb-8 pt-3'}`}>
        <StreakCard
          streak={!active ? 17 : streak}
          duration={!active ? { total: 21, remaining: 4, unit: 'days' } : duration}
        />
        <div className="w-full glassCard mt-4 rounded-[10px] md:rounded-[20px] py-4 px-4 gap-3 xl:gap-4 flex flex-row justify-around ">
          {(active ? today : DEMO_HABITS)?.habits?.map(({ complete, imageUrl }: any, id: number) => (
            <div className={`aspect-square flex-1 border-2 p-2 max-w-[3rem] max-h-12 rounded-full flex justify-center items-center ${!complete && 'opacity-20'}`} key={id}>
              <img src={imageUrl} className={`flex-1 max-w-8 max-h-6 `} />
            </div>
          ))}
        </div>
      </div>

      {!active && (
        <Link to='/monk-mode/start' className='flex flex-col justify-center items-center h-full absolute w-full bg-black/90'>
          <div  className='font-semibold text-center text-xl sm:text-2xl text-[#CBD5E1] leading-[110%] mb-2'>Start Your Monk Mode</div>
          <div className='text-center text-[#CBD5E1] mb-6'>Start your monk mode to track your progress</div>
          <button className={`button h-14 border-[2px] !rounded-[12px] text-[18px] text-white flex justify-center items-center w-3/4`}>
            Get Started
          </button>
        </Link>
      )}
    </div>
  </DashboardCard>
);
}

export default MonkModeTrackerCard;
