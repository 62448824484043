import gql from 'graphql-tag';

export default gql`
    query($vendorUuid: ID!){
        getUserHabitGroups(vendorUuid: $vendorUuid) {
            error
            data {
                name
                habitGroupUuid
                duration
                durationUnits
                status
                habits {
                    habitUuid
                    habitName
                    createdTimestamp
                    updatedTimestamp
                    frequency
                    perDay
                    streak
                    colour
                    status
                    reminderTimes
                    imageUrl
                }
                lastStreak
                completionStatus
                trackHistory {
                    date
                    habitsTracked
                    habitUuids
                }
            }
         
        }
    }
`;
