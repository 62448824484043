import { useHistory } from 'react-router-dom';
import friendIcon from '../../../assets/img/icons/friend-inactive.svg';
import { useContext } from 'react';
import { UserDataContext } from 'providers/UserDataProvider';

const FindAFriendStart = () => {
  const { userProfile, setUserProfile } = useContext(UserDataContext);
  const history = useHistory();
  const handleOnboardingComplete = () => {
    setUserProfile({ 
      ...userProfile, 
      data: {
        ...userProfile?.data,
        fafOnboardComplete: true
      }
    });
    history.push('/find-a-friend');
  }

  return (
    <div className="h-screen flex py-4 px-4 md:py-8 md:px-8 ">
      <div className="p-4 sm:p-0 overflow-hidden h-full w-full justify-center items-center cursor-pointer rounded-[20px] relative">
        <div className="h-full flex justify-center items-center">
          <div className="mx-auto flex w-full md:w-[70vw] lg:w-[40vw] flex-col glassCard rounded-[30px] relative px-4 md:px-14 py-6 backdrop-blur-md">
            <div className="w-[77px] h-[77px] glassCard backdrop-blur-md self-center flex justify-center items-center rounded-[16px] mb-3">
              <img src={friendIcon} className="h-10 w-10" />
            </div>
            <div className="flex flex-col w-full ">
              <h1 className="text-3xl md:text-[41px] text-[#CBD5E1] text-center font-normal">
                Welcome to the Campus
              </h1>
              <p className="text-base text-[#CBD5E180] leading-[150%] text-center my-4">
                At Educate, we believe in having the right people in order to
                attain the success you desire. That’s why our new feature let’s
                you connect with students across the world.
              </p>
            </div>
            <div
              onClick={handleOnboardingComplete}
              className="monkModeButton !border-[#0384FE] flex justify-center items-center h-14 border-[2px] !rounded-[12px] text-[18px] text-white"
            >
              Get started
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FindAFriendStart;
