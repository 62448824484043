import { startCase } from 'lodash';
import React from 'react';

interface StreakCardProps {
    streak: number;
    duration: {
        remaining: number;
        unit: string;
    }
}

const StreakCard = ({streak, duration}: StreakCardProps) => (
    <div className='flex flex-col flex-1 justify-center items-center text-center glassCard w-full rounded-[20px] p-4 !border-4 !border-[#0384FE]'>
        <div className='text-5xl sm:text-7xl font-extrabold text-[#CBD5E1] leading-[60px] sm:leading-[80px]' >
            {streak}
        </div>
        <div  className='font-semibold text-xl sm:text-2xl text-[#CBD5E1] leading-[110%]'>{`${streak > 1 ? 'Days' : 'Day'} Streak`}</div>
        <div className='opacity-50 font-semibold text-xl text-[#42464E]'>{`${duration?.remaining} ${startCase(duration?.unit)}s Left`}</div>
    </div>
)

export default StreakCard;