import React, {  FC } from 'react';
import star from '../../assets/img/icons/gradientStarIcon.svg';
import thumbsDown from '../../assets/img/icons/gradientThumbsDownIcon.svg';
import { XIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

type MonkModeMessageCardType = {
  finished: boolean;
  restart: ()=> void
};

const MonkModeMessageCard: FC<MonkModeMessageCardType> = ({
  finished,
  restart
}) => {
  return (
    <div className="h-screen flex justify-center px-4 items-center">
      <div className="mx-auto flex w-[40vw] flex-col glassCard rounded-[30px] relative px-14 py-6 backdrop-blur-md">
        <div className="w-[77px] h-[77px] glassCard backdrop-blur-md self-center flex justify-center items-center rounded-[16px] mb-2">
          <img src={finished ? star : thumbsDown} className="h-10 w-10" />
        </div>
        <div className="flex flex-col w-full mb-4">
          <h1 className="text-[41px] text-[#CBD5E1] font-normal">
            {finished ? 'Congratulations!' : 'Monk Mode Failed'}
          </h1>
          <>
          {finished ?
            <p className="text-base text-[#CBD5E180] leading-[150%]">
            You just completed your Monk Mode Objective. Have you reached your fullest potential and achieved your biggest goals? Let others know in the <Link to='/community' className="text-base text-[#CBD5E180] leading-[150%] underline">community tab</Link> 
            </p>
            :
            <p className="text-base text-[#CBD5E180] leading-[150%]">
            You weren’t able to complete your monk mode journey. We hope you achieved as much as you could. Let others know your challenges in the <Link to='/community' className="text-base text-[#CBD5E180] leading-[150%] underline">community tab</Link> 
            </p>
            }
          </>
        </div>
          <button
            className="monkModeButton h-14 border-[2px] !rounded-[12px] text-[18px] text-white"
            onClick={restart}
          >
            Start New Monk Mode
          </button>
        <div
          onClick={restart}
          className="h-[40px] w-[40px] rounded-[12px] !bg-[#151920] z-99 flex justify-center items-center absolute top-[-20px] left-[-15px] border-[2px] border-[#FFFFFF08] hover:cursor-pointer glassCard backdrop-blur-md"
        >
          <XIcon
            className="h-6 w-6 text-[#CBD5E1] hover:text-white transition-all"
            aria-hidden="true"
          />
        </div>
      </div>
    </div>
  );
};

export default MonkModeMessageCard;
