import gql from 'graphql-tag';

export default gql`
    query ($vendorUuid: ID!) {
        getUserAccess(vendorUuid: $vendorUuid) {
            data {
                key
                contentId
            }
        }
    }
`;
