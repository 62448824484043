import { useQuery } from "@apollo/react-hooks";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { UserDataContext } from "../../providers/UserDataProvider";
import "./styles.css";
import person from "../../assets/img/icons/person.svg";
import plus from "../../assets/img/icons/plusFull.svg";
import { Auth } from "aws-amplify";
import getTrackedVendorUser from "../../api/getTrackedVendorUser";
import { useConfig } from "@genflow/core";
import useImageUpload from "../../hooks/useImageUpload";
import { CognitoUserContext } from "../../providers/CognitoUserProvider";
import { Link } from "react-router-dom";

const ProfileCard = () => {
  const { vendorUuid } = useConfig();
  const { userProfile } = useContext(UserDataContext);
  const { cognitoUser, updateCognitoAttributes } = useContext(CognitoUserContext);
  const { data: trackedVendorUser } = useQuery(getTrackedVendorUser, {
    variables: { vendorUuid },
    skip: !vendorUuid
  });
  const userId = trackedVendorUser?.getTrackedVendorUser?.data?.userId;

  const email = cognitoUser?.attributes?.email ?? '';

  // Ensure cognito attribute 'picture' is in sync with old method of profileImage user-data field
  useEffect(() => {
    ((async () => {
      if (userProfile?.data?.profileImage) {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        if (!cognitoUser?.attributes?.picture && userProfile?.data?.profileImage !== cognitoUser.attributes?.picture) {
          await updateCognitoAttributes({ "picture": userProfile.data.profileImage });
        }
      }
    }))();
  }, [userProfile?.data?.profileImage]);


  return (
    <div className="mt-6">
      <div className="flex flex-col items-center">
        <Link
          className="cursor-pointer w-[90px] h-[90px] flex justify-center items-center rounded-2xl relative glassCard backdrop-blur-md overflow-hidden !border-[#0384FE]"
          to="/settings"
        >
          {cognitoUser?.attributes?.picture ? (
            <img
              src={cognitoUser?.attributes?.picture}
              alt=""
              className={`w-full h-full bg-[#0C1018]  object-cover object-top opacity-${
                cognitoUser?.attributes?.picture || image ? 1 : 0
              }`}
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center rounded-[20px] relative">
              <img src={person} className=" w-10 h-10" alt="profile picture" />
              <div className="absolute w-full h-full left-0 top-0 flex justify-center items-center">
                <img src={plus} className=" w-3 h-3" alt="profile picture" />
              </div>
            </div>
          )}

        </Link>
        <div className="flex flex-1 flex-col items-center py-0.5 w-full mt-1">
          <div className="flex flex-1 flex-col items-center">
            {!!cognitoUser?.attributes?.given_name && <p className="text-[#CBD5E1] leading-5 text-base font-semibold">{`${cognitoUser?.attributes?.given_name ?? ''} ${cognitoUser?.attributes?.family_name ?? ''}`}</p>}
            {!!email && <p className="text-gray-400 text-xs font-semibold break-all">{email}</p>}
            {!!userId && <p className="text-gray-400 text-xs font-semibold">{`Student ID: ${userId}`}</p>}
          </div>
        </div>

      </div>
    </div>
  );
};

export default ProfileCard;
