import { UserDataContext } from "providers/UserDataProvider";
import { useContext} from "react";

type Goal = {
    locked: boolean; 
    message: string; 
    cta?: string; 
    link?: string;
    externalLink?: boolean
  };
type Goals = Record<string, Goal>;

/**
 * A custom React hook for retrieving course goal lock information.
 *
 * @param ids - An array of course IDs.
 * @returns An object containing locked goal information.
 */

export default (ids: number[], access: boolean) => {
    if(!ids?.length)return[];

    const { userProfile } : {userProfile: any}  = useContext(UserDataContext);

    const goals: Goals = {
        6029: {
            locked: !userProfile?.data?.mm21Completed, 
            message: 'Unlock your true potential by biohacking your way to 7-figures. Get rid of mental fog, restore energy levels, and achieve peak performance through a holistic approach to life.',
            cta: 'Complete 21 days of Monk Mode',
            link: '/monk-mode'
        },
        6749: {
            locked: !access, 
            message: 'Enrollment Closed',
        },
        6172: {
            locked: !access, 
            message: 'To get access to this program, click Get Access. The program will unlock within 24 hours of signing up to GHL via the link above.',
            cta: 'Get Access',
            link: 'https://www.gohighlevel.com/iman-gadzhi?fp_ref=educate',
            externalLink: true,
        },
    }

    const lockedInfo = ids?.map((id) => goals?.[+id] ? {...goals?.[+id], id} : {
        locked: false,
        message: '',
        cta: '',
        link: '',
        id
    }) || []

    return {
        lockedInfo
    }
}