import React, { createContext, useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.js';
import { Moment } from 'moment';
import { UserDataContext } from './UserDataProvider';
import { Auth } from 'aws-amplify';

const TimezoneContext = createContext<TimezoneContextType>({
    timezone: null,
    setTimezone: (a: string) => null,
    localiseTimestamp: (a: string) => ({ time: null, abbr: null }),
});

interface TimezoneContextType {
    timezone: string | null;
    setTimezone: (timezone: string) => void;
    localiseTimestamp: (timestamp: string) => { time: Moment | null, abbr: string | null };
}

const { Provider, Consumer: TimezoneConsumer } = TimezoneContext;

interface TimezoneProviderProps {
    children: React.ReactNode;
}

const getTimezoneWithFallback = (timezoneString: string) => {
    const timezoneStringIsValid = !!moment.tz.zone(timezoneString);
    if (timezoneStringIsValid) {
        return timezoneString;
    } else {
        return moment.tz.guess();
    }
}

const TimezoneProvider = ({ children }: TimezoneProviderProps) => {
    const [timezone, setTimezone] = useState('');

    // Load profile data
    const { userProfile, setUserProfile } : {userProfile: any, setUserProfile: (a: any, b: boolean)=> void} = useContext(UserDataContext);

    // Update cognito attribute
    const updateCognitoAttribute = async (attribute: string, value: string) => {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
            [attribute]: value
        });
    }

    const updateProfileAndCognito = async (fallBackTimezone: string) => {
        setUserProfile({
            ...userProfile,
            data: {
                ...userProfile?.data,
                timezones: fallBackTimezone,
            },
        }, true);

        // Update the cognito attribute
        await updateCognitoAttribute('zoneinfo', fallBackTimezone);
    };

    // If profile timezone doesn't match default timezone, set to profile timezone
    useEffect(() => {
        // If the user has a different saved timezone to the current, update the current timezone
        if (userProfile?.data?.timezones && userProfile?.data?.timezones !== timezone) {
            const timezoneString = userProfile?.data?.timezones;
            const fallBackTimezone = getTimezoneWithFallback(timezoneString);
            setTimezone(fallBackTimezone);
        }
    }, [userProfile]);

    useEffect(()=> {
        const timezoneStringIsValid = !!moment.tz.zone(timezone);
        if (timezoneStringIsValid && timezone !== userProfile?.data?.timezones) {
            (async () => {
                try{
                    await updateProfileAndCognito(timezone);
                }catch(error){
                    console.log('update timezone error:', error)
                }
            })();
        }
    },[timezone]);

    useEffect(()=> {
        // if timezone doesn't exist on user profile & local timezone state
        const timezoneStringIsValid = !!moment.tz.zone(timezone);
        if(!timezoneStringIsValid && !userProfile?.data?.timezones){
            setTimezone(moment.tz.guess())
        }
    },[timezone])

    const localiseTimestamp = (timestamp: string) => {
        if (!timestamp || !timezone) return {
            time: null,
            abbr: null
        }
        const localisedTime = moment(timestamp).tz(timezone);
        return {
            time: localisedTime,
            abbr: localisedTime.format('z')
        };
    }

    // If timezone is set manually, set profile + cognito attribute
    return (
        <Provider
            value={{
                timezone,
                setTimezone,
                localiseTimestamp,
            }}
        >
            {children}
        </Provider>
    );
};

// TODO: Remove timezones.json and use moment.tz.names() instead
// On onboarding, create a list of timezones from moment.tz.names()
// Use a search with autocomplete to select the timezone from the list and save to the user profile

export { TimezoneContext, TimezoneProvider, TimezoneConsumer };
