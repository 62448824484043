import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { ChevronDownIcon, ChevronUpIcon, XIcon } from "@heroicons/react/outline";
import useCompleteLesson from "@genflow/web-courses/hooks/useCompleteLesson";
import { findIndex, get, has, parseInt } from "lodash";
import { UserDataContext } from "providers/UserDataProvider";
import CustomLoader from "../../../components/CustomLoader";
import { trackPageView } from "../../../utils/useAnalytics";
import { OnboardingContext } from "../../../providers/OnboardingProvider";
import Modal from "../../../components/modals/Modal";
import play from "../../../assets/img/play.svg";
import Carousel from "components/carousels/Carousel.tsx";
import upscaleVideo from "utils/upscaleVideo.js";
import moment from "moment";
import PhaseCard from "../../../components/cards/PhaseCard.tsx";
import useContent from "../../../hooks/useContent";

function filterSubsections(sections) {
  if (!sections) return [];

  const filteredSubsections = [];

  sections.forEach((item, i) => {
    if (item.parent_id) {
      const parentIndex = findIndex(sections, (i2) => i2.id == item.parent_id);

      if (has(filteredSubsections, `[${parentIndex}].subSections`)) {
        filteredSubsections[parentIndex].subSections.push(item);
      } else {
        filteredSubsections[parentIndex] = {
          ...filteredSubsections[parentIndex],
          subSections: [item],
        };
      }
    } else {
      filteredSubsections[i] = { ...item };
    }
  });

  return filteredSubsections;
}

export default ({ open, setOpen }) => {
  const productId = open;

  const { data: completedLessons } = useCompleteLesson(productId);

  const [progressPercentage, setProgressPercentage] = useState('60');

  const {data: course, isLoading} = useContent({productId});

  const brandThumbnail = isLoading
    ? false
    : course?.meta_data && JSON.parse(course?.meta_data)?.banner;
  const brandThumbnail2 = isLoading
    ? false
    : course?.meta_data &&
      JSON.parse(course?.meta_data)?.profile?.[0]?.thumbnail;
      const brandSrc = isLoading
      ? false
      // : course?.meta_data && JSON.parse(course?.meta_data)?.productLogo;
      : null


  const sections = filterSubsections(get(course, 'sections', null));

  const nonDownloadable = ['Transcript', 'Video', 'Audio'];
  const transcripts = ['Transcript', 'Audio'];

  const getVideos = (listAssets) => {
    const videoAssets = (assets) =>
      assets.filter((item) => item.type === 'Video');

    const downloadableAssets = (assets) =>
      assets.filter((item) => !nonDownloadable?.includes(item?.type));
    const metadataAsset = (v) => (typeof v === 'string' ? JSON.parse(v) : null);

    const getTranscript = (assets) =>
      assets.filter((item) => transcripts?.includes(item?.type));

    return listAssets.reduce((result, lesson) => {
      const metadata = metadataAsset(videoAssets(lesson.assets)[0]?.meta_data);

      // const availableVideo = metadata?.isLive;
      const availableVideo = true;

      if (availableVideo) {
        result.push({
          id: lesson.id,
          name: lesson.name,
          metadata,
          url: videoAssets(lesson.assets)[0]?.embedUrl,
          thumbnail: videoAssets(lesson.assets)[0]?.thumbnail,
          sproutId: videoAssets(lesson.assets)[0]?.sproutId,
          description: videoAssets(lesson.assets)[0]?.description || '',
          downloads: downloadableAssets(lesson.assets),
          transcript: getTranscript(lesson.assets) || null,
          complete: !!completedLessons.find(
            ({ id: cId }) => parseInt(cId) === parseInt(lesson.id)
          ),
        });
      }

      return result;
    }, []);
  };

  const isSectionAvailable = ({ id, name, lessons: l }) => {
    const videos = getVideos(l);

    const availableSection = !!videos.filter((item) => item.metadata?.isLive)
      .length;

    if (availableSection) {
      return {
        id,
        name,
        videos,
      };
    }
    return false;
  };

  const videoSections = sections.reduce((arraySections, section) => {
    if (section.subSections?.length) {
      const s = section.subSections.reduce((result, item) => {
        if (isSectionAvailable(item)) {
          result.push(isSectionAvailable(item));
        }
        return result;
      }, []);

      arraySections.push({
        id: section.id,
        name: section.name,
        subSections: s,
        videos: [],
      });
    } else {
      const s = isSectionAvailable(section);

      if (s) {
        arraySections.push(s);
      }
    }

    return arraySections;
  }, []);

  const flatVideosList = videoSections.reduce((acc, section) => {
    if (section.subSections) {
      section.subSections.map((item) => {
        acc = [...acc, ...item.videos];
      });
    } else {
      acc = [...acc, ...section.videos];
    }
    return acc;
  }, []);

  useEffect(() => {
    let counter = 0;
    for (let i = 0; i < flatVideosList.length; i++) {
      if (flatVideosList[i].complete === true) counter++;
    }
    setProgressPercentage(Math.round((counter / flatVideosList.length) * 100));
  }, [flatVideosList]);

  const location = useLocation();
  const analytics = useCallback(() => {
    trackPageView(location.pathname, 'Program');
  }, [location.pathname]);

  useEffect(() => {
    analytics();
  }, [analytics]);

  // check if onboarding not completed
  const { userProfile } = useContext(UserDataContext);
  const { setActivePage, setActiveInfoType } = useContext(OnboardingContext);
  useEffect(() => {
    if (
      !userProfile?.data?.tourCompleted?.advanceApply &&
      !!userProfile?.data?.tourCompleted?.firstVideo &&
      userProfile?.data?.onboardingCompleted
    ) {
      setActiveInfoType('programs');
      setActivePage(1);
    }
  }, [userProfile]);


  const [showDetails, setShowDetails] = useState(true);
  const DetailsIcon = showDetails ? ChevronUpIcon : ChevronDownIcon;

  const initialPhaseId = sections?.[0]?.id
  const [phaseId, setPhaseId] = useState(null);
  const selectedCourse = useMemo(
    () => sections?.find((item) => item?.id === phaseId) || sections?.[0],
    [sections, phaseId]
  );

  const courseVideos = useMemo(() => {
    if (selectedCourse?.lessons?.length) {
      return getVideos(selectedCourse?.lessons);
    }
    return [];
  }, [selectedCourse]);

  // const mockVideo =
  //   'https://videos.sproutvideo.com/embed/a79ddab61511efcb2e/1fa289b64598071c';

  const [playVideo, setPlayVideo] = useState(false);

  const userProgress = userProfile?.data?.progress;
  const courseProgress = userProgress?.[productId];

  const courseVideo = useMemo(() => {
    const videoUrl = course?.meta_data && JSON.parse(course?.meta_data)?.videoUrl;
    if (videoUrl) {
      return upscaleVideo(videoUrl);
    }
    return null;
  }, [course]);

  useEffect(()=> {
    if(!open && playVideo){
      setPlayVideo(false)
    }
  },[open])

  const filteredSections = sections?.filter((section) => section?.lessons?.length)?.map((section) => {
    const releaseDate = section?.meta_data && JSON.parse(section?.meta_data)?.releaseDate;
    const now = moment();
    return{
      ...section,
      releaseDate: releaseDate,
      locked: now.isBefore(moment(releaseDate))
    }
  });

  return (
    <Modal open={!!open} setOpen={setOpen}>
      {
        isLoading ? <CustomLoader loading={isLoading} />
        :
        <div className="flex justify-center px-2 md:px-4 py-2 md:py-4 items-center">
          <div className="mx-auto max-w-[90vw] md:max-w-[80vw] lg:max-w-[70vw]
          glassCard backdrop-blur-md rounded-[30px] text-[#CBD5E1] relative" style={{
            background: 'linear-gradient(123.54deg, rgba(27, 31, 39, 1) 0.7%, rgba(255, 255, 255, .03) 99.09%)'
          }}>
            <div
              onClick={() => setOpen(false)}
              className="h-[30px] w-[30px] md:h-[40px] md:w-[40px] rounded-[8px] md:rounded-[12px] bg-[#17191C] flex justify-center items-center absolute md:top-[-16px] top-[-10px] left-[-5px] md:left-[-16px] border-[2px] border-[#FFFFFF08] hover:cursor-pointer"
            >
              <XIcon
                className="h-5 w-5 md:h-6 mdw-6 text-[#CBD5E1] hover:text-white transition-all"
                aria-hidden="true"
              />
            </div>

            <div className="flex flex-col w-full  py-4 md:py-6 h-full">
              <div className="px-4 md:px-6 w-full overflow-hidden flex-1 mb-4">
                <div className="w-full rounded-[20px] h-full glassCard backdrop-blur-md overflow-y-auto aspect-video">
                  <div
                    className={`relative w-full h-[${
                      showDetails ? '70%' : '100%'
                    }] lg:!h-[${
                      showDetails ? '85%' : '100%'
                    }] rounded-[18px] overflow-hidden transition-all duration-500 ease`}
                  >
                    {playVideo ? (
                      <div
                        className="h-full w-full rounded-[20px] overflow-hidden"
                      style={{ background: '#101010' }}
                      >
                        {playVideo && (
                          <iframe
                            id="videoPlayer"
                            title="video"
                            className="aspect-video"
                            src={`${courseVideo}?cc=eng&autoPlay=${true}`}
                            style={{ background: 'transparent', border: 0, padding: 0, margin: 0 }}
                            allowFullScreen
                            width="100%"
                            height="100%"
                          />
                        )}
                      </div>
                    ) : (
                      (brandThumbnail || brandThumbnail2) && <img
                        src={brandThumbnail || brandThumbnail2 }
                        alt="course image"
                        className="object-cover object-top w-full h-full"
                      />
                    )}

                  {/* {!!brandSrc && <div className="absolute z-9999 bottom-8 w-full flex flex-row justify-between items-center px-8">
                      <img
                        src={brandSrc}
                        alt="course logo"
                        className="object-contain object-center md:h-[30px]  h-[20px]"
                      />
                    </div>} */}

                    {!playVideo && !!courseVideo && (
                      <div className="absolute z-9999 w-full h-full top-0 flex justify-center items-center">
                        <div
                          className="border-[3px] border-white rounded-full w-10 h-10 md:w-20 md:h-20 flex justify-center items-center glassCardBorderWhite hover:cursor-pointer"
                          onClick={() => setPlayVideo(!playVideo)}
                        >
                          <img
                            src={play}
                            alt="play video"
                            className="h-4 w-4 md:h-8 md:w-8 ml-1 md:ml-2"
                          />
                        </div>
                      </div>
                    )}

                  </div>

                  <div className="px-4 my-4 flex flex-col justify-between">
                    <div
                        dangerouslySetInnerHTML={{ __html: course?.description}}
                        className="text-left text-[#CBD5E1] text-sm md:text-base"
                        />
                    {/* <div className="flex flex-row">
                      {item?.pills?.map((pill) => (
                        <div className="glassCard py-[8px] px-[18px] rounded-[12px] mr-2">
                          <p className="text-[#CBD5E1] text-[12px]">{pill}</p>
                        </div>
                      ))}
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="w-full mx-4 md:mx-6">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row">
                  <div>
                      <p className='text-white text-lg'>Phases:</p>
                    </div>
                    {/* <div className="flex glassCard rounded-[12px] py-2 px-3 mr-2">
                    <div className="flex items-center glassCard rounded-[10px] mr-4 px-4 self-start md:self-center pb-[0.4rem] pt-[0.2rem] mb-2 md:mb-0 ">
                    <div>
                      <p>{`Module: ${course?.name}`}</p>
                    </div>

                    </div> */}
                    {/* {!!courseProgress?.video && (
                      <Link
                        to={{
                          pathname: `/course/${productID}/phase/${courseProgress?.lesson}/lessonID/${courseProgress?.video}`,
                          state: { brandSrc, productID },
                        }}
                        className="flex flex-row glassCard rounded-[12px] py-2 px-3 justify-center items-center grow-0"
                      >
                        <p className="mr-2 text-sm leading-[100%] font-normal">
                          Resume Watching
                        </p>
                        <PlayIcon alt="phase" className="text-white h-4 w-4" />
                      </Link>
                    )} */}
                  </div>

                </div>
              </div>

              {(filteredSections?.length > 0) && (
                <div className="w-full pt-2 pb-4 overflow-hidden relative">
                  <Carousel
                    content={filteredSections}
                    render={(item, isSnapPoint, index) => {
                      return item?.locked ? (
                        <div
                          className={`pl-4 md:pl-6 ${isSnapPoint && 'snap-start'} ${index === filteredSections?.length - 1 && `pr-4 md:pr-6`}`}
                        >
                          <PhaseCard
                            image={sections?.[index]?.lessons?.[0]?.assets?.find((asset)=> asset?.type === 'Video')?.thumbnail || brandThumbnail || brandThumbnail2}
                            phaseNumber=  {index < 10 ? `${index + 1}` : index + 1}
                            name={item?.name}
                            locked={item?.locked}
                            startTime={item.releaseDate}
                           />
                        </div>
                        ) : (
                        <Link
                          key={index}
                          to={{
                            pathname: `/course/${productId}/phase/${item?.id}`,
                            state: { item, brandSrc, productId },
                          }}
                          className={`pl-4 md:pl-6 ${isSnapPoint && 'snap-start'} ${index === filteredSections?.length - 1 && `pr-4 md:pr-6`} cursor-pointer`}
                        >
                           <PhaseCard
                            image={sections?.[index]?.lessons?.[0]?.assets?.find((asset)=> asset?.type === 'Video')?.thumbnail || brandThumbnail || brandThumbnail2}
                            phaseNumber=  {index < 10 ? `${index + 1}` : index + 1}
                            name={item?.name}
                            locked={item?.locked}
                            startTime={item.releaseDate}
                           />
                        </Link>
                      )
                    }}
                    />
                </div>
              )}
            </div>
          </div>
        </div>
         }
    </Modal>
  );
};

          // h-[70vh] md:h-[85vh]
          // w-[98vw] md:w-[67vw]
