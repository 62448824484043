import gql from 'graphql-tag';

export default gql`
    query ($vendorUuid: ID!){
        getTrackedVendorUser(vendorUuid: $vendorUuid) {
            error
            data {
                userId
            }
        }
    }
`;
