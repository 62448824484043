import React, { useEffect, useState } from "react";
import useHabitTracker from "../../../to-package/app-habit-tracker/hooks/useHabitTracker";
import { useHistory } from "react-router";
import { chunk, get, range, startCase } from "lodash";
import ReactGA from 'react-ga4';
import moment from "moment";
import lockIcon from '../../../assets/img/icons/lockIcon.svg'
import playIcon from '../../../assets/img/icons/playIcon.svg'
import MonkModeChallenges from "./components/MonkModeChallenges";
import HabitCard from "components/monkMode/HabitCard";
import StreakCard from "components/monkMode/StreakCard";
import useHabitStreak from "to-package/app-habit-tracker/hooks/useHabitStreak";
import Modal from "components/modals/Modal";
import MonkModeMessageCard from "components/cards/MonkModeMessageCard";
import { XIcon } from "@heroicons/react/solid";
import { trackEvent } from "utils/useAnalytics";

const MonkMode = () => {
  const history = useHistory();
  const { trackHabit, loading, disableAllHabitGroups } = useHabitTracker();

  const { duration, streak, active, today, failed, finished, restart, todayMoment, setFailed, setFinished } = useHabitStreak();

  useEffect(() => {
      // assuming habits is an array here
      // redirect to start page if no habits
      if (!loading && !active) {
          console.log('Redirect to start')
          history.push('/monk-mode/start');
      }
  }, [active, loading]);

  const onClickTrackHabit = async (habitUuid: string, habitName: string) => {
    const response = await trackHabit(habitUuid, {});
    ReactGA.event('Habit_Tracked', {
      habit_name: habitName,
      mm_name: `${duration?.total} Day Monk Mode`,
  });
  }

    const mileStones = [
      {
        title: '7 Days Complete',
        days: 7,
        videoUrl: '',
      },
      {
        title: '14 Days Complete',
        days: 14,
        videoUrl: '',
      },
      {
        title: '21 Days Complete',
        days: 21,
        videoUrl: '',
      },
    ];

    const [failedFinishedModal, setFailedFinishedModal] = useState(false);

    useEffect(() => {
      if (finished || failed) {
        setFailedFinishedModal(true);
      }

      if(finished){
        trackEvent({
          action: 'MM_Started',
          category: 'MM_Name',
          label: `${duration?.total} Day Monk Mode`,
        });
      }
    }, [finished, failed]);

    const restartMonkMode = () => {
      setFailedFinishedModal(false);
      restart();
    }

    const [videoModal, setVideoModal] = useState<string>('')

    return (
        <div className='flex flex-col h-screen'>
            <div className='flex flex-col lg:flex-row items-start glassCard rounded-[30px] p-2 sm:p-4 m-4 2xl:p-8 lg:m-8'>
               <div className='sm:h-full flex flex-col sm:flex-row lg:flex-col items-center gap-4 glassCard p-4 2xl:p-8 rounded-[20px] w-full lg:w-auto'>
                <StreakCard streak={streak} duration={duration} />
                 <div className='flex-1 flex flex-col gap-4 glassCard backdrop-blur-md rounded-[20px] p-4 h-full lg:h-auto items-center justify-around w-full sm:w-auto'>
                   {chunk(range(1, (duration?.total || 0) + 1), 7).map(((weekDays, id) =>
                   (
                      <div className='flex gap-x-3 gap-y-2 2xl:gap-x-6 2xl:gap-y-4' key={id}>
                        {weekDays.map((day, id) => (
                          <div className='relative' key={id}>
                            <div className={`w-6 h-6 2xl:w-8 2xl:h-8 rounded-full border-2 flex items-center justify-center ${day <= streak ? 'border-[#CBD5E1]' : 'border-[#42464E]'}`}>
                              <p className={`text-sm ${day <= streak ? 'text-[#CBD5E1]' : 'text-[#42464E]'} font-extrabold`}>{day}</p>
                              </div>
                            {
                             day === streak + 1 && streak !== duration?.total
                              && <div className='bg-gray-100 h-1 w-2 absolute -bottom-2 left-2/4 -translate-x-2/4 rounded-full'></div>
                            }
                          </div>
                        ))}
                      </div>
                   )
                   ))}
                 </div>
               </div>


               <div  className='flex flex-col flex-1 glassCard rounded-[20px] h-full p-4 2xl:p-8 lg:ml-4 2xl:ml-10 mt-4 lg:mt-0 w-full lg:w-auto'>
                <div className='font-medium text-xl sm:text-2xl mb-2 2xl:mb-5 text-[#CBD5E1] leading-[100%]'>{todayMoment?.format('MMMM Do')}</div>
                 {
                    active && today?.habits?.map(({habitUuid, habitName, imageUrl, complete})=> {
                      return (
                        <HabitCard
                          tracked={complete}
                          name={habitName}
                          onClick={() => onClickTrackHabit(habitUuid,habitName )}
                          icon={imageUrl}
                          key={habitUuid}
                        />
                      )
                    })
                 }
               </div>
            </div>

          <div className='flex flex-col sm:flex-row gap-4 mb-4 mx-4 2xl:mb-8 lg:mx-8'>
            <MonkModeChallenges />
            <div className="w-full sm:w-2/5 glassCard backdrop-blur-md px-4 2xl:px-8 pb-4 pt-3 h-full mb-2 rounded-[30px] ">
              <div className='text-lg mb-3'>Milestones</div>
              <div className='grid grid-cols-2 grid-rows-2 gap-4'>
                {mileStones?.map((mileStoneDays, id)=>{
                  const completed = mileStoneDays?.days <= streak
                  return(
                  <div
                  key={id}
                  onClick={()=> completed && setVideoModal(mileStoneDays?.videoUrl)}
                  className={`${completed ? 'bg-gradient-to-r from-[#0384FE] to-[#03E5B4]  p-[1px] hover:cursor-pointer' : 'glassCard'} rounded-[12px] `}
                  >
                    <div className="flex flex-col px-3 pt-2 pb-4 !bg-[#13171f] rounded-[12px] relative h-full">
                    <p className="text-base text-[#CBD5E1]">{mileStoneDays?.title}</p>
                    <p className="text-xs text-[#64748B]">Milestone</p>
                    <div className="flex justify-center items-center absolute bottom-2 right-2 w-7 h-7 ">
                      {
                        completed ?
                        <img src={playIcon} className="h-full w-full"/>
                        :
                        <div className="flex justify-center items-center w-full h-full rounded-full border-2 border-[#42464E] ">
                          <img src={lockIcon} className="h-3 w-3"/>
                      </div>
                      }
                    </div>
                  </div>
                  </div>
                )}
                )}
              </div>
            </div>
          </div>


            <Modal
              open={failedFinishedModal}
              setOpen={restartMonkMode}
              backgroundColor='rgba(12, 16, 24, 0.60)'
            >
               <MonkModeMessageCard
                  finished={finished}
                  restart={restartMonkMode}
                />
            </Modal>

            <Modal
              open={!!videoModal}
              setOpen={()=> setVideoModal('')}
              opacity={70}
            >
            <div className="py-3 px-3 flex justify-center items-center">
              <div className="p-4 lg:p-6 overflow-y-auto rounded-[20px] md:rounded-[30px] glassCard backdrop-blur-md relative flex-col">
                <iframe
                  src={videoModal}
                  allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                  className='h-full w-full rounded-lg flex-1 sm:min-w-[80vw] sm:aspect-video lg:min-w-[60vw] 2xl:min-w-[50vw]'></iframe>
              </div>
              <div
                onClick={() => setVideoModal('')}
                className="h-[40px] w-[40px] rounded-[12px] bg-[#17191C] z-99 flex justify-center items-center absolute top-0 left-0 border-[2px] border-[#FFFFFF08] hover:cursor-pointer glassCard backdrop-blur-md"
              >
                <XIcon
                  className="h-6 w-6 text-[#CBD5E1] hover:text-white transition-all"
                  aria-hidden="true"
                />
              </div>
            </div>
            </Modal>
        </div>
    );
};

export default MonkMode;
