import React, { useEffect, useMemo } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import CustomLoader from "components/CustomLoader";
import moment from "moment";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { nonDownloadable } from "constants/content";
import useContent from "../../../../hooks/useContent";

const ResourcesPhases = () => {
  const history = useHistory();
  const { courseId } = useParams();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  const {data: course, isLoading} = useContent({productId: courseId});

  const now = useMemo(() => moment(), []);

  // filter for released phases
  const filteredSections = useMemo(() => {
    if (!course?.sections?.length) {
      return [];
    }

    // return released phases with at least 1 downloadable asset
    const downloadablePhases = course?.sections?.filter((section) =>
      section?.lessons?.some((lesson) =>
        lesson?.assets?.some((asset) => !nonDownloadable?.includes(asset?.type))
      )
    );

    const filteredItems = downloadablePhases?.map((section) => {
      const releaseDate =
        section?.meta_data && JSON.parse(section?.meta_data)?.releaseDate;
      return {
        ...section,
        releaseDate: releaseDate,
        locked: !!releaseDate ? now.isBefore(moment(releaseDate)) : false,
      };
    });
    return filteredItems.filter((item) => !item?.locked);
  }, [course]);

  const metaData = course?.meta_data && JSON.parse(course?.meta_data);

  return (
    <>
      <CustomLoader loading={isLoading}>
        <div className="min-h-[90vh] md:min-h-screen flex p-4 lg:p-8">
          <div className="glassCard !border-[1px] w-full rounded-[20px] md:rounded-[30px] px-4 md:px-10 py-8 relative">
            <div
              onClick={() => history.goBack()}
              className="h-[30px] w-[30px] md:h-[40px] md:w-[40px] rounded-[8px] md:rounded-[12px] bg-[#17191C] flex justify-center items-center absolute md:top-[-16px] top-[-10px] left-[-5px] md:left-[-16px] border-[2px] border-[#FFFFFF08] hover:cursor-pointer"
            >
              <ArrowLeftIcon
                className="h-5 w-5 md:h-6 md:w-6 text-[#CBD5E1] hover:text-white transition-all"
                aria-hidden="true"
              />
            </div>
            <div>
              <div className="w-full aspect-[2.77] md:aspect-[4.77] mb-5 rounded-[16px] md:rounded-[20px] flex justify-center items-center glassCard !border-[1px] hover:cursor-pointer overflow-hidden">
                {metaData?.bannerImage ? (
                  <img
                    src={metaData?.bannerImage}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  // <img src={metaData?.productLogo} className="h-[50%] w-auto" />
                  <></>
                )}
              </div>
            </div>

            <div className="w-full grid grid-cols-2 gap-5">
              {filteredSections?.map((item, index) => (
                <Link
                  key={item?.id}
                  to={{
                    pathname: `/resources/phases/${courseId}/modules/${item?.id}`,
                  }}
                  className="glassCard !border-[1px] rounded-[16px] md:rounded-[20px] p-5 relative"
                >
                  <div>
                    <p className="text-base md:text-xl text-[#CBD5E1] font-bold">
                      {item?.name}
                    </p>
                    <p className="text-[#CBD5E14D] font-semibold text-sm md:text-lg">
                      Phase {index + 1}
                    </p>
                  </div>

                  <div className="absolute h-full right-0 top-0 flex items-center justify-center">
                    <p className="text-[#CBD5E11F] text-9xl font-extrabold leading-[100%]">
                      {index + 1}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </CustomLoader>
    </>
  );
};

export default ResourcesPhases;
