import React from 'react';
import { Button, Spinner } from 'reactstrap';
import classnames from 'classnames';

export default ({
    children, loading, disabled, onClick, ...rest
}) => (
    <Button {...rest} disabled={loading || disabled} onClick={!loading && onClick}>
        {
            loading
            && (
                <div
                    className="inline-block text-[#64748B] h-5 w-5 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                />
            )
        }
        {!(loading) && (
            <span
                className={classnames({
                    invisible: loading,
                    visible: !loading,
                    'span-style': false,
                })}
            >
                {children}
            </span>
        )}
    </Button>
);
