import React, { useContext, useEffect, useState } from 'react';
import { StopIcon, XCircleIcon } from '@heroicons/react/outline';
import { getIsDiscountedGYAUser, selectSubscription } from 'utils/stripe';
// import { useLogout } from '@genflow/web-auth';
import Loader from 'components/Loader';
// import { UserDataContext } from '../../providers/UserDataProvider';
// import { useHistory } from 'react-router-dom';
import { getUser } from 'utils/auth';
import useStickyLogout from 'hooks/useStickyLogout';
import { PLAN_PARAM } from "../../views/pages/auth/Register";
import getLocalStorageWithExpiry from "../../utils/getLocalStorageWithExpiry";

const useHasDiscount = () => {
    const [hasDiscount, setHasDiscount] = useState(false);
    useEffect(() => {
        (async () => {
            const cognitoUser = await getUser();
            const cognitoEmail = cognitoUser?.attributes?.email;
            const userDiscounts = await getIsDiscountedGYAUser(cognitoEmail);
            const isDiscounted = userDiscounts?.data?.isDiscounted
            setHasDiscount(isDiscounted);
        })();
    }, [])

    return hasDiscount;
}

const PurchaseModal = ({}) => {
    const [loading, setLoading] = useState(false);
    const onClickLogout = useStickyLogout();

    return (
        <div className="h-screen flex justify-center px-4 items-center">
            <div className="mx-auto flex w-[90vw] lg:w-[40vw] flex-col glassCard rounded-[30px] relative px-10 py-6 backdrop-blur-md text-center">
                <div className='w-[77px] h-[77px] glassCard backdrop-blur-md self-center flex justify-center items-center rounded-[16px] mb-3'>
                    <XCircleIcon className='h-10 w-10 text-[#CBD5E1]' />
                </div>
                <div className="flex flex-col w-full h-full mb-12">
                    <h1 className='text-[41px] text-[#CBD5E1] font-normal mb-4'>Purchase Membership</h1>
                    <p className='text-[16px] text-[#CBD5E180] leading-[150%]'>{
                        'You don\'t have access to Educate yet on this account. Please purchase a membership to continue.'
                    }</p>
                </div>
                <button disabled={loading} className='button h-14 border-[2px] !rounded-[12px] text-[18px] text-white flex justify-center items-center'
                    onClick={()=> {
                        setLoading(true);
                        const plan = getLocalStorageWithExpiry(PLAN_PARAM);
                        selectSubscription(plan)
                    }}
                >
                    {loading && (<Loader />)}
                    {!loading && 'Purchase Membership'}
                </button>

                <p onClick={onClickLogout} className='text-sm text-[#CBD5E180] mt-6 cursor-pointer'>Log Out</p>
            </div>
        </div>
    )
}

export default PurchaseModal;
