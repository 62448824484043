import React, { createContext, useEffect, useState } from 'react';
import useStickyState from '../utils/useStickyState';
import upsertUserProfileData from '../api/upsertUserProfileData';
import { useMutation } from '@apollo/react-hooks';

const USD1 = '$';
const GBP1 = '£';
const EUR1 = '€';
const USD = '$ USD';
const GBP = '£ GBP';
const EUR = '€ EUR';
const allCurrencies = [USD, GBP, EUR];
const getCurrencyLabel = currencySymbol => ({
    [USD]: 'USD',
    [GBP]: 'GBP',
    [EUR]: 'EUR',
}[currencySymbol]);

const getCurrencyLabel1 = currencySymbol => ({
    [USD1]: 'USD',
    [GBP1]: 'GBP',
    [EUR1]: 'EUR',
}[currencySymbol]);

export { allCurrencies };
const UserDataContext = createContext({
    userProfile: {
        data: {
            timezones: null,
            fafOnboardComplete: false,
        },
    },
    setUserProfile: (data) => data,
    userToken: ''
});
const { Provider, Consumer: UserDataConsumer } = UserDataContext;

const UserDataProvider = ({ children, config }) => {
    const [store, setStore] = useState({ ...config, update: null });
    const [userCurrency, setUserCurrency] = useStickyState(USD.charAt(0), 'userCurrency');
    const [userProfileData, setUserProfileData] = useState(null);
    const [userProfile, setUserProfile] = useStickyState({}, 'userProfile');
    const [userToken, setUserToken] = useState(null);
    const [showAccessModal, setShowAccessModal] = useState(false);
    const [upsertProfileData] = useMutation(upsertUserProfileData, {});

    useEffect(() => {
    // console.log('store here', store);
    }, [store]);

    // Updater
    const update = (key, data) => {
    // console.log('Update called, updating store: ', key, data);
        setStore(prevState => ({
            ...prevState,
            [key]: data,
        }));
    };

    // When config changes, re-initilise
    useEffect(() => {
        setStore({ ...config, update });
    // console.log('Configured store');
    }, [config]);

    const setAndSaveUserProfile = (data, save = false) => {
        console.log('got new profile data: ', data);
        console.log('previous data: ', userProfile);
        setUserProfile(data);
        if (save) {
            console.log('To save: ', data);
            upsertProfileData({
                variables: {
                    data: JSON.stringify(data.data),
                }
            });
        }
    };


    return (
        <Provider
            value={{
                ...store,
                userCurrency,
                setUserCurrency,
                allCurrencies,
                getCurrencyLabel,
                getCurrencyLabel1,
                userProfileData,
                setUserProfileData,
                setUserProfile: setAndSaveUserProfile,
                userProfile,
                setUserToken,
                userToken,
                showAccessModal,
                setShowAccessModal,
            }}
        >
            {children}
        </Provider>
    );
};

export { UserDataContext, UserDataProvider, UserDataConsumer };
