import React, { useContext, useEffect, useState } from "react";

// reactstrap components
import { Col, Row } from "reactstrap";

// core components
import "react-responsive-modal/styles.css";
import moment from "moment";
import { useConfig } from "@genflow/core";
import { useQuery } from "@apollo/react-hooks";
import { orderBy } from "lodash";
import { PencilIcon } from "@heroicons/react/outline";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Footer from "../../../components/Footer";
import CardHeader from "./components/CardHeader";
import ClientItem from "./components/ClientItem";
import NewClientModal from "./components/modals/NewClientModal";
import GetClientCustomersQuery from "../../../api/getClientCustomers";
import ClientPayments from "./components/ClientPayments";
import { UserDataContext } from "../../../providers/UserDataProvider";
import CustomLoader from "../../../components/CustomLoader";
import "./style.scss";
import { OnboardingContext } from "../../../providers/OnboardingProvider";
import UserProfileModal from "./components/modals/UserProfileModal";

export default () => {
    const [selectedClient, setSelectedClient] = useState(null);

    const [client, setClient] = useState(null);
    const [openNewClient, setOpenNewClient] = useState(false);
    const onCloseNewClient = () => setOpenNewClient(false);

    const [openNewPayment, setOpenNewPayment] = useState(false);
    const onCloseNewPayment = () => setOpenNewPayment(false);

    const [openFirstPaymentSuccess, setOpenFirstPaymentSuccess] = useState(false);
    const onCloseFirstPaymentSuccess = () => setOpenFirstPaymentSuccess(false);

    const { vendorUuid } = useConfig();
    const {
        data,
        // error: toolError,
        loading,
    // refetch,
    } = useQuery(GetClientCustomersQuery, {
        variables: {
            vendorUuid,
        },
    });

    const clients = orderBy(
    data?.getClientCustomers?.data || [],
    ['createdTimestamp'],
    ['desc'],
    );

    useEffect(() => {
        if (!clients?.length) {
            setSelectedClient(null);
            return;
        }
        if (clients.length > 0 && !selectedClient) {
            setSelectedClient(clients?.[0]);
            return;
        }
        if (clients?.length && !clients?.find(({ id }) => id === selectedClient?.id)) {
            setSelectedClient(clients?.[0]);
        }
    }, [clients, loading]);


    const {
        setActiveInfoType, showItem, setShowItem, setActivePage, activePage,
    } = useContext(OnboardingContext);

    useEffect(() => {
        if (showItem === 'addClient') {
            setOpenNewClient(true);
            setShowItem(null);
        }
    }, [showItem]);

    // check if onboarding not completed
    const { userProfile } = useContext(UserDataContext);
    useEffect(() => {
        if (!userProfile?.data?.tourCompleted?.addClient && userProfile?.data?.onboardingCompleted && activePage < 1) {
            setActiveInfoType('profile');
            setActivePage(0);
        }
    }, [userProfile]);

    const [showUserProfileModal, setShowUserProfileModal] = useState(false);

    return (
        <CustomLoader loading={loading}>
            <main className="profile-clients-page w-10/12 text-white mx-auto min-h-[60vh]">
                <div className="flex row center my-8">
                    <div>
                        <h2 className=" hidden sm:block">
                            My Profile
                            <InfoOutlinedIcon className="white500 ml-2 cursor-pointer" onClick={() => setActiveInfoType('profile')} />
                        </h2>
                        <h5 className="block sm:hidden">
                            My Profile
                            <InfoOutlinedIcon className="white500 ml-2 cursor-pointer" onClick={() => setActiveInfoType('profile')} />
                        </h5>
                    </div>

                    <div className="flex items-center hover:cursor-pointer" onClick={() => setShowUserProfileModal(true)}>
                        <PencilIcon className="h-4 w-4 ml-2 mt-1" />
                    </div>
                </div>

                <div>
                    <Row className="flex flex-col sm:flex-row">
                        <Col className="w-full sm:w-1/3 mr-0 sm:mr-8">
                            <div
                                className="shadow clientBox"
                                style={{
                                    overflowY: 'scroll',
                                }}
                            >
                                <CardHeader
                                    LeftComponent={(
                                        <>
                                            <h3
                                                className="hidden sm:block"
                                            >
                                                Clients
                                            </h3>
                                            <h5
                                                className="block sm:hidden"
                                            >
                                                Clients
                                            </h5>
                                        </>
                                    )}
                                    RightComponent={(
                                        <div

                                            className="blkButton h-fit w-24 sm:w-auto py-2 sm:py-1 body5 px-2 cursor-pointer"
                                            onClick={() => setOpenNewClient(true)}
                                        >
                                            + &nbsp; Client
                                        </div>
                                    )}
                                />

                                {clients.map(client => (
                                    <ClientItem
                                        client={client}
                                        selected={client?.id === selectedClient?.id}
                                        onClick={() => setSelectedClient(client)}
                                        onEditClick={() => {
                                            setOpenNewClient(true);
                                            setClient(client);
                                        }}
                                    />
                                ))}
                            </div>
                        </Col>
                        {selectedClient && (
                            <Col className="w-full sm:w-2/3">
                                <div
                                    className="shadow grey800Bg rounded-lg"
                                >
                                    <CardHeader
                                        LeftComponent={(
                                            <div>
                                                <h3
                                                    className="mb-8 hidden sm:block"
                                                >
                                                    {selectedClient?.clientCustomerName}
                                                </h3>
                                                <h5
                                                    className="mb-8 block sm:hidden"
                                                >
                                                    {selectedClient?.clientCustomerName}
                                                </h5>

                                                {selectedClient?.niche && (
                                                    <>
                                                        <div
                                                            className="whiteBtnNoFlex text-black body2 w-fit px-6 py-2 mb-8  cursor-pointer hidden sm:flex"
                                                        >
                                                            {selectedClient?.niche}
                                                        </div>
                                                        <div
                                                            className="whiteBtnNoFlex text-black body5 w-fit px-6 py-2 mb-8  cursor-pointer flex sm:hidden"
                                                        >
                                                            {selectedClient?.niche}
                                                        </div>
                                                    </>
                                                )}

                                                <div
                                                    className="body2 grey500 hidden sm:block"
                                                    style={{
                                                        marginTop: '1rem',
                                                    }}
                                                >
                                                    {`Signed ${moment(selectedClient?.signedDate).format(
                                                        'dddd, MMMM Do YYYY',
                                                    )}`}
                                                </div>
                                                <div
                                                    className="body5 grey500 block sm:hidden"
                                                    style={{
                                                        marginTop: '1rem',
                                                    }}
                                                >
                                                    {`Signed ${moment(selectedClient?.signedDate).format(
                                                        'dddd, MMMM Do YYYY',
                                                    )}`}
                                                </div>
                                            </div>
                                        )}
                                        RightComponent={!!selectedClient && (
                                            <div
                                                className="mb-auto flex"
                                            >
                                                <div
                                                    className="blkButton h-fit w-24 sm:w-auto py-2 sm:py-1 body5 px-2 cursor-pointer"
                                                    onClick={() => setOpenNewPayment(true)}
                                                >
                                                    + &nbsp; Payment
                                                </div>
                                            </div>
                                        )}
                                    />

                                    <ClientPayments
                                        selectedClient={selectedClient}
                                        openNewPayment={openNewPayment}
                                        onCloseNewPayment={onCloseNewPayment}
                                        openFirstPaymentSuccess={openFirstPaymentSuccess}
                                        onCloseFirstPaymentSuccess={onCloseFirstPaymentSuccess}
                                        setOpenFirstPaymentSuccess={setOpenFirstPaymentSuccess}
                                    />
                                </div>
                            </Col>
                        )}
                    </Row>
                </div>
            </main>

            <NewClientModal open={openNewClient} onClose={onCloseNewClient} client={client} setClient={setClient} />
            <UserProfileModal open={showUserProfileModal} setShowUserProfileModal={setShowUserProfileModal} />

            <Footer />
        </CustomLoader>
    );
};
