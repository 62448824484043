import { get, set } from "lodash";
import moment from "moment-timezone";
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import useHabitTracker from "to-package/app-habit-tracker/hooks/useHabitTracker";
import { CognitoUserContext } from "../../../providers/CognitoUserProvider.tsx";
import getCognitoUserAttribute from "../../../utils/getCognitoUserAttribute.js";

interface HabitStreakResponse {
    active: boolean;
    streak: number;
    duration: {
        total: number;
        remaining: number;
        unit: string;
    };
    today: {
        completed: number;
        habits: any[];
    },
    failed: boolean;
    finished: boolean;
    restart: () => void;
    setFailed: Dispatch<SetStateAction<boolean>> // for demo
    setFinished: Dispatch<SetStateAction<boolean>>// for demo
    todayMoment: any
}

const useHabitStreak = (): HabitStreakResponse => {
    const { habitGroups, disableAllHabitGroups, trackHabit, loading, updatingGroup } = useHabitTracker();
    const activeGroup = habitGroups?.[0]as HabitGroup;
    const inProgress = activeGroup?.duration > 0;
    const [todayStreakData, setTodayStreakData] = useState({ habitUuids: []} as any);
    const [failed, setFailed] = useState(false);
    const [finished, setFinished] = useState(false);
    const { cognitoUser, updateCognitoAttributes } = useContext(CognitoUserContext);
    // console.log('habitGroups', habitGroups)
    // console.log('activeGroup: ', activeGroup);
    // console.log('updatingGroup: ', updatingGroup)

    const userZoneInfo = cognitoUser?.attributes?.zoneinfo || 'Europe/London'
    const todayMoment = moment().tz(userZoneInfo);

    useEffect(() => {
        if(activeGroup?.trackHistory?.[0]?.date){
          const latestHistoryMoment = moment(activeGroup.trackHistory[0].date).tz(userZoneInfo);

        //   If is today
          if(latestHistoryMoment.isSame(moment().tz(userZoneInfo), 'day')) setTodayStreakData(activeGroup.trackHistory[0])
        }

        if(activeGroup?.duration > 0){
          if(activeGroup?.completionStatus === 'FINISHED') {
            setFinished(true);
            // disableAllHabitGroups();
          } else if(activeGroup?.completionStatus === 'FAILED') {
            setFailed(true);
            // disableAllHabitGroups();
          } else {
            setFinished(false);
            setFailed(false);
          }
        }
    }, [activeGroup, userZoneInfo]);

    const today = useMemo(() => {
        const habits = get(activeGroup, 'habits', []);

        return {
            completed: todayStreakData?.habitsTracked,
            habits: habits.map((habit: any) => ({
                ...habit,
                complete: todayStreakData?.habitUuids?.indexOf(habit.habitUuid) > -1,
            })),
        }
    }, [activeGroup, todayStreakData])

    // console.log('activeroup: ', activeGroup);

    const restart = () => {
        setTodayStreakData({ habitUuids: []});
        disableAllHabitGroups();
    }

    return {
        active: inProgress,
        // group: activeGroup,
        streak: activeGroup?.lastStreak,
        duration: {
            total: activeGroup?.duration,
            remaining: activeGroup?.duration - activeGroup?.lastStreak,
            unit: activeGroup?.durationUnits,
        },
        restart,
        today,
        failed,
        finished,
        setFailed, // for demo
        setFinished, // for demo
        todayMoment
    }
};

export default useHabitStreak;
