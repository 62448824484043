import React, { FC } from "react";
import { UserProfile } from "../../@types/user-discovery";
import friendHeader from "../../assets/img/friendHeader.png";

type FAFGridCardProp = {
  userProfile: UserProfile
  onClickUser: () => void;
};

const FAFGridCard: FC<FAFGridCardProp> = ({
                                            userProfile, onClickUser
                                          }) => (
  <div
    className="glassCard !border-[1px] flex flex-row items-center p-4 rounded-[20px] hover:cursor-pointer"
    onClick={onClickUser}
  >
    <div className="h-10 w-10 md:h-16 md:w-16 rounded-full mr-3">
      <img src={userProfile.profilePictureUrl ?? friendHeader} className="h-full w-full object-cover rounded-full" />
    </div>

    <div className="w-[70%]">
      <p
        className="text-sm md:text-base font-semibold">{`${userProfile?.firstName ?? ""} ${userProfile?.lastName ?? ""}`}</p>
      {userProfile?.location && <p className="text-xs md:text-sm text-[#4F70A0]">{`${userProfile?.location}`}</p>}
    </div>
  </div>
);

export default FAFGridCard;
