import React from 'react';
import { Link } from 'react-router-dom';
import { selectSubscription } from 'utils/stripe';

export default ({
    children, productID, brandSrc, onClick, access, progress, currentSection, currentVideo,
}) => (
    <>
    {
        !!access ?
        (
            !!progress ? (<Link to={{ pathname: `/course/${productID}/phase/${currentSection}/lesson/${currentVideo}`, state: { productID, brandSrc } }}>{children}</Link>)
            :
            <a onClick={() => onClick(true)} className="cursor-pointer">{children}</a>
        )
        :
        children
    }
    </>
);
