import React, { FC } from 'react';
import moment from 'moment';

type CoachCardType = {
    title: string, 
    image: string ,
    startTime: Date, 
    endTime: Date, 
    onClick: (a: any) => void,
    timezone: string,
};

const CoachCard: FC<CoachCardType> = ({ title, image , startTime, endTime, onClick, timezone}) => (
  <div className='w-full flex justify-between items-center glassCard !border-[1px] backdrop-blur-md rounded-[12px] pr-2 mb-3 hover:cursor-pointer' onClick={onClick}>
    <div className='flex flex-row items-center w-full'>
      <img src={image} className='w-20 h-20 lg:w-14 lg:h-14 object-cover rounded-[10px] bg-red' />
      <div className='flex flex-col items-start justify-center ml-2 w-full'>
        <p className='text-sm text-[#CBD5E1] font-bold line-clamp-1 mb-1'>
          {title}
          </p>
          <p
              className="text-xs font-bold text-[#CBD5E1]/50 line-clamp-1"
            >
              {`${moment(startTime).format(
              'HH:mm'
            )} - ${moment(endTime).format(
              'HH:mm'
            )} ${timezone}`}
            </p>
      </div>
    </div>
  </div>
);

export default CoachCard;
