import React, { FC } from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import play from '../../assets/img/icons/play.svg';
import CompletedIcon from '../../assets/img/icons/completed.svg';

type RecordingCardType = {
   image: string,
   title: string,
   onClick: ()=> void,
   completed: boolean,
   onComplete: ()=> void,
   startDate: string
}

const RecordingCard:FC<RecordingCardType> = ({
    image, title, onClick, completed, onComplete,startDate
}) => (
    <div
        className="glassCard rounded-[20px] w-full px-4 py-4 flex flex-row justify-between hover:cursor-pointer relative"
        onClick={onClick}
    >
        <div className="w-full bg-red mr-4 rounded-[10px] overflow-hidden relative">
            <img
                src={image}
                className="h-full w-full object-cover"
                alt="video thumbnail"
            />
        </div>
        <div className="w-full flex flex-col justify-between ">
            <div className="glassCard rounded-[10px] mb-2 w-full h-full  px-2 py-2 flex flex-col">
                <div className='glassCard w-full flex justify-center rounded-[6px] py-1 '>
                    <p className='text-sm text-[#CBD5E1]'>{startDate}</p>
                </div>
                <p className="text-sm 2xl:text-base text-[#CBD5E1] font-normal mt-1 2xl:mt-2 text-left">{title}</p>
            </div>
            <div
                className={`${!completed && 'button !border-[1px]'} hover:cursor-pointer !rounded-[8px] w-full h-[30px] 2xl:h-[40px] py-1 flex justify-center items-center bg-gradient-to-r from-[#0384FE] to-[#03E5B4]`}
            >
                <p className="text-sm text-[#CBD5E1]">{completed ? 'Completed' : 'Watch recording'}</p>
                {
                    completed && <CheckCircleIcon className="text-white h-4 w-4 ml-[6px]" />
                }
            </div>
        </div>       
    </div>
);

export default RecordingCard;
