import { UserDataContext } from "providers/UserDataProvider";
import { useContext } from "react";
import { uploadImage } from "utils/uploadImage";

const useImageUpload = () => {
  const { userToken }: { userToken?: string } = useContext(UserDataContext);
  //
  // const uploadProfilePicture = (e: any) => {
  //   setImageLoading(true);
  //   uploadImage(e?.target?.files[0], userToken)
  //     .then((res) => {
  //       if (res?.fileUrl) {
  //         setImageLoading(false);
  //         setUserProfile({
  //           ...userProfile,
  //           data: { ...userProfile?.data, profileImage: res?.fileUrl }
  //         });
  //
  //         upsertProfileData({
  //           variables: {
  //             data: JSON.stringify({
  //               ...userProfile?.data,
  //               profileImage: res?.fileUrl
  //             })
  //           }
  //         });
  //       }
  //     })
  //     .catch((e) => {
  //       setImageLoading(false);
  //     });
  // };

  const uploadPublicImage = async (e: any) => {
    const res = await uploadImage(e?.target?.files[0], userToken);
    return res.fileUrl;
  };

  return {
    uploadPublicImage
  };
};

export default useImageUpload;
