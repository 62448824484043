import { UserDataContext } from "providers/UserDataProvider";
import { useContext } from "react";
import { useHistory } from "react-router";
import { useLogout } from '@genflow/web-auth';

const useStickyLogout = () => {
    const { setUserProfile } = useContext(UserDataContext);
    const history = useHistory();
    const logout = useLogout();

    const stickyLogout = () => {
        try {
            logout().then(() => {
                // clear user profile from local storage
                setUserProfile({});
                console.log('logout now');
                localStorage.clear();
                history.push('/login');
            });
        } catch (error) {
            console.error(error);
        }
    };

    return stickyLogout;   
}

export default useStickyLogout;