import React, { FC } from 'react';
import moment from 'moment';
import toTitleCase from '../../utils/toTitleCase.ts';

type CoachCardType = {
    title: string, 
    image: string ,
    startTime: Date, 
    endTime: Date, 
    onClick: (a: any) => void,
    timezone: string,
};

const CoachCard: FC<CoachCardType> = ({ title, image , startTime, endTime, onClick, timezone}) => (
  <div className='w-full flex justify-between items-center glassCard backdrop-blur-md rounded-[20px] pr-4' onClick={onClick}>
    <div className='flex flex-row items-center w-2/3'>
      <img src={image} className='w-20 h-20 object-cover rounded-[18px] bg-red' />
      <div className='flex flex-col items-start justify-center ml-4'>
        <p className='text-sm md:text-lg text-[#CBD5E1]  md:font-bold line-clamp-1'>
          {title}
          </p>
          <p
              className="text-[10px] font-bold md:text-sm text-[#CBD5E1]/50"
            >
              {`${moment(startTime).format(
              'HH:mm'
            )} - ${moment(endTime).format(
              'HH:mm'
            )} ${timezone}`}
            </p>
      </div>
    </div>
    <div className='glassCard backdrop-blur-md py-2 px-4 rounded-[12px] '>
      <p className='text-sm text-[#CBD5E1] hidden md:block'>Reserve your spot</p>
      <p className='text-sm text-[#CBD5E1] md:hidden'>Reserve</p>
    </div>
  </div>
);

export default CoachCard;
