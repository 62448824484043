import plusIcon from '../../assets/img/icons/plusFull.svg';
import communityLogo from '../../assets/img/icons/community-logo.svg';
import email from '../../assets/img/icons/email-outline.svg';
import instagram from '../../assets/img/icons/instagram.svg';
import twitter from '../../assets/img/icons/twitter.svg';
import youtube from '../../assets/img/icons/youtube.svg';
import website from '../../assets/img/icons/website.svg';
import ANLogo from '../../assets/img/icons/ANLogo.svg';
import sixFigureLogo from '../../assets/img/icons/sixFigureCourseLogo.svg';
import penCourseLogo from '../../assets/img/icons/penCourseLogo.svg';

export const locations = [
    {
      label: 'New York',
      value: 'New York',
    },
    {
      label: 'London',
      value: 'London',
    },
    {
      label: 'San Francisco',
      value: 'San Francisco',
    },
    {
      label: 'Barcelona',
      value: 'Barcelona',
    },
    {
      label: 'Paris',
      value: 'Paris',
    },
    {
      label: 'Berlin',
      value: 'Berlin',
    },
  ];

  export const programs = [
    {
      label: 'Agency Navigator',
      value: 'Agency Navigator',
      icon: ANLogo,
    },
    {
      label: 'Pen to Profit',
      value: 'Pen to Profit',
      icon: penCourseLogo,
    },
    {
      label: 'Six Figure Sales Rep',
      value: 'Six Figure Sales Rep',
      icon: sixFigureLogo,
    },
  ];

  export const socials = [
    {
      icon: communityLogo,
      value: '',
      placeHolder: 'Educate Community Profile Link',
      editable: true,
      platform: 'community',
    },
    {
      icon: instagram,
      value: '',
      placeHolder: 'Instagram Profile Link',
      editable: true,
      platform: 'instagram',
    },
    // {
    //   icon: email,
    //   value: '',
    //   placeHolder: 'Email Address',
    //   editable: false,
    //   platform: 'email',
    // },
    {
      icon: twitter,
      value: '',
      placeHolder: 'Twitter Profile Link',
      editable: true,
      platform: 'twitter',
    },
    // {
    //   icon: youtube,
    //   value: '',
    //   placeHolder: 'Youtube Channel Link',
    //   editable: true,
    //   platform: 'youtube',
    // },
    // {
    //   icon: website,
    //   value: '',
    //   placeHolder: 'Your website',
    //   editable: true,
    //   platform: 'customWebsite',
    // },
  ];

export {
  plusIcon,
  communityLogo,
  email,
  instagram,
  twitter,
  youtube,
  website,
  }
