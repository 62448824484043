import React, { FC, useMemo } from "react";
import { lowerCase } from "lodash";
import useCompleteLesson from "@genflow/web-courses/hooks/useCompleteLesson";
import CourseProductLink from "../CourseProductLink";
import useUserAccess from "../../hooks/useUserAccess.ts";

type ProgressCardType = {
  image: string;
  productID: number | string;
  brandSrc: string;
  invite: boolean;
  progress: boolean;
  video: number | string;
  lesson: number | string;
  sections: any[];
  isSnapPoint?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  lessonIndex?: number;
};


const ProgressCard: FC<ProgressCardType> = ({
  image,
  productID,
  brandSrc,
  sections,
  progress,
  lesson,
  video,
  isSnapPoint,
  lessonIndex,
}) => {
  const { hasAccess } = useUserAccess({ contentId: `${productID}`, skip: !productID });

  const totalLessons = sections
    ?.map(({ lessons }) => lessons.length)
    ?.reduce((acc, value) => acc + value, 0);
  const { totalCompletedLessons } = useCompleteLesson(productID);

  const progressPercentage = (totalCompletedLessons * 100) / totalLessons;
  const progressVideo = sections
    ?.find((item) => item?.id === lesson)
    ?.lessons?.find((item: any) => item?.id === video);

  const thumbnail = useMemo(
    () =>
      progressVideo?.assets?.find(
        (item: any) => lowerCase(item?.type) === 'video'
      )?.thumbnail,
    [progressVideo]
  );

  return (
    <div
      className={`shrink-0 mr-4 w-full ${
        isSnapPoint && 'snap-start'
      } px-4 lg:px-6 2xl:px-8`}
    >
      <CourseProductLink
        key={productID}
        productID={productID}
        brandSrc={brandSrc}
        access={hasAccess}
        progress={progress}
        currentSection={lesson}
        currentVideo={video}
        onClick={null}
      >
        <div className="h-full w-full rounded-[20px] relative flex flex-row glassCard backdrop-blur-md shrink-0  hover:cursor-pointer">
          <div className="relative h-full w-full">
            <img
              src={image}
              alt="course image"
              className="object-cover w-full h-full rounded-[18px]"
            />

            <div className="absolute   w-full z-10 bottom-4 md:bottom-6 flex flex-col px-5">
              <div className="w-full flex flex-col md:flex-row justify-between md:items-end">
                <div className="w-full max-w-[40%]">
                  {!!brandSrc && (
                    <div>
                      <img
                        src={brandSrc}
                        alt="logo"
                        className="object-contain w-auto md:h-[65px] h-[55px]"
                      />
                    </div>
                  )}
                </div>

                <div className="backdrop-blur-md glassCard p-3 rounded-[20px] min-w-[45%] max-w-full md:max-w-[55%]">
                  <div className="flex flex-row items-end">
                    <img
                      src={thumbnail}
                      alt="progress"
                      className="w-1/3 md:w-20  rounded-md object-contain object-center mr-2"
                    />

                    <div>
                      <p className="text-white/50 text-xs font-medium">
                        {`Phase ${lessonIndex || 1}`}
                      </p>
                      <p className="text-sm font-medium">
                        {progressVideo?.name}
                      </p>
                    </div>
                  </div>

                  <div className="w-full border-[1px] border-[#CBD5E1] rounded-[10px] flex justify-center py-1.5 mt-2">
                    <p className="text-[#CBD5E1] text-sm">Resume Watching</p>
                  </div>
                </div>
              </div>

              <div className="w-full bg-[#CBD5E166]  h-1.5 bottom-0 overflow-hidden rounded-xl mt-4">
                <div
                  className="bg-white h-full rounded-xl ease-linear transition-all duration-700"
                  style={{ width: `${progressPercentage}%` }}
                />
              </div>
            </div>
          </div>
        </div>
      </CourseProductLink>
    </div>
  );
};

export default ProgressCard;
