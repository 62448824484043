export default (key: string) => {
  const itemStr = localStorage.getItem(key)
  if (!itemStr) return null;

  const item = JSON.parse(itemStr)
  if (new Date().getTime() > item.expiry) {
    localStorage.removeItem(key)
    return null
  }
  return item.value
}
