import gql from 'graphql-tag';

export default gql`
    query ($vendorUuid: ID!, $search: String, $filters: SearchUserPublicProfilesFilters, $page: Int, $pageSize: Int){
        searchUserPublicProfiles(vendorUuid: $vendorUuid, search: $search, filters: $filters, page: $page, pageSize: $pageSize) {
            page
            pageSize
            pages
            total
            data {
                isPrivate
                cognitoSub
                profilePictureUrl
                headerPictureUrl
                birthdate
                firstName
                lastName
                bio
                careerPath
                location
                contactMethods {
                    platform
                    method
                }
            }
        }
    }
`;
