import React, { useContext, useEffect, useState } from 'react';
import Loader from '../../../components/Loader';
import { getUser } from '../../../utils/auth';
import { UserDataContext } from '../../../providers/UserDataProvider';
import { uploadImage } from '../../../utils/uploadImage';
import person from '../../../assets/img/icons/person.svg'
import plus from '../../../assets/img/icons/plusFull.svg'
import { trackEvent } from 'utils/useAnalytics';

const OnboardingUserInfo = ({ next }) => {
    const [user, setUser] = useState({});
    const { userProfile, setUserProfile, userToken } = useContext(UserDataContext);
    const [loading, setLoading] = useState(false);

    const handleImageChange = (e) => {
        setLoading(true);
        uploadImage(e?.target?.files[0], userToken).then((res) => {
            if (res?.fileUrl) {
                setLoading(false);
                setUser({
                    ...user,
                    profileImage: res?.fileUrl,
                });
            }
        }).catch((e) => {
            setLoading(false);
        });
    };

    const handleChangeText = (e) => {
        const { value } = e.target;
        setUser({
            ...user,
            [e.target.id]: value,
        });
    };


    useEffect(() => {
        userInfo();
    }, []);

    const userInfo = async () => {
        try {
            const cognitoUser = await getUser();
            if (cognitoUser?.attributes?.sub) {
                const email = cognitoUser?.attributes?.email;
                setUser({ ...user, email, firstName: cognitoUser?.attributes?.given_name, lastName: cognitoUser?.attributes?.family_name  });
            }
        } catch (e) {
            console.log(e);
        }
    };


    const onSubmit = (e) => {
        next(e, {
            ...userProfile,
            data: {
                ...userProfile?.data,
                ...user,
                onboardingStageId: 1,
                subscriptionPurchased: true
            },
        });
    };

    useEffect(()=> {
        if(!userProfile?.data?.subscriptionPurchased){
            trackEvent({
                action: 'Purchase',
                category: 'Subscription_Purchased',
            });
        }
    },[])


    return (
        <div className="w-full flex flex-col">
            <form onSubmit={onSubmit}>
                <div className="flex justify-center">
                    <div className="mt-3 mb-3 w-[103px] h-[103px] flex justify-center items-center rounded-[20px] relative glassCard ">
                        <img src={user?.profileImage} alt="" className={`w-[100px] h-[100px] rounded-[20px] object-cover object-top opacity-${user?.profileImage ? 1 : 0}`} />
                        <label id="form-onboarding-image" htmlFor="onboarding-image" className="text-white flex items-center justify-center w-full h-full rounded-full absolute z-10 top-0 left-0">
                            {(user?.profileImage || loading) ? '' : (
                                <div className="w-[100px] h-[100px] flex items-center justify-center rounded-[20px] relative">
                                    <img src={person} className=" w-16 h-16" alt='profile picture'/>

                                    <div className='absolute w-full h-full left-0 top-0 flex justify-center items-center'>
                                        <img src={plus} className=" w-4 h-4" alt='profile picture'/>
                                    </div>
                                </div>
                            )}
                            {loading && <Loader />}
                            <input type="file" id="onboarding-image" alt="+ add photo" width="48" height="48" onChange={handleImageChange} className="h-0 w-0 opacity-0" />
                        </label>
                    </div>
                </div>
                <div className="mb-4 w-full">
                    <label htmlFor="firstName" className="block mb-2 text-sm font-medium text-#CBD5E1 w-full ">
                        What’s your first name?
                        <input type="text" id="firstName" onChange={handleChangeText} className="rounded-md mt-2  glassCard backdrop-blur-md text-white text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" value={user?.firstName} required />
                    </label>
                </div>
                <div className="mb-4 w-full">
                    <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-#CBD5E1 w-full ">
                        What’s your last name?
                        <input type="text" id="lastName" onChange={handleChangeText} className="rounded-md mt-2  glassCard backdrop-blur-md text-white text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" value={user?.lastName} required />
                    </label>
                </div>
                <div className="mb-4 w-full">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-white w-full ">
                        Your email?
                        <input type="email" id="email" readOnly className="rounded-md glassCard backdrop-blur-md mt-2  text-white text-sm focus:ring-blue-500 focus:outline-none block w-full p-2.5 " value={user?.email} required />
                    </label>
                </div>

                <button
                    type="submit"
                    className="button flex w-full justify-center rounded-md  px-3 py-3 2xl:py-4 text-lg md:text-lg font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    disabled={loading}
                >
                    Next
                </button>
                <div />

            </form>
        </div>
    );
};

export default OnboardingUserInfo;
