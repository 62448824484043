import React, { FC, useContext } from "react";
import DashboardCard from "./DashboardCard";
import Loader from "components/Loader";
import useCountDown from "hooks/useCountDown";
import useUserAccess from "../../hooks/useUserAccess.ts";
import useCommunity from "../../hooks/useCommunity.ts";
import communityImage from "../../assets/img/join-the-community.png"
import updgradeToday from "../../assets/img/upgrade-today.png"
import { CognitoUserContext } from "providers/CognitoUserProvider.tsx";

type MonkModeCardType = {
  image: string;
  startTime: string
  link: string
  loading: string
}

const MonkModeCard: FC<MonkModeCardType> = ({ image, startTime, link, loading }) => {
  const { discordCommunityUrl } = useCommunity();
  const countDown = useCountDown(startTime, true);
  const { userAccess } = useUserAccess();
  const hasDigitalLaunchPad = !!userAccess?.find(({ contentId }: { contentId: string }) => contentId === "6196");
  const hasAgencyNavigator = !!userAccess?.find(({ contentId }: { contentId: string }) => contentId === "6018");
  const onlyDigitalLaunchPad = !hasAgencyNavigator && hasDigitalLaunchPad;
  const { cognitoUser } = useContext(CognitoUserContext)
  const upgradeLink = `https://backend-bvblwyj72q-uc.a.run.app/stripe/dl-upgrade?email=${encodeURIComponent(cognitoUser?.attributes?.email ?? '')}`

  return (
    <DashboardCard
      to={`/${link}`}
      externalLink={onlyDigitalLaunchPad ? upgradeLink : link?.includes("http") ? link : null}
      onClick={(e) => !onlyDigitalLaunchPad && !countDown?.elapsed && e.preventDefault()}
      title={onlyDigitalLaunchPad ? "" : "Coming Up"} lgWidth={60}>
      {loading && (
        <div className="flex w-full h-full justify-center items-center">
          <Loader />
        </div>
      )}
      <div className="flex-1 overflow-hidden">
        <div
          className="rounded-lg md:rounded-[20px] bg-cover aspect-[340/177] shadow-lg object-contain flex-1 max-h-full max-w-full relative"
          style={
            {
              backgroundImage: `url(${onlyDigitalLaunchPad ? updgradeToday : image})`
            }}>
          {!!startTime && <div
            className="absolute bottom-3 right-3 md:bottom-5 md:right-5 glassCard backdrop-blur-md py-2 px-4 md:px-6 rounded-[20px]">
            <div className="flex flex-row items-start">
              <div className="flex flex-col items-center">
                <p
                  className="font-bold text-base md:text-2xl w-4 md:w-8 text-center leading-normal">{countDown?.days}</p>
                <p className="text-[10px] font-light mt-[-4px]">DAYS</p>
              </div>
              <p className="font-bold text-base md:text-2xl mx-1">:</p>
              <div className="flex flex-col items-center">
                <p
                  className="font-bold text-base md:text-2xl w-4 md:w-8 text-center leading-normal">{countDown?.hours}</p>
                <p className="text-[10px] font-light mt-[-4px]">HOURS</p>
              </div>
              <p className="font-bold text-base md:text-2xl mx-1">:</p>
              <div className="flex flex-col items-center">
                <p
                  className="font-bold text-base md:text-2xl w-4 md:w-8 text-center leading-normal">{countDown?.minutes}</p>
                <p className="text-[10px] font-light mt-[-4px]">MINS</p>
              </div>
              <p className="font-bold text-base md:text-2xl mx-1">:</p>
              <div className="flex flex-col items-center">
                <p
                  className="font-bold text-base md:text-2xl w-4 md:w-8 text-center leading-normal">{countDown?.seconds}</p>
                <p className="text-[10px] font-light mt-[-4px]">SECS</p>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </DashboardCard>
  );
};

export default MonkModeCard;
