/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { findIndex, flatten, get, has, parseInt } from "lodash";
import useCompleteLesson from "@genflow/web-courses/hooks/useCompleteLesson";
import "../../../assets/css/sproutvideo.css";
import VideoPage from "../../../components/VideoPage/index";
import moment from "moment";
import useContent from "../../../hooks/useContent";

function filterSubsections(sections) {
    if (!sections) return [];

    const filteredSubsections = [];

    sections.forEach((item, i) => {
        if (item.parent_id) {
            const parentIndex = findIndex(sections, i2 => i2.id == item.parent_id);

            if (has(filteredSubsections, `[${parentIndex}].subSections`)) {
                filteredSubsections[parentIndex].subSections.push(item);
            } else {
                filteredSubsections[parentIndex] = { ...filteredSubsections[parentIndex], subSections: [item] };
            }
        } else {
            filteredSubsections[i] = { ...item };
        }
    });

    return filteredSubsections;
}

export default () => {
    const { productID, sectionID, lessonID } = useParams();
    const {data, isLoading} = useContent({ productId: productID });
    const { completeLesson, data: completedLessons } = useCompleteLesson(productID);
    const sections = get(data, 'sections', []);

    const lessons = flatten(sections.map(({ lessons }) => lessons));

    const [selectedLesson, setSelectedLesson] = useState(null);

    useEffect(() => {
        if (!selectedLesson && lessons.length > 0) {
            setSelectedLesson(
        sections
            .find(section => section.id === sectionID)
            ?.lessons?.find(lesson => lesson.id === lessonID) || lessons[0],
            );
        }
    }, [lessons]);

    const nonDownloadable = ['Transcript', 'Video', 'Audio', 'Secure Video'];
    const transcripts = ['Transcript', 'Audio'];
    const getVideos = (listAssets) => {
        const videoAssets = assets => assets.filter(item => item.type === 'Video');


        const downloadableAssets = assets => assets.filter(item => !nonDownloadable?.includes(item?.type));

        const metadataAsset = v => (typeof v === 'string' ? JSON.parse(v) : null);
        const metadataLesson = v => (typeof v === 'string' ? JSON.parse(v) : null);

        const getTranscript = assets => assets.filter(item => transcripts?.includes(item?.type));


        return listAssets.reduce((result, lesson) => {
            const metadata = {...metadataAsset(videoAssets(lesson.assets)[0]?.meta_data), ...metadataLesson(lesson.meta_data)};

            // const availableVideo = metadata?.isLive;
            const availableVideo = true; // update once Giuditta updates md

            if (availableVideo) {
                result.push({
                    id: lesson.id,
                    name: lesson.name,
                    metadata,
                    url: videoAssets(lesson.assets)[0]?.embedUrl,
                    thumbnail: videoAssets(lesson.assets)[0]?.thumbnail,
                    sproutId: videoAssets(lesson.assets)[0]?.sproutId,
                    description: videoAssets(lesson.assets)[0]?.description || '',
                    downloads: downloadableAssets(lesson.assets),
                    transcript: getTranscript(lesson.assets) || null,
                    complete: !!completedLessons.find(({ id: cId }) => parseInt(cId) === parseInt(lesson.id)),
                    order: lesson?.order
                });
            }

            return result;
        }, []);
    };

    const isSectionAvailable = ({ id, name, lessons: l, meta_data }) => {
        const videos = getVideos(l);

        console.log({ videos });
        // const availableSection = !!videos.filter(item => item.metadata?.isLive).length;
        const availableSection = !!videos?.length; // update once Giuditta updates md
        const releaseDate = meta_data && JSON.parse(meta_data)?.releaseDate;
        const now = moment();

        if (availableSection) {
            return {
                id,
                name,
                videos,
                locked: now.isBefore(moment(releaseDate))
            };
        }
        return false;
    };
    const videoSections = filterSubsections(sections).reduce((arraySections, section) => {
        if (section.subSections?.length) {
            const s = section.subSections.reduce((result, item) => {
                if (isSectionAvailable(item)) {
                    result.push(isSectionAvailable(item));
                }
                return result;
            }, []);

            arraySections.push({
                id: section.id,
                name: section.name,
                subSections: s,
                videos: [],
            });
        } else {
            const s = isSectionAvailable(section);


            if (s) {
                arraySections.push(s);
            }
        }

        return arraySections;
    }, []);

    return (
        <>
            <VideoPage
                name={get(data, 'name', '')}
                productID
                brandSrc
                loading={isLoading}
                sections={videoSections}
                showSectionIndex
                enableCompleteVideo
                nextButtonLabel="Next"
                onToggleComplete={(video) => {
                    console.log('complete', video);
                    completeLesson(video?.id, !video?.complete);
                }}
            />
        </>
    );
};
