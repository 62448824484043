import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { isEmpty, isEqual, xorWith } from "lodash";
import { useConfig } from "@genflow/core";
import getUserHabitGroupsQuery from "../api/getUserHabitGroups.query";
import createUpdateHabitGroupMutation from "../api/createUpdateHabitGroup.mutation";
import trackHabitMutation from "../api/trackHabit.mutation";
import getUserHabitGroupsStatsQuery from "../api/getUserHabitGroupsStats.query";

const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));

export const HabitsContext = createContext({
  habitGroups: [],
  createUpdateHabitGroup: ({ habitGroupUuid, duration, metadata }) => null,
  trackHabit: (habitUuid, metadata) => null,
  loading: false,
  updatingGroup: false,
  disableAllHabitGroups: () => null
});
const { Provider, Consumer: HabitsConsumer } = HabitsContext;

export const HabitsProvider = ({ children }) => {
  const { vendorUuid, habitTrackerDebugMode } = useConfig();

  const [habitGroups, setHabitGroups] = useState();

  const { data: groupsData, error: groupsError, loading } = useQuery(getUserHabitGroupsQuery, {
    variables: { vendorUuid },
    skip: !vendorUuid
  });

  const networkGroups = groupsData?.getUserHabitGroups?.data || [];

  useEffect(() => {
    const filteredGroups = networkGroups?.filter(({ status }) => status !== "disabled");

    if (!isArrayEqual(filteredGroups, habitGroups)) {
      setHabitGroups(filteredGroups);
    }
  }, [networkGroups]);


  const [
    createUpdateHabitGroupReq, { loading: updatingGroup }
  ] = useMutation(createUpdateHabitGroupMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [{
      query: getUserHabitGroupsQuery,
      variables: { vendorUuid },
      skip: !vendorUuid
    }]
  });

  const [
    trackHabitReq, { loading: trackingHabit }
  ] = useMutation(trackHabitMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: getUserHabitGroupsQuery,
        variables: { vendorUuid },
        skip: !vendorUuid
      },
      {
        query: getUserHabitGroupsStatsQuery,
        variables: { vendorUuid },
        skip: !vendorUuid
      }]
  });

  const createUpdateHabitGroup = ({ habitGroupUuid = null, duration, metadata = {} }) => {
    // console.log('createUpdateHabitGroup', vendorUuid, habitGroupUuid, metadata);
    return createUpdateHabitGroupReq({
      variables: {
        vendorUuid,
        metadata: {
          ...metadata,
          habitGroupUuid,
          status: metadata?.status || "active"
        },
        duration
      }
    });
  };

  const trackHabit = (habitUuid, metadata = {}) => {
    return trackHabitReq({
      variables: {
        vendorUuid,
        habitUuid,
        metadata
      }
    });
  };

  const createHabitGroupInput = data => ({
    name: data?.name,
    duration: data?.duration,
    status: data?.status,
    habitsKeys: data?.habitsKeys
  });

  // TODO - Move to backend
  const disableAllHabitGroups = async () => {
    // There should only ever be 1 group, this is just a safety check
    const activeGroups = habitGroups?.filter(({ status }) => status === "active");
    console.log("activeGroups: ", activeGroups);

    await Promise.all(activeGroups.map(async (habitGroup) => {
      const response = await createUpdateHabitGroup({
        habitGroupUuid: habitGroup.habitGroupUuid,
        metadata: {
          status: "disabled"
        }
      });
    }));
  };


  return (
    <Provider value={{
      habitGroups,
      loading,
      createUpdateHabitGroup,
      trackHabit,
      trackingHabit,
      updatingGroup,
      disableAllHabitGroups
    }}
    >
      {children}
    </Provider>
  );
};

HabitsProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default {
  HabitsProvider,
  HabitsContext,
  HabitsConsumer
};
