import React, { FC } from 'react';
import { DownloadIcon } from '@heroicons/react/outline';

type ResourceCardType = {
  onClick: () => void;
  title: string;
  subtitle: string;
};

const ResourceCard: FC<ResourceCardType> = ({ onClick, title, subtitle }) =>  (
    <div
      onClick={onClick}
      rel="tooltip"
      data-original-title=""
      title=""
      className="group glassCard !border-[1px] rounded-[16px] md:rounded-[20px] p-5 relative min-h-[120px] hover:cursor-pointer"
    >
      <div>
        <p className="text-base md:text-xl text-[#CBD5E1] font-bold mb-1">
          {title}
        </p>
        <p className="text-[#CBD5E14D] font-semibold text-sm md:text-lg">
          {subtitle}
        </p>
      </div>
      <div className="absolute z-99 right-4 bottom-4">
        <DownloadIcon className="h-7 w-7 mt-1 text-white opacity-20 group-active:opacity-80 transition-all" />
      </div>
    </div>
  );


export default ResourceCard;
