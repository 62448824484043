import { useConfig } from "@genflow/core";
import { get, lowerCase, parseInt } from "lodash";
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range.js";
import { TimezoneContext } from "providers/TimezoneProvider";
import { useContext, useMemo } from "react";
import parseMetadata from "utils/parseMetadata";
import useSortCallsByStartTime from "./useSortCallsByStartTime";
import useContent from "./useContent";

export default () => {
  const {
    vendorUuid,
    agencyNavigatorResourceLibraryProductID,
    agencyNavigatorCourseProductID,
    upcomingQACallsID,
  } = useConfig();

  const sortCallsByStartTime = useSortCallsByStartTime();

  const { localiseTimestamp, timezone } = useContext(TimezoneContext);

  const {data, isLoading} = useContent({productId: agencyNavigatorResourceLibraryProductID});

  const upcomingQA = get(data, 'sections', []).find(
    ({ id }) => parseInt(id) === upcomingQACallsID
  );

  let getHosts = (index) => {
    const hosts = upcomingQA?.lessons?.[index]?.assets;
    if (!hosts || !timezone) return [];
    const response = [...hosts]?.map((asset) => {
        const metaData = parseMetadata(asset);
        const { time: localStartTime, abbr } = localiseTimestamp(metaData?.startTime);
        const { time: localEndTime } = localiseTimestamp(metaData?.endTime);
        const images = [
          asset?.url,
          metaData?.image2,
          metaData?.image3,
          metaData?.image4,
        ]?.filter(Boolean);
        return {
          title: asset?.title,
          date: metaData?.dateInput,
          img: asset?.url,
          name: metaData?.speakerName?.[0],
          position: metaData?.displayName,
          description: asset?.description,
          link: metaData?.url,
          visible: metaData?.isLive,
          images,
          order: asset?.order,
          day: metaData?.day,
          startTime: localStartTime, // to add here
          endTime: localEndTime, // to add here
          color: metaData?.colour || '#0384FE',
          callIcon: metaData?.callIcon,
          startDate: localStartTime?.format('L'),
          categories: metaData?.categories,
          timezone: abbr,
        };
      }
    );

    return sortCallsByStartTime(response);
  };

  const hosts = useMemo(() => {
    if (!upcomingQA?.lessons?.length) return [];

    return upcomingQA?.lessons?.map((item, id) => ({
      title: item?.name,
      id: item?.name,
      hosts: getHosts(id),
    }));
  }, [upcomingQA, timezone]);

  const currentDay = useMemo(() => moment.utc().format('L'), []);
  const todayQA = useMemo(() => {
    const days = hosts?.reduce((acc, item) => {
      const hostByType = item?.hosts?.map((hostItem) => {
        return {
          ...hostItem,
          id: item?.id,
          visible: hostItem?.visible,
        };
      });
      if (hostByType?.length) {
        return [...(acc || []), ...hostByType]
      }
    }, []);

    // current day calls
    const dayCalls = days?.filter(
      ({ startDate, visible }) =>
        lowerCase(startDate)?.includes(lowerCase(currentDay)) && visible
    );

    return dayCalls;
  }, [hosts]);

  return {
    loading: isLoading,
    hosts,
    todayQA,
  };
};
