import gql from 'graphql-tag';

export default gql`
    query($vendorUuid: ID!){
        getUserHabitGroupsStats(vendorUuid: $vendorUuid) {
            error
            data {
                byCompletionStatus {
                    completionStatus
                    total
                    byDuration {
                        duration
                        total
                    }
                }
            }

        }
    }
`;
