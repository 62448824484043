import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "@genflow/web-auth";
import { Redirect } from "react-router";
import axios from "axios";
import { sortBy } from "lodash";
import queryString from "query-string";
import { v1 as uuidv4 } from "uuid";
import { Auth } from "aws-amplify";
import { useConfig } from "@genflow/core";
import { ArrowLeftIcon, ExclamationCircleIcon } from "@heroicons/react/outline";
import { trackEvent } from "../../../utils/useAnalytics";
import useStickyState from "../../../utils/useStickyState";
import { loginPasswordless } from "../../../utils/auth";
import LoadingButton from "../../../components/LoadingButton";
import { selectSubscription } from "../../../utils/stripe";
import CodeInput from "./components/CodeInput";
import countries from "../../../constants/registration/countriesData.json";
import "./styles.css";
import logo from "../../../assets/img/logo.svg";
import { StringParam, useQueryParams } from "use-query-params";
import ExpandMore from "@material-ui/icons/ExpandMore";
import setLocalStorageWithExpiry from "../../../utils/setLocalStorageWithExpiry";
import useUserAccess from "../../../hooks/useUserAccess";

const PREFILLED_EMAIL_QUERY_PARAM = 'prefilled_email';
export const PLAN_PARAM = 'plan';
export default ({ location }) => {
    const [queryParams] = useQueryParams({
        [PREFILLED_EMAIL_QUERY_PARAM]: StringParam,
        [PLAN_PARAM]: StringParam,
    });
    const isUpgrade = location?.pathname?.includes("upgrade");
    const [currIndex, setCurrIndex] = useState(0);
    const defaultFormData = queryString.parse(location?.search);
    const { vendorUuid } = useConfig();
    const { useIsLoggedIn } = useAuth();
    const isLoggedIn = useIsLoggedIn(true);
    const [registering, setRegistering] = useState(false);
    const [firstName, setFirstName] = useStickyState(defaultFormData?.firstName || "", "registerFirstName", false);
    const [lastName, setLastName] = useStickyState(defaultFormData?.lastName || "", "registerLastName", false);
    const [emailAddress, setEmailAddress] = useState(defaultFormData?.email || queryParams?.prefilled_email || "");
    const [confirmEmailAddress, setConfirmEmailAddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useStickyState(defaultFormData?.phone || "", "phoneNumber", false);
    const [instagram, setInstagram] = useStickyState(defaultFormData?.instagram || "", "instagram", false);
    const [validationMessage, setValidationMessage] = useState(null);
    const [unverifiedUser, setUnverifiedUser] = useState(null);
    const [codeValue, setCodeValue] = useState();
    const [selectedCountry, setSelectedCountry] = useState("United Kingdom");
    const activeCountry = useMemo(() => countries?.length && countries?.find(({ name }) => name === selectedCountry), [countries, selectedCountry]);
    const [registrationComplete, setRegistrationComplete] = useState(false);
    const { userAccess, isLoading } = useUserAccess({skip: !registrationComplete });
    const hasAccess = !!userAccess?.find(({key}) => key === 'analytics.read');

    useEffect(() => {
        setLocalStorageWithExpiry(PLAN_PARAM, queryParams?.[PLAN_PARAM] || '', 3600);
    }, [queryParams]);

    useEffect(() => {
        if (registrationComplete && !isLoading && hasAccess !== null && !hasAccess) selectSubscription(queryParams?.plan || null);
    }, [registrationComplete, hasAccess, isLoading]);

    if (isLoggedIn === null) return null;
    if (isLoggedIn && hasAccess && registrationComplete) return <Redirect to="/" />;



    const handleRegister = async () => {
        setValidationMessage("");
        if (!emailAddress.replace(/\s+/g, "") || !confirmEmailAddress.replace(/\s+/g, "")
            || !firstName.replace(/\s+/g, "") || !lastName.replace(/\s+/g, "") || !phoneNumber.replace(/\s+/g, "")) {
            setValidationMessage("You must input all required fields before creating an account.");
            return;
        }

        if (firstName.replace(/\s+/g, "")?.length < 3 || lastName.replace(/\s+/g, "")?.length < 3) {
            setValidationMessage("First and last names require at least 3 characters.");
            return;
        }

        if (emailAddress !== confirmEmailAddress) {
            setValidationMessage("Your emails does not match.");
            return;
        }

        try {
            if (!!unverifiedUser && !!codeValue) {
                setRegistering(true);
                try {
                    await Auth.sendCustomChallengeAnswer(
                        unverifiedUser, codeValue
                    );
                    // This will throw an error if the user is not yet authenticated:
                    await Auth.currentSession();
                    setRegistrationComplete(true);
                } catch (error) {
                    console.log(error);
                    if (error.code === "NotAuthorizedException") {
                        setValidationMessage("Your code seems to have expired, please try again.");
                        setUnverifiedUser(null);
                    } else {
                        setValidationMessage("Your code seems to be incorrect!");
                        console.warn(error);
                    }
                }
            } else {
                setRegistering(true);
                const password = uuidv4();

                const email = emailAddress.toLowerCase().trim();
                const emailWithVendor = `${email}#${vendorUuid}`;

                const response = await Auth.signUp({
                    password,
                    username: emailWithVendor,
                    attributes: {
                        email,
                        given_name: firstName,
                        family_name: lastName,
                        phone_number: phoneNumber?.includes(activeCountry?.countryCallingCode) ? phoneNumber : `${activeCountry?.countryCallingCode}${phoneNumber}`,
                        'custom:instagram_username': instagram?.replace(/\s/g, ""),
                        'custom:plan': queryParams?.[PLAN_PARAM],

                    }
                });

                trackEvent({
                    action: "User_Registered",
                    category: "Users"
                });

                const passwordLoginResponse = await loginPasswordless(email).catch((error) => {
                    setRegistering(false);
                    setValidationMessage(error);
                });

                setUnverifiedUser(passwordLoginResponse);
                setRegistering(false);
            }

        } catch (err) {
            if (err?.code === "UsernameExistsException") {
                setValidationMessage("This email already exists, why not login instead?");
            } else {
                console.log(err);
                setValidationMessage("Registration failed!");
            }
            setRegistering(false);
        }
    };

    const clearValidationMessage = () => {
        if (validationMessage) setValidationMessage(null);
    };

    const emailInputsEntered = !!emailAddress && !!confirmEmailAddress;
    const emailInputsMatch = emailAddress === confirmEmailAddress;

    const canRegister = unverifiedUser
        ? codeValue?.length === 6
        : (
            !!firstName
            && !!lastName
            && !!emailAddress
            && !!confirmEmailAddress
            && !!phoneNumber
            && emailAddress === confirmEmailAddress
        );

    const firstSectionComplete = !!emailAddress
        && !!confirmEmailAddress
        && !!phoneNumber
        && emailAddress === confirmEmailAddress;

    const handleChange = (event) => {
        setSelectedCountry(event.target.value);
    };

    const handleKeyPress = (event) => {
        const pattern = /[0-9]/;
        const inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    };

    const handleEmailBlur = () => {
        if (emailInputsEntered && !emailInputsMatch) {
            setValidationMessage("Your emails do not match.");
        }
    };

    return (
        <div className="flex px-4 py-4 lg:px-8 2xl:py-12 justify-center h-[100vh]">
            <div
                className="rounded-[20px] md:rounded-[30px] pt-6 2xl:pt-12 pb-5 2xl:pb-10 w-full md:w-4/6 lg:w-[624px] glassCard px-4 md:px-8 self-center">
                <div className="sm:mx-auto w-full relative">
                    {currIndex === 1 && (
                        <div className="absolute left-2 hover:cursor-pointer" onClick={() => setCurrIndex(0)}>
                            <ArrowLeftIcon className="h-5 w-5 md:h-8 md:w-8 shrink-0 text-white" aria-hidden="true" />
                        </div>
                    )}
                    <img
                        className="mx-auto h-5 md:h-8 2xl:h-14  w-auto"
                        src={logo}
                        alt="Your Company"
                    />
                </div>
                {isUpgrade && (
                    <div
                        className="mt-5 md:mt-8 2xl:mt-10 shadow-sm flex items-center justify-center rounded-md py-3 px-2 lg:px-5 x-auto sm:w-full mx-auto max-w-sm md:max-w-lg text-white button flex-col lg:flex-row">
                        <ExclamationCircleIcon className="h-8 w-8 lg:mr-4 mb-1 lg:mb-0 text-white "
                                               aria-hidden="true" />
                        <p className="text-base text-center lg:text-left">To be eligible to upgrade to Educate, please
                            sign up using your <span className="font-semibold">Grow Your Agency</span> email address.
                        </p>
                    </div>
                )}
                <div
                    className="w-full mt-5 md:mt-8 2xl:mt-10 mx-auto sm:w-full max-w-sm md:max-w-lg rounded-[16px] p-5 glassCard">
                    {
                        currIndex === 0 ? (
                            <div className="space-y-4 2xl:space-y-6" role="form">
                                <div>

                                    <label htmlFor="email"
                                           className="block text-sm 2xl:text-base font-medium leading-6 text-[#CBD5E1] ">
                                        Email
                                        <input
                                            disabled={!!unverifiedUser}
                                            value={emailAddress}
                                            onChange={(e) => {
                                                clearValidationMessage();
                                                setEmailAddress(e.target.value);
                                            }}
                                            onBlur={handleEmailBlur}
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            required
                                            placeholder="Email Address"
                                            className="outlineRemoved mt-2 placeholder:text-[#64748B] block w-full rounded-md py-3 px-4 2xl:py-4  text-[#CBD5E1] shadow-sm  ring-inset ring-gray-300  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm 2xl:text-base sm:leading-6 input"
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label htmlFor="confirmEmail"
                                           className="block text-sm 2xl:text-base font-medium leading-6 text-[#CBD5E1] ">
                                        Confirm Email
                                        <input
                                            disabled={!!unverifiedUser}
                                            value={confirmEmailAddress}
                                            onChange={(e) => {
                                                clearValidationMessage();
                                                setConfirmEmailAddress(e.target.value);
                                            }}
                                            onBlur={handleEmailBlur}
                                            id="confirmEmail"
                                            name="confirmEmail"
                                            type="email"
                                            autoComplete="email"
                                            required
                                            placeholder="Email Address"
                                            className="outlineRemoved mt-2 placeholder:text-[#64748B] block w-full rounded-md py-3 px-4 2xl:py-4 text-[#CBD5E1] shadow-sm  ring-inset ring-gray-300  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm 2xl:text-base sm:leading-6 input"
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label htmlFor="lastName"
                                           className="block text-sm 2xl:text-base font-medium leading-6 text-[#CBD5E1] ">
                                        Phone Number
                                        <div
                                            className="flex flex-row items-center  w-full mt-2 border border-gray-300 rounded-md py-3 px-4 2xl:py-4 input">
                                            <div className="relative flex flex-row">
                                                <select
                                                    className="w-10 appearance-none bg-transparent absolute outline-0 text-transparent cursor-pointer"
                                                    value={selectedCountry} onChange={handleChange}>
                                                    {
                                                        sortBy(countries, "name")?.map(item => (
                                                            <option value={item?.name} key={item?.name}>
                                                                {item?.name}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                                <img src={activeCountry?.flags?.png} alt="React Logo"
                                                     className="w-5 h-5  2xl:w-6 2xl:h-6 object-contain" />
                                                <ExpandMore className="w-3 h-3" />
                                                <p className="ml-2 sm:text-sm 2xl:text-base text-[#CBD5E1]">
                                                    {`(${activeCountry?.countryCallingCode})`}
                                                </p>
                                            </div>
                                            <input
                                                disabled={!!unverifiedUser}
                                                placeholder="Phone Number"
                                                type="text"
                                                className="border border-transparent bg-transparent sm:text-sm 2xl:text-base  outlineRemoved ml-1 text-[#CBD5E1] placeholder:text-[#64748B] outline-none"
                                                value={phoneNumber}
                                                onChange={(e) => {
                                                    clearValidationMessage();
                                                    setPhoneNumber(e.target.value);
                                                }}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </div>
                                    </label>
                                </div>
                                {
                                    !!validationMessage && (
                                        <div
                                            className="text-[#CBD5E1] mt-3 mb-3 py-3 px-4 text-xs md:text-sm bg-[#FF474720] border-[1px] border-[#D13F40] rounded-xl">
                                            {validationMessage}
                                        </div>
                                    )
                                }
                                <div>
                                    <LoadingButton
                                        className="button flex w-full justify-center rounded-md  px-3 py-3 2xl:py-4 text-lg md:text-lg font-semibold leading-6 text-white shadow-sm cursor-pointer focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        disabled={!firstSectionComplete}
                                        color="primary"
                                        type="button"
                                        onClick={() => setCurrIndex(1)}
                                    >
                                        Next
                                    </LoadingButton>
                                </div>
                            </div>
                        ) : (
                            <div className="space-y-4 2xl:space-y-6" role="form">
                                <div>
                                    <label htmlFor="firstName"
                                           className="block text-sm 2xl:text-base font-medium leading-6 text-[#CBD5E1] ">
                                        First Name
                                        <input
                                            disabled={!!unverifiedUser}
                                            value={firstName}
                                            onChange={(e) => {
                                                clearValidationMessage();
                                                setFirstName(e.target.value);
                                            }}
                                            id="firstName"
                                            name="firstName"
                                            type="text"
                                            required
                                            placeholder="First Name"
                                            className="outlineRemoved mt-2 placeholder:text-[#64748B] block w-full rounded-md py-3 px-4 2xl:py-4 text-[#CBD5E1] shadow-sm  ring-inset ring-gray-300  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm 2xl:text-base sm:leading-6 input"
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label htmlFor="lastName"
                                           className="block text-sm 2xl:text-base font-medium leading-6 text-[#CBD5E1] ">
                                        Last Name
                                        <input
                                            disabled={!!unverifiedUser}
                                            value={lastName}
                                            onChange={(e) => {
                                                clearValidationMessage();
                                                setLastName(e.target.value);
                                            }}
                                            id="lastName"
                                            name="lastName"
                                            type="text"
                                            required
                                            placeholder="Last Name"
                                            className="outlineRemoved mt-2 placeholder:text-[#64748B] block w-full rounded-md py-3 px-4 2xl:py-4 text-[#CBD5E1] shadow-sm  ring-inset ring-gray-300  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm 2xl:text-base sm:leading-6 input"
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label htmlFor="instagram"
                                           className="block text-sm 2xl:text-base font-medium leading-6 text-[#CBD5E1] ">
                                        Instagram
                                        <input
                                            disabled={!!unverifiedUser}
                                            value={instagram}
                                            onChange={(e) => {
                                                clearValidationMessage();
                                                setInstagram(e.target.value.replace(/[' ']/g, ''));
                                            }}
                                            id="instagram"
                                            name="instagram"
                                            type="text"
                                            required
                                            placeholder="@username (optional)"
                                            className="outlineRemoved mt-2 placeholder:text-[#64748B] block w-full rounded-md py-3 px-4 2xl:py-4 text-[#CBD5E1] shadow-sm  ring-inset ring-gray-300  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm 2xl:text-base sm:leading-6 input"
                                        />
                                    </label>
                                </div>
                                {
                                    !!unverifiedUser && <CodeInput onChange={setCodeValue} />
                                }

                                {
                                    !!validationMessage && (
                                        <div
                                            className="text-[#CBD5E1] mt-3 mb-3 py-3 px-4 text-xs md:text-sm bg-[#FF474720] border-[1px] border-[#D13F40] rounded-xl">
                                            {validationMessage}
                                        </div>
                                    )
                                }
                                {/* <div>
                                    <LoadingButton
                                        className={`button ${!canRegister && "disabled"} flex w-full justify-center rounded-md  py-3 px-4 2xl:py-4 text-lg md:text-lg font-semibold leading-6 text-white shadow-sm cursor-pointer focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                                        loading={registering}
                                        disabled={!canRegister}
                                        color="primary"
                                        type="button"
                                        onClick={handleRegister}
                                    >
                                        {unverifiedUser ? "Next" : "Sign up"}
                                    </LoadingButton>
                                </div> */}
                            </div>
                        )
                    }


                </div>
                <p className="mt-5 2xl:mt-10 text-center text-sm md:text-base text-[#64748B]">
                    Already have an account?
                    {" "}
                    <Link to="/login"
                          className="font-semibold leading-6 text-transparent bg-clip-text bg-gradient-to-r from-[#0384FE] to-[#03E5B4] underline-offset-auto">
                        Sign In
                    </Link>
                </p>
            </div>
        </div>
    );
};
