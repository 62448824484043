import React, { useContext, useEffect, useState } from "react";
import { plusIcon, programs, socials } from "constants/findAFriend/content";
import { keys, sortBy, startCase } from "lodash";
import useImageUpload from "hooks/useImageUpload";
import Loader from "components/Loader";
import friendHeader from "../../assets/img/friendHeader.png";
import { useMutation, useQuery } from "@apollo/react-hooks";
import getUserPublicProfile from "../../api/getUserPublicProfile.js";
import { useConfig } from "@genflow/core";
import { UserProfile } from "../../@types/user-discovery";
import CustomLoader from "../../components/CustomLoader";
import { Auth } from "aws-amplify";
import updateUserPublicProfile from "../../api/updateUserPublicProfile.js";
import { CognitoUserContext } from "../../providers/CognitoUserProvider.tsx";
import SettingsCard from "components/cards/SettingsCard.tsx";
import getTrackedVendorUser from "api/getTrackedVendorUser.js";
import TimeZoneCard from "components/cards/TimeZoneCard.tsx";
import EmailIcon from "../../assets/img/icons/settings-email.svg";
import NameIcon from "../../assets/img/icons/settings-name.svg";
import TimezoneIcon from "../../assets/img/icons/settings-timezone.svg";
import IdIcon from "../../assets/img/icons/settings-id.svg";
import PrivateIcon from "../../assets/img/icons/settings-private.svg";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import useCountries from "hooks/useCountries.ts";
import { ExclamationIcon } from "@heroicons/react/outline";

const Settings = () => {
  const { vendorUuid } = useConfig();
  const { data, error, loading } = useQuery(getUserPublicProfile, {
    variables: {
      vendorUuid
    }
  });
  const [updateUserProfile, { loading: updating }] = useMutation(updateUserPublicProfile, {});
  const { cognitoUser, updateCognitoAttributes } = useContext(CognitoUserContext);
  const [profileData, setProfileData] = useState({} as UserProfile);
  const updateProfileData = (changes: any) => setProfileData({ ...profileData, ...changes });
  const contactMethods = profileData?.contactMethods?.reduce((acc, { platform, method }) => ({
    ...acc,
    [platform]: method
  }), {} as any);

  useEffect(() => {
    if (!loading && data) {
      let updatedProfileData = data?.getUserPublicProfile?.data;

      const locationArr = updatedProfileData?.location?.split(',')?.map((item:string)=> item?.trim())
      const locationCountry = locationArr?.[1] ?? locationArr?.[0]
      const locationCity = !!(locationArr?.length > 1) && locationArr?.[0];

      if (!updatedProfileData?.country) updatedProfileData = { ...updatedProfileData, country: locationCountry || "United Kingdom", city: locationCity};
      setProfileData(updatedProfileData);
    }
  }, [data, loading]);


  const { uploadPublicImage } = useImageUpload();

  const onUpdateContactMethod = ({ platform, method }: { platform: string, method: string }) => {
    const updatedContactMethodsObj = {
      ...contactMethods,
      [platform]: method
    };

    updateProfileData({
      contactMethods: keys(updatedContactMethodsObj).map(platform => ({
        platform,
        method: updatedContactMethodsObj[platform]
      })).filter(({method}) => !!method)
    });
  };
  const onUpdateCareerPath = (careerPath: string) => updateProfileData({ careerPath });
  const onUpdateBio = (bio: string) => updateProfileData({ bio });
  const onUpdateBirthdate = (birthdate: string) => updateProfileData({ birthdate });
  const onUpdateLocation = (location: string) => updateProfileData({ location });
  const onUpdateIsPrivate = (isPrivate: boolean) => updateProfileData({ isPrivate });

  const onUpdateCountry = (country: string) => updateProfileData({ country });
  const onUpdateCity = (city: string) => updateProfileData({ city });

  const [profilePictureUploading, setProfilePictureUploading] = useState(false);
  const onUpdateProfilePictureUrl = async (e: any) => {
    setProfilePictureUploading(true);
    const profilePictureUrl = await uploadPublicImage(e);
    updateProfileData({ profilePictureUrl });
    setProfilePictureUploading(false);
  };


  const [headerPictureUploading, setHeaderPictureUploading] = useState(false);
  const onUpdateHeaderPictureUrl = async (e: any) => {
    setHeaderPictureUploading(true);
    const headerPictureUrl = await uploadPublicImage(e);
    updateProfileData({ headerPictureUrl });
    setHeaderPictureUploading(false);
  };

  console.log("profileData: ", profileData);

  const onClickSave = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const {
      birthdate,
      bio,
      careerPath,
      location,
      contactMethods,
      headerPictureUrl,
      profilePictureUrl,
      isPrivate,
      firstName,
      lastName,
      country,
      city
    } = profileData;

    const cognitoAttributes = {
      ...(!!profilePictureUrl && cognitoUser?.attributes?.picture !== profilePictureUrl ? {
        picture: profilePictureUrl
      } : {}),

      ...(!!birthdate && cognitoUser?.attributes?.birthdate !== birthdate ? { birthdate } : {}),

      ...(!!firstName && cognitoUser?.attributes?.given_name !== firstName ? { given_name: firstName } : {}),

      ...(!!lastName && cognitoUser?.attributes?.family_name !== lastName ? { family_name: lastName } : {})
    };

    if (keys(cognitoAttributes)?.length > 0) await updateCognitoAttributes(cognitoAttributes);

    const locationString = city ? `${city}, ${country}` : country

    await updateUserProfile({
      variables: {
        vendorUuid,
        data: {
          bio,
          careerPath,
          location: locationString,
          contactMethods: contactMethods?.map(({ platform, method }) => ({ platform, method })),
          headerPictureUrl,
          isPrivate,
          // firstName,
          // lastName
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: getUserPublicProfile,
        variables: {
          vendorUuid
        }
      }]
    });


  };

  const {countries, cities, flag} = useCountries(profileData?.country)
  const profileLocation= profileData?.location

  useEffect(()=> {
    if(!!cities?.length && (!profileLocation?.includes(profileData?.country || '')))onUpdateCity(cities?.[0])
  },[profileData?.country])

  const sortedCountries = sortBy(countries);
  const sortedCities = sortBy(cities);

  // Get user id
  const { data: trackedVendorUser } = useQuery(getTrackedVendorUser, {
    variables: { vendorUuid },
    skip: !vendorUuid
  });
  const userId = trackedVendorUser?.getTrackedVendorUser?.data?.userId;

  const profilePrivateFields = [
    !profileData?.profilePictureUrl && "Profile Picture",
    (!profileData?.location && !profileData.country && !profileData.city) && "Country/City",
    !profileData?.contactMethods?.length && "Socials",
  ].filter(Boolean);

  return (
    <div className="lg:h-screen flex flex-col py-4 px-4 md:py-8 md:px-8">
      <CustomLoader loading={loading}>
        <div
          className="flex flex-col 2xl:flex-row justify-center items-stretch overflow-y-auto gap-4 2xl:gap-8 overflow-hidden">
          <div
            className="mx-auto flex w-full 2xl:w-2/3 flex-1 flex-col glassCard !bg-[#1a1e26] !border-[1px] rounded-[20px] relative px-5 py-5 backdrop-blur-md">
            <div className="">
              <div className="py-2 glassCard rounded-2xl text-base 2xl:text-lg mb-4 text-center">My Profile</div>
              <div className="w-full rounded-xl relative">
                <div className="w-full h-24 md:h-52 relative overflow-hidden">
                  <img
                    src={profileData?.headerPictureUrl ?? friendHeader}
                    className="w-full h-full object-cover rounded-xl"
                  />

                  <div
                    className={`absolute top-0 left-0 w-full h-full flex justify-center items-center opacity-[${
                      !profileData?.headerPictureUrl ? "1" : "0"
                    }]`}
                  >
                    <img src={plusIcon} className="w-5 h-5 md:w-10 md:h-10" />
                  </div>

                  <label
                    id="headerImage"
                    htmlFor="nav-image"
                    className="text-white flex items-center justify-center w-full h-full rounded-[20px] absolute z-10 top-0 left-0"
                  >
                    {headerPictureUploading && (
                      <div className="w-full flex justify-end">
                        <Loader />
                      </div>
                    )}
                    <input
                      type="file"
                      id="nav-image"
                      alt="+ add photo"
                      onChange={onUpdateHeaderPictureUrl}
                      className="h-full w-full opacity-0 "
                    />
                  </label>
                </div>

                <div className="flex flex-row items-end md:mt-[-100px] mt-[-50px]">
                  <div
                    className="w-24 h-24 md:w-[200px] md:h-[200px] bg-white rounded-xl md:rounded-[30px] ml-2 md:ml-10 mr-2 md:mr-6 p-[1px] md:p-1 relative">

                    <img
                      src={profileData?.profilePictureUrl ?? friendHeader}
                      className="w-full h-full object-cover rounded-xl md:rounded-[30px]"
                    />

                    <div
                      className={`absolute top-0 left-0 w-full h-full flex justify-center items-center opacity-[${
                        !profileData?.profilePictureUrl ? "1" : "0"
                      }]`}
                    >
                      <img src={plusIcon} className="w-4 h-4 md:w-7 md:h-7" />
                    </div>

                    <label
                      id="image"
                      htmlFor="nav-image"
                      className="text-white flex items-center justify-center w-full h-full rounded-[20px] absolute z-10 top-0 left-0"
                    >
                      {profilePictureUploading && (
                        <div className="w-full flex justify-end">
                          <Loader />
                        </div>
                      )}

                      <input
                        type="file"
                        id="nav-image"
                        alt="+ add photo"
                        onChange={onUpdateProfilePictureUrl}
                        className="h-full w-full opacity-0"
                      />
                    </label>
                  </div>

                  <div
                    className="flex flex-col md:flex-row md:items-end md:justify-between md:h-12 md:mb-5 flex-1">
                    <p className="text-[#CBD5E1] text-left">
                      <div className='text-lg md:text-3xl'>{startCase(`${profileData?.firstName ?? ""} ${profileData?.lastName ?? ""}`)}</div>
                      {
                        profilePrivateFields?.length > 0 && !profileData.isPrivate && <div className='mt-2 text-xs flex items-center gap-2'>

                          <ExclamationIcon className="h-5 w-5" aria-hidden="true" /> {`Your profile will not be public until you complete: ${profilePrivateFields?.join(', ')}`}</div>
                      }
                    </p>

                    <div
                      className="h-12 border-2 border-[#0384FE] rounded-xl hidden md:flex justify-center items-center px-8 hover:cursor-pointer"
                      onClick={onClickSave}
                    >
                      <p className="text-[#CBD5E1] text-base">{updating ? <Loader /> : "Save & Continue"}</p>
                    </div>
                  </div>
                </div>

                {/* mobile */}
                <div
                  className="mt-5 h-12 border-2 border-[#0384FE] rounded-xl flex md:hidden justify-center items-center px-8 hover:cursor-pointer"
                  onClick={onClickSave}
                >
                  <p className="text-[#CBD5E1] text-base">{updating ? <Loader /> : "Save & Continue"}</p>
                </div>
              </div>
            </div>

            <div className="md:grid grid-flow-col gap-5 w-full mt-5 ">
              <div
                className="col-span-3 h-full flex flex-col glassCard backdrop-blur-md rounded-2xl !border-[1px] px-4 mb-5 md:mb-0">
                <div className="my-4 w-full">
                  <div className="block items-start mb-2 text-sm font-medium text-white w-full">
                    <p className="text-left text-[#CBD5E1] text-lg">Country</p>
                    <div
                      className="pr-2.5 flex flex-row items-center rounded-md glassCard !border-[1px] backdrop-blur-md overflow-hidden mt-2">
                      {!!flag && <div className="w-7 h-7 flex items-center justify-center  rounded-full ml-4 bg-[#4f70a021]">
                        <img
                          src={flag}
                          className="w-4 h-4 object-contain rounded-full"
                        />
                      </div>}
                      <select
                        id="country"
                        onChange={e => onUpdateCountry(e.target.value)}
                        value={profileData?.country}
                        className="bg-transparent text-white text-sm  focus:outline-none focus:border-blue-500 block w-full !p-2.5"
                      >
                        {sortedCountries?.map((option, id) => (
                          <option value={option} key={id}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
               {!!cities?.length && <div className="mb-4 w-full">
                  <div className="block items-start mb-2 text-sm font-medium text-white w-full">
                    <p className="text-left text-[#CBD5E1] text-lg">City</p>
                    <div
                      className="pr-2.5 flex flex-row items-center rounded-md glassCard !border-[1px] backdrop-blur-md overflow-hidden mt-2">
                      <select
                        id="city"
                        onChange={e => onUpdateCity(e.target.value)}
                        value={profileData?.city}
                        className="bg-transparent text-white text-sm  focus:outline-none focus:border-blue-500 block w-full !p-2.5"
                      >
                        {sortedCities?.map((option, id) => (
                          <option value={option} key={id}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>}
                <div className="mb-4 w-full">
                  <label
                    htmlFor="dob"
                    className="block mb-2 text-sm font-medium text-white w-full "
                  >
                    <p className="text-left text-[#CBD5E1] text-lg flex items-center">
                      <span>{"Date of Birth"}</span>
                      <span className="text-sm ml-2 opacity-50">(DD-MM-YYYY)</span>
                    </p>
                    <input
                      type="date"
                      id="dob"
                      value={profileData?.birthdate}
                      onChange={e => onUpdateBirthdate(e.target.value)}
                      name="dob"
                      max={new Date().toISOString().split("T")[0]}
                      className="rounded-md glassCard !border-[1px] backdrop-blur-md mt-2  text-white text-sm focus:ring-blue-500 focus:outline-none block w-full p-2.5"
                      required
                    />
                  </label>
                </div>
                <div className="mb-4 w-full flex h-full">
                  <label
                    htmlFor="registrationReason"
                    className="block mb-2 text-sm font-medium text-white w-full"
                  >
                    <p className="text-left text-[#CBD5E1] text-lg"> About Me </p>
                    <textarea
                      onChange={e => onUpdateBio(e.target.value)}
                      value={profileData?.bio}
                      id="aboutMe"
                      className="flex md:h-[90%] rounded-md mt-2 glassCard !border-[1px] backdrop-blur-md text-white text-sm focus:ring-blue-500 focus:outline-none w-full p-2.5"
                    />
                  </label>
                </div>
              </div>

              <div className="col-span-1  flex flex-col justify-end items-end">
                <div
                  className="flex flex-col items-start justify-start glassCard !border-[1px] p-4 w-full rounded-2xl mb-5">
                  <p className="text-base font-semibold text-[#CBD5E1]">
                    Choose Your Career Path
                  </p>
                  <div className="mt-2">
                    {programs?.map(({ label, value, icon }) => (
                      <div
                        key={value}
                        onClick={() => onUpdateCareerPath(value)}
                        id="program"
                        className={`!border-[${
                          value === profileData?.careerPath ? "#fff" : ""
                        }] hover:cursor-pointer py-1.5 px-3 flex flex-row items-center glassCard !border-[1px] rounded-[10px] mb-2`}
                      >
                        <img
                          src={icon}
                          className="h-3 w-3 object-contain mr-1.5"
                        />
                        <p className="text-xs text-white">{label}</p>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="flex flex-col items-start justify-start glassCard !border-[1px] p-4 w-full rounded-2xl">
                  <p className="text-base font-semibold text-[#CBD5E1]">
                    Link Your Socials
                  </p>
                  <div className="mt-2 w-full">
                    {socials?.map(({ placeHolder, icon, platform }) => (
                      <label
                        key={platform}
                        htmlFor={platform}
                        className="mt-2 flex flex-row items-center justify-center mb-2 text-sm font-medium text-white w-full"
                      >
                        <div className="w-6 h-6 mr-2">
                          <img src={icon} className="w-6 h-6 object-contain " />
                        </div>
                        <input
                          key={platform}
                          onChange={(e) => onUpdateContactMethod({ platform, method: e?.target?.value })}
                          value={contactMethods?.[platform]}
                          placeholder={placeHolder}
                          className="placeholder-[#42464E] w-full rounded-md glassCard !border-[1px] backdrop-blur-md text-white text-sm focus:ring-blue-500 focus:outline-none p-2.5"
                          autoComplete="off"
                          data-lpignore="true"
                        />
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="w-full 2xl:w-1/3 flex mx-auto h-full flex-col glassCard !bg-[#1a1e26] !border-[1px] rounded-[20px] relative px-5 py-5 backdrop-blur-md">
            <div className="py-2 glassCard rounded-2xl text-base 2xl:text-lg mb-4 text-center">Settings</div>
            <SettingsCard
              label="Your Name"
              icon={NameIcon}
            >
              <div className="flex flex-row gap-4">
                <input
                  className="rounded-md glassCard !border-[1px] backdrop-blur-md mt-2  text-white text-sm focus:ring-blue-500 focus:outline-none block flex-1 p-2.5"
                  onChange={(e) => updateProfileData({ firstName: e.target.value })}
                  value={profileData?.firstName}
                  placeholder="First Name"
                  autoComplete="off"
                />
                <input
                  className="rounded-md glassCard !border-[1px] backdrop-blur-md mt-2  text-white text-sm focus:ring-blue-500 focus:outline-none block flex-1 p-2.5"
                  onChange={(e) => updateProfileData({ lastName: e.target.value })}
                  value={profileData?.lastName}
                  placeholder="Last Name"
                  autoComplete="off"
                />
              </div>
            </SettingsCard>
            <SettingsCard
              label="Email Address"
              icon={EmailIcon}
              // secondaryCtaText="Request Email Change"
              // secondaryCtaOnClick={() => console.log("request email change")}
            >
              <div
                className="rounded-md glassCard !border-[1px] backdrop-blur-md mt-2  text-white text-sm focus:ring-blue-500 focus:outline-none block flex-1 p-2.5"
              >
                {cognitoUser?.attributes?.email}
              </div>
            </SettingsCard>
            <SettingsCard
              label="Timezone"
              icon={TimezoneIcon}
            >
              <TimeZoneCard />
            </SettingsCard>
            <SettingsCard
              label="Student ID"
              icon={IdIcon}
            >
              <div
                className="rounded-md glassCard !border-[1px] backdrop-blur-md mt-2  text-white text-sm focus:ring-blue-500 focus:outline-none block flex-1 p-2.5"
              >
                {userId}
              </div>
            </SettingsCard>
            <SettingsCard
              label="Find a Friend"
              icon={PrivateIcon}
            >
              <div
                className="rounded-md glassCard !border-[1px] backdrop-blur-md mt-2  text-white text-sm focus:ring-blue-500 focus:outline-none flex-1 p-2.5 flex flex-row justify-between items-center"
              >
                <div>Make Profile Private</div>
                <Toggle
                  checked={profileData?.isPrivate}
                  icons={false}
                  onChange={(e) => onUpdateIsPrivate(e.target.checked)}
                />
              </div>
            </SettingsCard>
            <div
              className="h-12 border-2 border-[#0384FE] rounded-xl hidden md:flex justify-center items-center px-8 hover:cursor-pointer"
              onClick={onClickSave}
            >
              <p className="text-[#CBD5E1] text-base">{updating ? <Loader /> : "Save"}</p>
            </div>
          </div>
        </div>
      </CustomLoader>
    </div>
  );
};

export default Settings;
