import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ initialTime, redirectUrl }: {
    initialTime: number;
    redirectUrl: string;
}) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timer);
          window.location.href = redirectUrl;
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [redirectUrl]);

  return <h1 className='text-2xl font-bold text-white'>{timeLeft}</h1>;
};

export default CountdownTimer;