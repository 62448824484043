import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useLocation } from "react-router-dom";
import { lowerCase } from "lodash";
import { useIsLoggedIn } from "@genflow/web-auth";
import Bugsnag from "@bugsnag/js";
import OnboardingQuestions from "../../views/pages/onboarding/OnboardingQuestions";
import { UserDataContext } from "../../providers/UserDataProvider";
import { OnboardingContext } from "../../providers/OnboardingProvider";
import { trackPageView } from "../../utils/useAnalytics";
import { TRACKABLES } from "../../constants/Trackables";
import getUserProfile from "../../api/getUserProfile";
import { getUser } from "../../utils/auth";
import useUserAccess from "../../hooks/useUserAccess";


const Layout = ({ children }) => {
    const location = useLocation();
    const [userId, setUserId] = useState(null);
    const isLoggedIn = useIsLoggedIn(true);
    const {
        userProfile, setUserProfile, setUserToken
    } = useContext(UserDataContext);


    useEffect(() => {
        setBugsnagUser();
    }, [isLoggedIn, userProfile]);

    const setBugsnagUser = async () => {
        try {
            const cognitoUser = await getUser();
            if (cognitoUser?.attributes?.sub) {
                const id = cognitoUser?.attributes?.sub;
                const token = cognitoUser?.signInUserSession?.idToken?.jwtToken;
                setUserToken(token);
                setUserId(id);
                const name = `${cognitoUser?.attributes?.family_name} ${cognitoUser?.attributes?.given_name}`;
                const email = cognitoUser?.attributes?.email;
                Bugsnag.setUser(id, email, name);
            }
        } catch (e) {
            console.log(e);
        }
    };

    // track current url in trackable list whenever url changes
    const analytics = useCallback(() => {
        const pathArr = location?.pathname?.split('/');
        const path = pathArr?.[pathArr?.length - 1];

        const trackable = TRACKABLES?.find(({ value }) => lowerCase(value) === lowerCase(path));
        if (trackable) {
            trackPageView(location.pathname, trackable?.label);
        }
    }, [location.pathname]);

    useEffect(() => {
        analytics();
    }, [analytics]);


    const { userAccess, isLoading } = useUserAccess();
    const hasAccess = !!userAccess?.find(({key}) => key === 'analytics.read');

    const { setShow, } = useContext(OnboardingContext);

    // fetch user profile from admin
    const { data, refetch } = useQuery(getUserProfile);
    const userProfileData = data?.getCustomerUserProfileData?.data?.[0];
    const parsedData = userProfileData?.data ? JSON.parse(userProfileData?.data) : {};

    useEffect(() => {
        if (userId) {
            refetch();
            setShow(true);
        }
    }, [userId]);

    // update local storage after fetch
    useEffect(() => {
        if (userProfileData !== null) {
            const profileData = userProfile?.data;
            setUserProfile({ ...userProfileData, data: { ...profileData, ...parsedData } });
        }
    }, [userProfileData, userId]);

    const showOnboardingQuestion = useMemo(() => ((userProfile?.data?.onboardingCompleted !== true
        && parsedData?.onboardingCompleted !== true) && isLoggedIn && !!userId && !isLoading && hasAccess), [parsedData, userProfile, isLoggedIn, userId, isLoading, hasAccess]);


    return (
        <>
            {/* {showNavBar && <NavBar />} */}
            {/* only open if user has not undergone onboarding */}
            <OnboardingQuestions open={showOnboardingQuestion} />
            <div>
                {children}
            </div>
        </>
    );
};
export default Layout;
