import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '@genflow/web-auth';
import { Redirect, useHistory } from 'react-router';
import { Auth } from 'aws-amplify';
import { DateUtils } from '@aws-amplify/core';
import { NEEDS_PASSWORD } from '../../../env';
import useStickyState from '../../../utils/useStickyState';
import LoadingButton from '../../../components/LoadingButton';
import { loginPasswordless, login } from '../../../utils/auth';
import CodeInput from './components/CodeInput';
import './styles.css';

let countdownTrigger;

// console.log("NEEDS_PASSWORD", NEEDS_PASSWORD);

export default () => {
    const { useLogin, useIsLoggedIn } = useAuth();
    useLogin(true);
    const isLoggedIn = useIsLoggedIn(true);
    // const [loggingIn, setLoggingIn] = useState(false);
    const [rememberMe, setRememberMe] = useStickyState(false, 'loginRememberMe');
    const [email, setEmail] = useStickyState(null, 'loginEmail', !rememberMe);
    const [password, setPassword] = useState(null);
    const [validationMessage, setValidationMessage] = useState(null);
    const [loggingIn, setLoggingIn] = useState(false);

    const [unverifiedUser, setUnverifiedUser] = useState(null);
    const [codeValue, setCodeValue] = useState();

    const [countdownSeconds, setCountdownSeconds] = useState(0);
    const [codeResent, setCodeResent] = useState(false);
    const [borderColor, setBorderColor] = useState('#434343');

    const history = useHistory();

    useEffect(() => {
        if (countdownTrigger) {
            clearTimeout(countdownTrigger);
            countdownTrigger = null;
        }
        if (!unverifiedUser) return; // avoid starting the countdown at startup
        if (countdownSeconds < 1) return;

        countdownTrigger = setTimeout(() => {
            setCountdownSeconds(countdownSeconds - 1);
        }, 1000);
    }, [countdownSeconds]);

    if (isLoggedIn === null) return null;

    if (isLoggedIn) {
        return <Redirect to="/" />;
    }

    const handleLogin = async () => {
        if (NEEDS_PASSWORD) {
            await login({
                email, password,
            });
            return;
        }

        if (!!unverifiedUser && !!codeValue) {
            setLoggingIn(true);
            try {
                await Auth.sendCustomChallengeAnswer(unverifiedUser, codeValue);
                // This will throw an error if the user is not yet authenticated:
                const user = await Auth.currentSession();
                console.log('clockDrift', user.clockDrift);
                DateUtils.setClockOffset(DateUtils.setClockOffset(user.clockDrift * 1000 * -1));
                history.push('/dashboard');
            } catch (error) {
                console.log(error);
                if (error.code === 'NotAuthorizedException') {
                    setValidationMessage(
                        'Your code seems to have expired, please try again.',
                    );
                    setUnverifiedUser(null);
                } else {
                    setValidationMessage('Your code seems to be incorrect!');
                    console.warn(error);
                }
            }
        } else {
            setLoggingIn(true);
            const passwordLoginResponse = await loginPasswordless(email).catch(
                (error) => {
                    setLoggingIn(false);
                    setValidationMessage(error);
                },
            );

            setUnverifiedUser(passwordLoginResponse);
            setCountdownSeconds(45); // seconds to resend the code
        }

        setLoggingIn(false);
    };

    const clearValidationMessage = () => {
        if (validationMessage) setValidationMessage(null);
    };

    const resendEmail = () => {
        handleLogin();
        setCodeResent(true);
    };

    const canLogin = unverifiedUser ? codeValue?.length === 6 : !!email;

    return (
        <div className="flex flex-col justify-center  px-4 py-4 lg:px-8 2xl:py-12  over l h-screen">
            <div className="rounded-[20px] md:rounded-[30px] pt-6 2xl:pt-12 pb-5 2xl:pb-10 w-full md:w-4/6 lg:w-[624px] self-center glassCard px-4 md:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm ">
                    <img
                        className="mx-auto h-5 md:h-8 2xl:h-14  w-auto"
                        src="../Logo.svg"
                        alt="Your Company"
                    />
                </div>

                <div className="w-full mt-5 md:mt-8 2xl:mt-10 sm:mx-auto sm:w-full max-w-sm md:max-w-lg rounded-[16px] p-5 glassCard">
                    <div className="space-y-6" role="form">
                        <div>
                            <label htmlFor="email" className="block text-sm 2xl:text-base font-medium leading-6 text-[#CBD5E1] ">
                                Email
                                <input
                                    value={email}
                                    onChange={(newEmail) => {
                                        clearValidationMessage();
                                        setEmail(newEmail.target.value);
                                    }}
                                    onClick={() => {
                                        setBorderColor('white');
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter' && canLogin) {
                                            handleLogin();
                                        }
                                    }}
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    placeholder="Email Address"
                                    className="outlineRemoved mt-2 placeholder:text-[#64748B] block w-full rounded-md py-3 px-4 2xl:py-4 text-[#CBD5E1] shadow-sm  ring-inset ring-gray-300  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm md:text-base sm:leading-6 input"
                                />
                            </label>

                            {
                                NEEDS_PASSWORD
                            && (
                                <input
                                    placeholder="Password"
                                    type="password"
                                    className="border border-transparent bg-transparent pb-2 body3 w-full outlineRemoved"
                                    value={password}
                                    onChange={(newPass) => {
                                        clearValidationMessage();
                                        setPassword(newPass.target.value);
                                    }}
                                    onClick={() => {
                                        setBorderColor('white');
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter' && canLogin) {
                                            handleLogin();
                                        }
                                    }}
                                />
                            )
                            }
                        </div>

                        {!!unverifiedUser && (
                            <>
                                <CodeInput onChange={setCodeValue} />

                                {
                                    !codeResent && (
                                        <div
                                            className={`text-center font-semibold leading-6 text-[#64748B] ${countdownSeconds < 1 && 'cursor-pointer text-transparent bg-clip-text bg-gradient-to-r from-[#0384FE] to-[#03E5B4] underline-offset-auto'}`}
                                            onClick={() => countdownSeconds < 1 && resendEmail()}
                                        >
                                            {
                                                countdownSeconds > 0
                                                    ? ` Resend email (${countdownSeconds}s)`
                                                    : 'Resend email'
                                            }
                                        </div>
                                    )
                                }


                            </>
                        )}

                        <div
                            className="custom-control custom-control-alternative custom-checkbox remember-box flex flex-row items-center"
                        >
                            <input
                                checked={rememberMe}
                                className="custom-control-input w-4 h-4"
                                id="customCheckLogin"
                                type="checkbox"
                                onChange={(e) => {
                                    setRememberMe(Boolean(e.target.checked));
                                }}
                            />
                            <label
                                className="custom-control-label ml-3"
                                htmlFor="customCheckLogin"
                            >
                                <span className="text-[#64748B]">Remember me</span>
                            </label>
                        </div>

                        {!!validationMessage && (
                            <div className="text-center text-red-400 mt-3 mb-3 small">
                                {validationMessage}
                            </div>
                        )}

                        <div>
                            <LoadingButton
                                className="button flex w-full justify-center rounded-md  px-3 py-3 2xl:py-4 text-lg md:text-lg font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                loading={loggingIn}
                                disabled={!canLogin}
                                color="primary"
                                type="button"
                                onClick={handleLogin}
                            >
                                {unverifiedUser ? 'Next' : 'Sign in' }
                            </LoadingButton>
                        </div>
                    </div>
                </div>
                {/* <p className="mt-5 2xl:mt-10  text-center text-sm md:text-base text-[#64748B]">
                    New here?
                    {' '}
                    <Link to="/register" className="font-semibold leading-6 text-transparent bg-clip-text bg-gradient-to-r from-[#0384FE] to-[#03E5B4] underline-offset-auto">
                        Create an account
                    </Link>
                </p> */}
            </div>
        </div>


    );
};
